import React, { memo, useRef, useLayoutEffect } from "react";
import usePrevious from "../../hooks/usePrevious";
import { makeStyles, TextField } from "@material-ui/core";

const SingleOTPInputComponent = ({ focus, error, ...rest }) => {
  const inputRef = useRef(null);
  const prevFocus = usePrevious(!!focus);
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus) {
        inputRef.current.focus();
      }
      if (focus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [focus, prevFocus]);

  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      label={null}
      inputRef={inputRef}
      error={error}
      autoComplete="off"
      {...rest}
    />
  );
};

const SingleOTPInput = memo(SingleOTPInputComponent);
export default SingleOTPInput;
