import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import QRCode from "qrcode";
import { productionUrl } from "../../config/config";
import { useTranslation } from "react-i18next";
import { toPng } from "html-to-image";
import download from "downloadjs";
import {
  createMetadataContext,
  getOuDisplayName,
  recursiveTranslate,
} from "../../utils";
import { useSelector } from "react-redux";
import { toClientMobileValue } from "./utils";
import { DOSE_TYPES } from "../../common/TypedInput/DoseSelectRadioGroup";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ResultDialog = ({
  data: {
    teiUid,
    ScsGETliG8f: scheduledDate,
    uG58XyIlYFr: scheduledTime,
    vaccinationSite,
    sB1IHYu2xQT: firstName,
    ENRjVGxVL6l: lastName,
    ciCR6BBvIT4: phoneNumber,
  },
  open,
  onClose,
  // attributes,
}) => {
  const attributes = useSelector((state) => state.metadata.attributes);
  const doseNumber = useSelector((state) => state.cvr.doseNumber);
  const { t, i18n } = useTranslation();
  const orgUnits = useSelector((state) => state.metadata.orgUnits);
  const translatedOrgUnits = useMemo(() => {
    return recursiveTranslate(i18n.language)(orgUnits);
  }, [i18n.language, orgUnits]);
  const findOu = createMetadataContext(
    translatedOrgUnits,
    (metadata) => (id) =>
      metadata.organisationUnits.organisationUnits.find(({ id: ouId }) => {
        return ouId === id;
      }),
    (item) => item
  );
  const findAttr = createMetadataContext(
    attributes,
    (metadata) => (id) => {
      return metadata.programTrackedEntityAttributes.find(
        ({ trackedEntityAttribute: { id: attrId } }) => {
          return attrId === id;
        }
      );
    },
    (item) => item.trackedEntityAttribute
  );
  const [qrCode, setQrCode] = useState(null);
  const [isDownloadingPng, setIsDownloadingPng] = useState(false);
  useEffect(() => {
    if (open) {
      QRCode.toDataURL(`${productionUrl}/#/status?id=${teiUid}`)
        .then((url) => {
          setQrCode(url);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [open]);

  const ref = React.createRef();

  const downloadPng = () => {
    if (ref.current) {
      setIsDownloadingPng(true);
      toPng(ref.current)
        .then(function (dataUrl) {
          download(dataUrl, `${teiUid}.png`);
        })
        .catch((e) => console.log(e))
        .finally(() => setIsDownloadingPng(false));
    }
  };

  const getTitle = () => {
    switch (doseNumber) {
      case DOSE_TYPES.FIRST:
        return t("preRegistrationCompleted");
      case DOSE_TYPES.SECOND:
        return t("secondDoseRegistrationCompleted");
      case DOSE_TYPES.BOOSTER:
        return t("boosterDoseRegistrationCompleted");
    }
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      // onClose={onClose}
    >
      <Box textAlign="center">
        <DialogContent ref={ref}>
          <DialogTitle>{getTitle()}</DialogTitle>
          <DialogContentText>
            <img src={qrCode} height={150} width={150} />
          </DialogContentText>
          <DialogContentText>
            {t("yourPreRegistrationCode")}: <strong>{teiUid}</strong>
          </DialogContentText>
          <DialogContentText>
            {findAttr("sB1IHYu2xQT").formName}: <strong>{firstName}</strong>
          </DialogContentText>
          <DialogContentText>
            {findAttr("ENRjVGxVL6l").formName}: <strong>{lastName}</strong>
          </DialogContentText>
          {phoneNumber && (
            <DialogContentText>
              {findAttr("ciCR6BBvIT4").formName}:{" "}
              <strong>{toClientMobileValue(phoneNumber)}</strong>
            </DialogContentText>
          )}
          <DialogContentText>
            {findAttr("ScsGETliG8f").formName}: <strong>{scheduledDate}</strong>
          </DialogContentText>
          <DialogContentText>
            {findAttr("uG58XyIlYFr").formName}: <strong>{scheduledTime}</strong>
          </DialogContentText>
          <DialogContentText>
            {t("selectVaccinationSite")}:{" "}
            <strong>{getOuDisplayName(findOu(vaccinationSite))}</strong>
          </DialogContentText>
          <DialogContentText>
            <div
              dangerouslySetInnerHTML={{
                __html: t("pleaseTakeAPicture"),
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/*<Button
            startIcon={
              isDownloadingPng ? (
                <Box component={CircularProgress} mr={1} size={22} />
              ) : (
                <GetApp />
              )
            }
            disabled={isDownloadingPng}
            size="large"
            color="primary"
            onClick={downloadPng}
          >
            {t("download")}
          </Button>*/}
          <Button size="large" color="primary" onClick={onClose}>
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ResultDialog;
