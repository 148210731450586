import React from "react";
import Logo from "./Logo";
import { AppBar, Box, Button, Divider, Grid, Toolbar, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../LanguageSelect";

const DesktopMenu = () => {
  const { t } = useTranslation();
  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container justify="space-between">
          <Grid item>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item>
                <Logo />
              </Grid>
              <Grid item>
                <Typography>{t("title")}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box textAlign="center">
              <Grid container alignItems="center" justify="center" spacing={3}>
                <Grid item>
                  <Button component={Link} to="/cvr" color="inherit">
                    {t("menuRegister")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button component={Link} to="/guide" color="inherit">
                    {t("menuVaccinationGuide")}
                  </Button>
                </Grid>
                {/* <Grid item>
                  <Button component={Link} to="/status" color="inherit">
                    {t("menuCheckStatus")}
                  </Button>
                </Grid> */}
                <Grid item>
                  <Button component={Link} to="/generate-certificate" color="inherit">
                    {t("generateCertificate")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button component={Link} to="/international-certificate" color="inherit">
                    {t("internationalCertificate")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button component={Link} to="/verify-certificate" color="inherit">
                    {t("verify")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button component={Link} to="/lao-dp" color="inherit">
                    {t("laoDP")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box textAlign="right">
              <LanguageSelect />
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export default DesktopMenu;
