import React from "react";
import grey from "@material-ui/core/colors/grey";
import { Box, Button, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import androidDownload from "../../../assets/images/android_download.png";
import iosDownload from "../../../assets/images/ios_download.png";
import apkDownload from "../../../assets/images/apk_download.png";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  logoImg: {
    width: 180,
    height: "unset",
    [theme.breakpoints.up("md")]: {
      width: "unset",
      height: 65
    }
  },
  powerText: {
    fontWeight: "bold",
    textAlign: "left",
    [theme.breakpoints.up("md")]: {
      textAlign: "center"
    }
  }
}));

const Footer = () => {
  const { t } = useTranslation();
  const { logoImg, powerText } = useStyles();
  return (
    <Box py={4} mt={3} bgcolor={grey[400]} component="footer">
      <Container>
        <Grid container spacing={3} alignItems="center" justify="space-between">
          <Grid item>
            <Box
              component={Typography}
              mb={1}
              variant="h6"
              color="white"
              style={{
                letterSpacing: 2
              }}
              // className={title}
            >
              <strong>{t("downloadAvailable")}</strong>
            </Box>
            <Grid container>
              <Grid item>
                <Button
                  component="a"
                  href="https://play.google.com/store/apps/details?id=org.hispvietnam.laocoviddp"
                  target="_blank"
                >
                  <img className={logoImg} src={androidDownload} />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  component="a"
                  href="https://apps.apple.com/app/laos-covid-digital-proof/id1593158557"
                  target="_blank"
                >
                  <img className={logoImg} src={iosDownload} />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  component="a"
                  href="https://drive.google.com/file/d/1a4lemn6PkvswQ_ruWEcHmzVTsenaYodv/view"
                  target="_blank"
                >
                  <img className={logoImg} src={apkDownload} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box mb={2} component={Typography} variant="subtitle2" color="white" className={powerText}>
              Powered by
            </Box>
            <img width={150} src="https://hisp.vn/wp-content/uploads/2021/01/LOGO_HISPVN_Vertical-300x177.png" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
