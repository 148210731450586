import React, { useState } from "react";
import SearchPage from "./SearchForm";
import VaccinationCertificateContainer from "./VaccinationCertificate.container";
import withSkeletonLoading from "../../hocs/withSkeletonLoading";
import grey from "@material-ui/core/colors/grey";
import { Box, Paper, Typography, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { isDoseDone, isVaccinationDone, sortVaccinations } from "../../utils";
import { Alert } from "@material-ui/lab";
import { getCertificate } from "../../services/api";
import i18n from "i18next";

const SearchFormLoading = withSkeletonLoading()(SearchPage);
const VaccinationCertificateLoading = withSkeletonLoading(() => null)(
  VaccinationCertificateContainer
);

const GenerateCertificatePage = () => {
  const [processedData, setProcessedData] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const theme = useTheme();

  const onSubmit = ({
    sB1IHYu2xQT: firstName,
    ENRjVGxVL6l: lastName,
    ciCR6BBvIT4: phoneNumber,
    corXnplgfQ7: systemCVID,
    tQeFLjYbqzv: dateOfBirth,
    DmuazFb368B: sex,
  }) => {
    setError("");
    setProcessedData(null);
    setLoading(true);
    getCertificate({
      firstName,
      lastName,
      phoneNumber,
      systemCVID,
      dateOfBirth,
      sex,
      locale: i18n.language,
    })
      .then((res) => {
        if (!res) {
          setProcessedData(null);
          return setError("unknownError");
        }
        const data = res;
        const { vaccinations, ...attrs } = data;
        if (!vaccinations || !vaccinations.length || !isVaccinationDone(data)) {
          setError("youHaveNotCompleteYourVaccination");
        } else {
          setProcessedData({
            ...attrs,
            vaccinations: sortVaccinations(
              vaccinations.filter((vaccination) => isDoseDone(vaccination))
            ),
          });
        }
        debugger;
      })
      .catch((res) => {
        setProcessedData(null);
        if (res.status === 404) {
          return setError("notFound");
        }
        return setError("unknownServerError");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box borderRadius={0} bgcolor={grey[100]}>
      <Box
        style={{
          minHeight: "calc(100vh - 65px)",
        }}
        bgcolor="white"
        mx={"auto"}
        maxWidth={theme.breakpoints.values.md}
      >
        <Paper elevation={0}>
          <Box p={3}>
            <Box mb={2} mt={1}>
              <Typography component="h1" variant="h5" align="center">
                {t("generateVaccinationCertificate")}
              </Typography>
            </Box>
            <SearchFormLoading
              loading={loading}
              // loading={true}
              loaded={true}
              mask
              onSubmit={onSubmit}
            />
            {error ? (
              <Alert component={Box} mt={3} severity="error">
                {t(error)}
              </Alert>
            ) : (
              <VaccinationCertificateLoading
                loading={loading}
                loaded={!!processedData}
                data={processedData}
              />
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default GenerateCertificatePage;
