import { all } from "redux-saga/effects";
import { getAttributes, getLatestAttributes } from "./getAttributes";
import getDataElements from "./getDataElements";
import getVillageHierarchy from "./getVillageHierarchy";

export default function* rootSaga() {
  yield all([
    getAttributes(),
    getLatestAttributes(),
    getDataElements(),
    getVillageHierarchy(),
  ]);
}
