import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CommonDialog = ({
  data: { title, content },
  open,
  onClose
  // attributes,
}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (open) {
    }
  }, [open]);

  const ref = React.createRef();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      // onClose={onClose}
    >
      <Box textAlign="center">
        <DialogContent ref={ref}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContentText>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="large" color="primary" onClick={onClose}>
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CommonDialog;
