import React, { useRef, useEffect, useState } from "react";
import {
  Dialog,
  Button,
  CircularProgress,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Box
} from "@material-ui/core";
import { FlipCameraAndroid } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const sx = {
  switchButton: {
    position: "absolute",
    fontSize: "46px",
    color: "#fff",
    margin: "5px 5px",
    zIndex: 112,
    cursor: "pointer",
    top: 80,
    right: 25,
    opacity: 0.8
  },
  alertPopup: {
    position: "absolute",
    zIndex: 111,
    top: 0,
    left: 0,
    width: "100%",
    opacity: 0.8
  },
  container: {
    position: "absolute",
    zIndex: 110,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(255,255,255,0.8)"
  }
};

const withQRScanner =
  (Component) =>
  ({
    onCancel,
    onScanned,
    onClick,
    cancelText,
    deleteText,
    messageText,
    qrboxSize = 300,
    closeAfterScan = true,
    autoOpen,
    ...props
  }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [listDevices, setListDevices] = useState(null);
    const [selectedDevide, setSelectedDevide] = useState(0);
    const [error, setError] = useState(null);
    const html5QrCodeScannerRef = useRef(null);

    const clear = () => {
      setStop();
      setError(null);
      setLoading(null);
      setListDevices(null);
      setSelectedDevide(0);
    };

    useEffect(() => {
      if (autoOpen) {
        handleClick();
      }
      return clear;
    }, []);

    const errorCallback = (message) => {
      setError(message);
    };

    const handleSuccessfull = () => {
      console.log("closeAfterScan", html5QrCodeScannerRef.current);
      console.log("html5QrCodeScannerRef.current", html5QrCodeScannerRef.current);
      if (closeAfterScan && html5QrCodeScannerRef.current) {
        html5QrCodeScannerRef.current
          .stop()
          .then((ignore) => {
            // QR Code scanning is stopped.
            html5QrCodeScannerRef.current.clear();
            html5QrCodeScannerRef.current = null;
            // html5qrCodeScanner.clear();
            console.log("stopped after successful scan");
          })
          .catch((err) => {
            // Stop failed, handle it.
            html5QrCodeScannerRef.current.clear();
            html5QrCodeScannerRef.current = null;
            // html5qrCodeScanner.clear();
            console.log("fails to stop after succesfull scan result ");
          });
      }
      setOpen(false);
    };

    const setStop = () => {
      if (html5QrCodeScannerRef.current) {
        html5QrCodeScannerRef.current
          ?.stop()
          ?.then((ignore) => {
            // QR Code scanning is stopped
            html5QrCodeScannerRef.current.clear();
            html5QrCodeScannerRef.current = null;

            console.log("stopped after successful scan");
          })
          ?.catch((err) => {
            // Stop failed, handle it.
            html5QrCodeScannerRef.current?.clear();
            html5QrCodeScannerRef.current = null;

            console.log("fails to stop after successfull scan result ");
          });
      }
    };

    const changeCamera = () => {
      if (html5QrCodeScannerRef.current) {
        setLoading(true);
        // STOP
        html5QrCodeScannerRef.current?.stop()?.then((ignore) => {
          html5QrCodeScannerRef.current.clear();
        });
        // START
        let nextIndexDevices = (selectedDevide + 1) % listDevices.length;
        startCamera(listDevices[nextIndexDevices]);
        setSelectedDevide(nextIndexDevices);
      }
    };

    const startCamera = (cameraId) => {
      console.log("html5QrCodeScannerRef", html5QrCodeScannerRef);
      console.log("cameraId", cameraId);
      if (html5QrCodeScannerRef.current) {
        html5QrCodeScannerRef.current
          .start(
            cameraId,
            {
              fps: 10, // Optional, frame per seconds for qr code scanning
              qrbox: qrboxSize // Optional, if you want bounded box UI
              // aspectRatio: 1.777778,
              // disableFlip: true,
            },
            (decodedText, decodedResult) => {
              // do something when code is read
              console.log("decodedResult", decodedResult);

              if (decodedText) {
                // callback
                onScanned(
                  {
                    isScanSuccess: true,
                    isScanning: false,
                    data: decodedText,
                    error: ""
                  }
                  /* errorCallback,
                    handleSuccessfull*/
                );
                handleSuccessfull();
              } else {
                errorCallback();
                setError("Invalid QRCode");
              }
            },
            (errorMessage) => {
              // parse error, ignore it.
              // stop loading when camera is ready
              setLoading(false);
              setError(null);
              console.log("errorMessage", errorMessage);
            }
          )
          .catch((err) => {
            // Start failed, handle it.
            onScanned({
              isScanSuccess: false,
              isScanning: false,
              isScanFailure: true,
              data: null,
              error: err || "QR Code parsing failed"
            });
          });
      }
    };

    const setActive = () => {
      console.log("window.Html5Qrcode", window.Html5Qrcode);
      try {
        window.Html5Qrcode.getCameras()
          .then((devices) => {
            /**
             * devices would be an array of objects of type:
             * { id: "id", label: "label" }
             */

            if (devices && devices.length) {
              // alert(JSON.stringify(devices));
              var deviceIDs = devices.map((d) => d.id);
              setListDevices(deviceIDs);
              var cameraId = deviceIDs[deviceIDs.length - 1];
              setSelectedDevide(deviceIDs.length - 1);
              console.log("cameraId", cameraId);

              // alert(devices.length + "-" + JSON.stringify(devices));

              // .. use this to start scanning.
              let html5qrCodeScanner = null;

              if (!html5QrCodeScannerRef.current) {
                // eslint-disable-next-line
                html5qrCodeScanner = new window.Html5Qrcode("scannerID", {
                  // Use this flag to turn on the feature.
                  experimentalFeatures: {
                    useBarCodeDetectorIfSupported: true
                  }
                });
                html5QrCodeScannerRef.current = html5qrCodeScanner;
              } else {
                html5qrCodeScanner = html5QrCodeScannerRef.current;
                setOpen(false);
              }

              console.log("html5qrCodeScanner", html5qrCodeScanner);

              // START
              startCamera(cameraId);
            } else {
              setError("noDeviceFound");
            }
          })
          .catch((err) => {
            setError(err);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        setError("unknownError");
      }
    };

    const handleClick = (event) => {
      // onClick && onClick(event);
      setOpen(true);
      setLoading(true);
      setActive();
      // setAnchorEl(event.currentTarget);
    };

    const handleCancel = (event) => {
      clear();
      setError(null);
      setOpen(false);
    };

    return (
      <>
        <Component onClick={handleClick} {...props} />
        <Dialog keepMounted maxWidth="xs" fullWidth open={open} onCancel={handleCancel}>
          <DialogTitle>QR Scanner</DialogTitle>
          <DialogContent>
            {loading && (
              <Box my={5}>
                <Grid container justify="center">
                  <CircularProgress />
                </Grid>
              </Box>
            )}
            <div key="scannerID" id="scannerID" />
            {error && <Alert severity="error">{t(error)}</Alert>}
            {!loading && listDevices && listDevices.length > 1 && (
              <FlipCameraAndroid style={sx.switchButton} onClick={changeCamera} />
            )}
          </DialogContent>
          <DialogActions>
            <Button key="back" onClick={handleCancel}>
              {t("cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

export default withQRScanner;
