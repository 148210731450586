import React from "react";
import { Alert } from "@material-ui/lab";
import { Box } from "@material-ui/core";

const FailedError = ({ error }) => {
  return (
    <Box textAlign="center">
      <Box px={5} mx="auto" display="inline-block">
        <Alert severity="error" title="Failed">
          {error}
        </Alert>
      </Box>
    </Box>
  );
};

export default FailedError;
