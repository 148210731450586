import React from "react";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles((theme) => ({
  titleClass: {
    fontSize: "1.2rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem",
    },
  },
  descriptionClass: {
    fontSize: "1.2rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem",
    },
  },
  container: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    [theme.breakpoints]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
}));

const InfoCard = ({ imgSrc, title, description }) => {
  const { titleClass, container, descriptionClass } = useStyles();
  return (
    <Box
      component={Card}
      height="100%"
      bgcolor={grey[200]}
      border={1}
      borderColor={grey[100]}
      elevation={0}
    >
      <Box className={container} px={3}>
        <Box p={5} pb={1}>
          <CardMedia
            style={{
              maxWidth: "300px",
              width: "100%",
              margin: "auto",
            }}
            width="auto"
            component="img"
            image={imgSrc}
            alt="QR Code"
          />
        </Box>
        <CardContent>
          <Typography className={titleClass} gutterBottom variant="h5">
            <strong>{title}</strong>
          </Typography>
          <Typography
            className={descriptionClass}
            variant="body1"
            color="text.secondary"
          >
            {description}
          </Typography>
        </CardContent>
      </Box>
    </Box>
  );
};

export default InfoCard;
