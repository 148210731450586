import React, { useEffect, useState } from "react";
import grey from "@material-ui/core/colors/grey";
import { Box, Paper, Typography, useTheme, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import withQRScanner from "../../hocs/withQRScanner";
import { certificationApiFetch } from "../../services/certification";
import PatientInformation from "./PatientInformation";
import FailedError from "./FailedError";
import { certificationApi } from "../../config/config";
import withSkeletonLoading from "../../hocs/withSkeletonLoading";
import LoadingButton from "../../common/LoadingButton";
import Loader from "../../common/Loader";
// import Html5QrcodeScannerPlugin from "../../common/Html5QrcodeScannerPlugin";

const ButtonWithQRScanner = withQRScanner(LoadingButton);
const LoadingPatientInformation = withSkeletonLoading(Loader)(PatientInformation);

const VerifyCertificate = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onScanned = async ({ isScanSuccess, isScanning, data, error }, errorCallback, handleSuccessfull) => {
    setData(null);
    setError("");
    setIsLoading(true);
    certificationApiFetch(`/api/verify`, {
      method: "POST",
      body: data
    })
      .then((res) => {
        if (res.status === "ERROR") {
          setData(null);
          setError("qrCodeIsNotRecognized");
        } else {
          setError(null);
          setData(res);
        }
      })
      .catch((e) => {
        setData(null);
        setError("unknownServerError");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box borderRadius={0} bgcolor={grey[100]}>
      <Box
        style={{
          minHeight: "calc(100vh - 65px)"
        }}
        bgcolor="white"
        mx={"auto"}
        p={3}
        pt={5}
        maxWidth={theme.breakpoints.values.md}
      >
        <Typography align="center" variant="h6" component="h1">
          {t("scanYourCovidCertificateQRCode")}
        </Typography>
        <Box textAlign="center" my={3}>
          <ButtonWithQRScanner
            autoOpen
            color="primary"
            variant="contained"
            size="large"
            onScanned={onScanned}
            type="primary"
            qrboxSize={Math.min(300, window.innerWidth - 180)}
            disabled={isLoading}
            CircularProgressProps={{
              size: 16
            }}
            spacing={1}
            loading={isLoading}
          >
            {t("scan")}
          </ButtonWithQRScanner>
        </Box>
        <LoadingPatientInformation loading={isLoading} loaded={!isLoading && !!data} mask={false} data={data} />
        {error && <FailedError error={t(error)} />}
      </Box>
    </Box>
  );
};

export default VerifyCertificate;
