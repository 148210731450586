export const SET_INTERNAL_1ST_VERIFY_LOADING =
  "SET_INTERNAL_1ST_VERIFY_LOADING";
export const SET_INTERNAL_1ST_VERIFY_DATA = "SET_INTERNAL_1ST_VERIFY_DATA";
export const SET_INTERNAL_1ST_VERIFY_ERROR = "SET_INTERNAL_1ST_VERIFY_ERROR";

export const VERIFY_INTERNAL_1ST_DOSE = "VERIFY_INTERNAL_1ST_DOSE";

export const OPEN_INTERNAL_FIRST_DOSE_VERIFY_MODAL =
  "OPEN_INTERNAL_FIRST_DOSE_VERIFY_MODAL";

export const OPEN_INTERNAL_2ND_SCHEDULING_MODAL =
    "OPEN_INTERNAL_2ND_SCHEDULING_MODAL";
