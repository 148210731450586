import React, { useMemo, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import VaccinationSlotButtonSelect from "./VaccinationSlotButtonSelect";
import { compose } from "redux";
import withDhis2Metadata from "../../hocs/withDhis2Metadata";
import withFormController from "../../hocs/withFormController";
import withSkeletonLoading from "../../hocs/withSkeletonLoading";
import InputSkeleton from "../../skeletons/Input.skeleton";
import { getVaccinationSlotsApi } from "../../services/api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// const LoadingVaccinationButtonSelect = withSkeletonLoading(InputSkeleton)(
//   VaccinationSlotButtonSelect
// );

// setLoading(false);

const VaccinationSlotButtonSelectContainer = ({ id, control }) => {
  const attributes = useSelector((state) => state.metadata.attributes);
  const doseNumber = useSelector((state) => state.cvr.doseNumber);
  const AttributeDhis2FormInput = useMemo(
    () =>
      compose(
        withDhis2Metadata(
          attributes,
          (metadata) => (id) => {
            return metadata.programTrackedEntityAttributes.find(
              ({ trackedEntityAttribute: { id: attrId } }) => {
                return attrId === id;
              }
            );
          },
          (item) => item.trackedEntityAttribute,
          {
            pickedProps: ["id", ["label", ["formName", "name"]]],
          }
        ),
        withFormController({ control }),
        withSkeletonLoading(InputSkeleton)
      )(VaccinationSlotButtonSelect),
    [attributes]
  );

  const { t } = useTranslation();
  const [rawData, setRawData] = useState(null);
  const [loading, setLoading] = useState(false);
  const getVaccinationSlots = ({ vaccinationSite, scheduledDate }) => {
    return getVaccinationSlotsApi({
      vaccinationSite,
      vaccinationDate: moment(scheduledDate, "YYYY-MM-DD").format("YYYYMMDD"),
    });
  };

  const onDependentChangedProps = ([vaccinationSite, scheduledDate]) => {
    setLoading(true);
    if (!!vaccinationSite && !!scheduledDate) {
      getVaccinationSlots({
        vaccinationSite,
        scheduledDate,
      })
        .then((data) => {
          setRawData(data);
        })
        .catch((e) => {
          setRawData(null);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setRawData(null);
    }
  };

  return (
    <AttributeDhis2FormInput
      id={id}
      // Controller props
      ParentComponent={Grid}
      ParentComponentProps={{
        item: true,
        component: Box,
        pt: 3,
      }}
      rules={{
        required: t("thisFieldIsRequired"),
      }}
      dependentFields={["vaccinationSite", "ScsGETliG8f"]}
      onDependentChangedRenderer={([vaccinationSite, scheduledDate]) =>
        !!scheduledDate && !!vaccinationSite
      }
      onDependentChangedProps={onDependentChangedProps}
      //LOADING PROPS
      loading={loading}
      loaded={!!rawData}
      mask
      doseNumber={doseNumber}
      // Com Props
      rawData={rawData}
    />
  );
};

export default VaccinationSlotButtonSelectContainer;
