import { GET_ORG_UNITS, GET_ORG_UNITS_SUCCEED } from "./types";

export const getOrgUnits = (orgUnits) => ({
  type: GET_ORG_UNITS,
});

export const getOrgUnitsSucceed = (orgUnits) => ({
  type: GET_ORG_UNITS_SUCCEED,
  orgUnits,
});
