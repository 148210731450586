import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Hidden from "@material-ui/core/Hidden";
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 50,
    marginRight: theme.spacing(3),
  },
}));

const Layout = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <Hidden mdUp>
        <MobileMenu />
      </Hidden>
      <Hidden smDown>
        <DesktopMenu />
      </Hidden>
      {children}
    </>
  );
};

export default Layout;
