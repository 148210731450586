import React from "react";
import { Route, Switch } from "react-router-dom";
import CVR from "../../pages/CVR";
import { Redirect } from "react-router";
import Guide from "../../pages/Guide";
import VerifyCertificate from "../../pages/VerifyCertificate";
import GenerateCertificate from "../../pages/GenerateCertificate";
import InternationalCertificate from "../../pages/InternationalCertificate";
import LaoDP from "../../pages/LaoDP";
import withLayout from "../../hocs/withLayout";
import TermOfUsePrivacyPolicy from "../../pages/TermOfUsePrivacyPolicy";


const [
  HCVR,
  HGuide,
  HVerifyCertificate,
  HGenerateCertificate,
    HInternationalCertificate,
  HTermOfUsePrivacyPolicy,
] = [
  CVR,
  Guide,
  VerifyCertificate,
  GenerateCertificate,
  InternationalCertificate,
  TermOfUsePrivacyPolicy,
].map((C) => withLayout(C));

const App = () => (
  <Switch>
    <Route exact path="/" render={() => <Redirect to="/cvr" />} />
    <Route path="/cvr" render={() => <HCVR />} />
    <Route path="/guide" render={() => <HGuide />} />
    {/*<Route path="/status" render={() => <CheckStatus />} />*/}
    <Route path="/verify-certificate" render={() => <HVerifyCertificate />} />
    <Route
      path="/generate-certificate"
      render={() => <HGenerateCertificate />}
    />
      <Route
          path="/international-certificate"
          render={() => <HInternationalCertificate />}
      />
    <Route path="/lao-dp" render={() => <LaoDP />} />
    <Route
      path="/terms-of-use-privacy-policy"
      render={() => <HTermOfUsePrivacyPolicy />}
    />
  </Switch>
);

export default App;
