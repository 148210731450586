import React from "react";
import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

const withSkeletonLoading =
  (CustomizedSkeleton = (props) => <Skeleton animation="wave" {...props} />) =>
  (Component) => {
    const SkeletonLoading = ({
      loading,
      mask,
      loaded,
      preRender,
      ...props
    }) => {
      if (!loading && !loaded && !preRender) {
        return null;
      }
      if ((loading && !mask && !preRender) || (mask && !loaded && !preRender)) {
        return <CustomizedSkeleton />;
      }
      return (
        <div
          style={
            loading
              ? {
                  position: "relative",
                  width: "100%",
                  // overflow: "auto",
                  height: "100%",
                  // maxHeight: "calc(100vh - 104px)",
                }
              : {}
          }
        >
          {loading && (
            <Grid
              container
              alignContent="center"
              justify="center"
              style={{
                position: "absolute",
                zIndex: 110,
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(255,255,255,0.7)",
              }}
            >
              <CircularProgress />
            </Grid>
          )}
          <Component loading={loading} loaded={loaded} mask={mask} {...props} />
        </div>
      );
    };
    SkeletonLoading.propTypes = {
      loading: PropTypes.bool.isRequired,
      loaded: PropTypes.bool.isRequired,
      mask: PropTypes.bool,
      preRender: PropTypes.bool,
    };
    SkeletonLoading.propTypes = {
      mask: false,
      loaded: false,
      preRender: false,
    };
    return SkeletonLoading;
  };

export default withSkeletonLoading;
