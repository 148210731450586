import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const SubmitErrorDialog = ({ open, onClose, errors }) => {
  const { t } = useTranslation();
  errors = Array.isArray(errors) ? errors : [errors];
  return (
    <Dialog open={open}>
      <DialogContent>
        <Alert severity="error">
          <AlertTitle>{t("errorTitle")}</AlertTitle>
          {errors.map((error) => (
            <Typography>{error}</Typography>
          ))}
          {/*{t("errorContent")}*/}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          {t("ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitErrorDialog;
