import React from "react";
import ReactDOM from "react-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import locale from "./locale";

import { Provider } from "react-redux";
import configureStore, { history } from "./redux/store";

import { ConnectedRouter } from "connected-react-router";
import "./index.css";
import App from "./App";

export const locales = ["en", "lo"];
let resources = {};

locales.forEach((l) => {
  resources[l] = Object.entries(locale).reduce(
    (accumulator, currentValue) => {
      const key = currentValue[0];
      const value = currentValue[1];
      accumulator.translation[key] = value[l];
      return accumulator;
    },
    { translation: {} }
  );
});
i18n.use(initReactI18next).init({
  resources: locale,
  lng: "lo",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
