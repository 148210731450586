import React, { useLayoutEffect, useState } from "react";
import { convertPdfDoc2FileURL, fillPdf, showPage } from "../../utils";
import withSkeletonLoading from "../../hocs/withSkeletonLoading";
import Loader from "../../common/Loader";
import VaccinationCertificate from "./VaccinationCertificate";

const LoadingVaccinationCertificate = withSkeletonLoading(Loader)(VaccinationCertificate);

const VaccinationCertificateContainer = ({ data: { qr, ...data } }) => {
  const [pdfURL, setPdfURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const generateData = async () => {
    setLoading(true);
    debugger;
    console.log(data);
    const pdfDoc = await fillPdf("OnlineemptyGreencard_V1", {
      data,
      qrCode: qr,
      fontFileName: "saysettha-ot.ttf"
    });
    const fileURL = await convertPdfDoc2FileURL(pdfDoc);
    setPdfURL(fileURL);
    await showPage(pdfDoc, 1, document.getElementById("pdf-canvas"));
    setLoading(false);
  };
  useLayoutEffect(() => {
    generateData();
  }, []);
  return <LoadingVaccinationCertificate loading={loading} loaded={true} pdfURL={pdfURL} preRender />;
};

export default VaccinationCertificateContainer;
