import React from "react";
import ResultDialog from "./ResultDialog";
import { useDispatch, useSelector } from "react-redux";
import { openResultModal } from "../../redux/reducers/cvr/result/actions";
import withSkeletonLoading from "../../hocs/withSkeletonLoading";

const LoadingResultModal = withSkeletonLoading(() => null)(ResultDialog);

const ResultDialogContainer = () => {
  const { isOpenResultModal, data, isLoading } = useSelector(
    (state) => state.cvr.result
  );
  const dispatch = useDispatch();
  return (
    <LoadingResultModal
      loading={isLoading}
      loaded={!!data}
      open={isOpenResultModal}
      onClose={() => {
        dispatch(openResultModal(false));
        window.location.href = "";
      }}
      data={data}
    />
  );
};

export default ResultDialogContainer;
