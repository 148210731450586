import React from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import TranslateIcon from "@material-ui/icons/Translate";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import moment from "moment";

const languages = [
  {
    name: "English",
    value: "en",
  },
  {
    name: "Lao",
    value: "lo",
  },
];
const LanguageSelect = ({ closeDrawer }) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        color="inherit"
        startIcon={<TranslateIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
      >
        {languages.find((lang) => lang.value === i18n.language).name}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((language) => {
          return (
            <MenuItem
              onClick={(e) => {
                i18n.changeLanguage(language.value);
                moment.locale(language.value);
                handleClose();
                closeDrawer && closeDrawer(e);
              }}
            >
              {language.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default LanguageSelect;
