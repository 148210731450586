import React, { useEffect, useMemo } from "react";
import TypedInput from "../../common/TypedInput";
import moment from "moment";

const ScheduledDateSelect = ({
  id,
  onMonthChange,
  availableSlotsInMonthData,
  vaccinationSite,
  value = null,
  onChange,
  ...props
}) => {
  const enabledDates = useMemo(() => {
    return Object.entries(availableSlotsInMonthData.rows)
      .filter(([key, value]) => value)
      .map(([key, value]) => moment(key, "YYYYMMDD"));
  }, [availableSlotsInMonthData]);

  const isInvalidValue = (date) => {
    return !enabledDates.some((enabledDate) => enabledDate.isSame(date, "day"));
  };

  useEffect(() => {
    if (value === null) return;
    // Reset data to null after disable date
    if (isInvalidValue(value)) {
      onChange(null);
    }
  }, [enabledDates]);

  const handleMonthChange = async (date) => {
    return onMonthChange({
      vaccinationSite,
      date,
    });
  };
  return (
    <TypedInput
      id={id}
      variant="dialog"
      inputVariant="outlined"
      disablePast
      format="YYYY-MM-DD"
      onMonthChange={handleMonthChange}
      shouldDisableDate={(date) =>
        enabledDates.every((enabledDate) => !enabledDate.isSame(date, "day"))
      }
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

export default ScheduledDateSelect;
