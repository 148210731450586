import {
  OPEN_INTERNAL_2ND_SCHEDULING_MODAL,
  OPEN_INTERNAL_FIRST_DOSE_VERIFY_MODAL,
  SET_INTERNAL_1ST_VERIFY_DATA,
  SET_INTERNAL_1ST_VERIFY_ERROR,
  SET_INTERNAL_1ST_VERIFY_LOADING,
} from "./types";
import { combineReducers } from "redux";

const isLoading = (state = false, action) => {
  switch (action.type) {
    case SET_INTERNAL_1ST_VERIFY_LOADING:
      return action.isLoading;
    default:
      return state;
  }
};

const data = (state = null, action) => {
  switch (action.type) {
    case SET_INTERNAL_1ST_VERIFY_DATA:
      return action.data;
    default:
      return state;
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case SET_INTERNAL_1ST_VERIFY_ERROR:
      return action.error;
    default:
      return state;
  }
};

const isOpenInternal1stDoseVerifyModal = (state = false, action) => {
  switch (action.type) {
    case OPEN_INTERNAL_FIRST_DOSE_VERIFY_MODAL:
      return action.isOpen;
    default:
      return state;
  }
};

const isOpenInternal2ndDoseSchedulingModal = (state = false, action) => {
  switch (action.type) {
    case OPEN_INTERNAL_2ND_SCHEDULING_MODAL:
      return action.isOpen;
    default:
      return state;
  }
};

export default combineReducers({
  isLoading,
  data,
  error,
  isOpenInternal1stDoseVerifyModal,
  isOpenInternal2ndDoseSchedulingModal,
});
