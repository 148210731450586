import React from "react";
import CVRForm from "./CVRForm";
import withSkeletonLoading from "../../hocs/withSkeletonLoading";
import { useTranslation } from "react-i18next";
import { toServerData } from "./utils";
import { postSubmitData, registerTEI } from "../../services/api";
import get from "lodash/get";
import {
  openResultModal,
  setResultData,
  setResultError,
  setResultLoading,
} from "../../redux/reducers/cvr/result/actions";
import { useDispatch, useSelector } from "react-redux";
import { DOSE_TYPES } from "../../common/TypedInput/DoseSelectRadioGroup";

const LoadingCvrForm = withSkeletonLoading()(CVRForm);

const CvrFormContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.cvr.result);
  const dose = useSelector((state) => state.cvr.doseNumber);

  const onSuccess = (response, payload) => {
    dispatch(openResultModal(true));
    dispatch(setResultData(payload));
    dispatch(setResultError(null));
  };
  const onError = (e) => {
    dispatch(setResultData(null));
    dispatch(
      setResultError(
        get(
          e,
          [
            "result",
            "response",
            "importSummaries",
            "0",
            "conflicts",
            "0",
            "value",
          ],
          get(e, ["message"], t("unknownServerError"))
        )
      )
    );
  };

  const onResponse = (response, payload) => {
    if (
      !response ||
      response.status === "error" ||
      response.status === "warning"
    ) {
      onError(response);
    } else {
      onSuccess(response, payload);
    }
  };

  const onFinally = () => {
    dispatch(setResultLoading(false));
  };
  const onSubmit = (rawData, e) => {
    dispatch(setResultLoading(true));
    switch (dose) {
      case DOSE_TYPES.FIRST: {
        const payload = toServerData(rawData);
        postSubmitData(payload)
          .then((response) => {
            onResponse(response, payload);
          })
          .catch(onError)
          .finally(onFinally);
        break;
      }
      case DOSE_TYPES.SECOND:
      case DOSE_TYPES.BOOSTER: {
        const payload = { ...toServerData(rawData), dose };
        registerTEI(payload)
          .then((response) => {
            onResponse(response, payload);
          })
          .catch(onError)
          .finally(onFinally);
        break;
      }
    }
  };
  return (
    <LoadingCvrForm
      loading={isLoading}
      mask
      loaded={true}
      onSubmit={onSubmit}
    />
  );
};

export default CvrFormContainer;
