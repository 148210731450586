import {
  OPEN_INTERNAL_2ND_SCHEDULING_MODAL,
  OPEN_INTERNAL_FIRST_DOSE_VERIFY_MODAL,
  SET_INTERNAL_1ST_VERIFY_DATA,
  SET_INTERNAL_1ST_VERIFY_ERROR,
  SET_INTERNAL_1ST_VERIFY_LOADING,
  VERIFY_INTERNAL_1ST_DOSE,
} from "./types";

export const setVerifyInternal1stDialogLoading = (isLoading) => ({
  type: SET_INTERNAL_1ST_VERIFY_LOADING,
  isLoading,
});

export const setVerifyInternal1stDoseData = (data) => ({
  type: SET_INTERNAL_1ST_VERIFY_DATA,
  data,
});

export const setVerifyInternal1stError = (error) => ({
  type: SET_INTERNAL_1ST_VERIFY_ERROR,
  error,
});

export const openInternalFirstDoseVerifyModal = (isOpen) => ({
  type: OPEN_INTERNAL_FIRST_DOSE_VERIFY_MODAL,
  isOpen,
});

export const openInternal2ndSchedulingModal = (isOpen) => ({
  type: OPEN_INTERNAL_2ND_SCHEDULING_MODAL,
  isOpen,
});
