import { takeLatest, put } from "redux-saga/effects";
import {
  GET_ATTRIBUTES,
  GET_LATEST_ATTRIBUTES,
} from "../reducers/metadata/attributes/types";
import { getAttributesSucceed } from "../reducers/metadata/attributes/actions";
import { recursiveTranslate } from "../../utils";
import attributes from "../../config/attributes.json";

function* getAttributesFlow({ locale }) {
  yield put(getAttributesSucceed(recursiveTranslate(locale)(attributes)));
}

export function* getAttributes() {
  yield takeLatest(GET_ATTRIBUTES, getAttributesFlow);
}

function* getLatestAttributesFlow({ locale, newAttrs }) {
  attributes.programTrackedEntityAttributes.push({
    trackedEntityAttribute: newAttrs.trackedEntityAttributes[0],
  });
  yield put(getAttributesSucceed(recursiveTranslate(locale)(attributes)));
}

export function* getLatestAttributes() {
  yield takeLatest(GET_LATEST_ATTRIBUTES, getLatestAttributesFlow);
}
