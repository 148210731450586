import React, { useMemo, useState } from "react";
import Box from "@material-ui/core/Box";
import TypedInput from "../../common/TypedInput";
import { Button, FormHelperText, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DHIS2_TYPES } from "../../common/TypedInput/utils";
import { useForm } from "react-hook-form";
import withFormController from "../../hocs/withFormController";
import { useSelector } from "react-redux";
import { compose } from "redux";
import withDhis2Metadata from "../../hocs/withDhis2Metadata";
import CVIDInput from "../CVR/CVIDInput";
import NewSimpleCaptcha from "../CVR/NewSimpleCaptcha";
import { randomIntFromInterval } from "../../utils";
import useQuery from "../../hooks/useQuery";
import { PHONE_PREFIX } from "../../constants/global";

const SearchForm = ({ onSubmit, data, loading }) => {
  const attributes = useSelector((state) => state.metadata.attributes);
  const { control, handleSubmit, setValue } = useForm();
  const { t } = useTranslation();
  const [captchaValue, setCaptchaValue] = useState("");
  const [rootCaptchaValue, setRootCaptchaValue] = useState("");
  const [captchaMessage, setCaptchaMessage] = useState("");

  const AttributeDhis2FormInput = useMemo(
    () =>
      compose(
        withDhis2Metadata(
          attributes,
          (metadata) => (id) => {
            return metadata.programTrackedEntityAttributes.find(
              ({ trackedEntityAttribute: { id: attrId } }) => {
                return attrId === id;
              }
            );
          },
          (item) => item.trackedEntityAttribute,
          {
            pickedProps: [
              "id",
              ["label", ["formName", "name"]],
              "optionSet",
              "valueType",
            ],
          }
        ),
        withFormController({ control })
      )(TypedInput),
    [attributes]
  );

  const validateCaptcha = (captchaValue) => captchaValue === rootCaptchaValue;

  const generateCaptchaValue = () =>
    Array(4)
      .fill()
      .map(() => randomIntFromInterval(0, 9))
      .join("");

  const internalSubmit = (values) => {
    if (!validateCaptcha(captchaValue)) {
      return setCaptchaMessage(t("wrongValue"));
    }
    onSubmit(values);
    [
      "sB1IHYu2xQT",
      "ENRjVGxVL6l",
      "ciCR6BBvIT4",
      "corXnplgfQ7",
      "DmuazFb368B",
    ].map((id) => setValue(id, ""));
    setValue("tQeFLjYbqzv", null);
    setCaptchaValue("");
    setRootCaptchaValue(generateCaptchaValue());
  };

  const params = useQuery();

  return (
    <form onSubmit={handleSubmit(internalSubmit)}>
      <Grid spacing={3} container>
        <Grid xs={12} md={6} item>
          <AttributeDhis2FormInput
            ParentComponent={Grid}
            ParentComponentProps={{ item: true }}
            // valueType={DHIS2_TYPES.TEXT}
            id="sB1IHYu2xQT"
            rules={{
              required: t("thisFieldIsRequired"),
            }}
            defaultValue={params.get("firstName")}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <AttributeDhis2FormInput
            ParentComponent={Grid}
            ParentComponentProps={{ item: true }}
            // valueType={DHIS2_TYPES.TEXT}
            id="ENRjVGxVL6l"
            rules={{
              required: t("thisFieldIsRequired"),
            }}
            defaultValue={params.get("lastName")}
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          item
          container
          style={{
            marginBottom: -4,
          }}
        >
          <AttributeDhis2FormInput
            ParentComponent={Grid}
            ParentComponentProps={{
              item: true,
              xs: 12,
              alignItems: "flex-end",
              container: true,
            }}
            id="ciCR6BBvIT4"
            // valueType={DHIS2_TYPES.NUMBER}
            rules={{
              required: t("thisFieldIsRequired"),
              maxLength: {
                value: 11,
                message: t("phoneNumberHasMaximum11Digits"),
              },
              pattern: {
                value: PHONE_PREFIX,
                message: t("phoneNumberHasToStartWithLaosAndVietnamese"),
              },
            }}
            defaultValue={params.get("phoneNumber")}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <AttributeDhis2FormInput
            id="corXnplgfQ7"
            valueType={DHIS2_TYPES.OTHER}
            Component={CVIDInput}
            variant="outlined"
            size="small"
            autoFocus
            length={4}
            ParentComponent={Box}
            ParentComponentProps={{
              component: Grid,
              item: true,
              width: 1,
              pt: 0,
              pb: 0,
            }}
            rules={{
              required: t("thisFieldIsRequired"),
              pattern: {
                value: /^[A-Z]\d{4}-[A-Z]-(\d|[A-Z])\d{4}$/,
                message: t("wrongCVIDFormat"),
              },
            }}
            defaultValue={params.get("systemCVID")}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <AttributeDhis2FormInput
            ParentComponent={Grid}
            ParentComponentProps={{ item: true }}
            variant="dialog"
            inputVariant="outlined"
            id="tQeFLjYbqzv"
            // valueType={DHIS2_TYPES.DATE}
            // defaultValue={null}
            disableFuture
            // autoOk
            format="YYYY-MM-DD"
            rules={{
              required: t("thisFieldIsRequired"),
              /*validate: {
                mustGreaterThan17: (value) => {
                  const age = moment().diff(value, "years");
                  if (age < 17) {
                    return t("under17yo");
                  }
                },
              },*/
            }}
            views={["year", "month", "date"]}
            openTo="year"
            defaultValue={params.get("dateOfBirth") || null}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <AttributeDhis2FormInput
            ParentComponent={Grid}
            ParentComponentProps={{ item: true }}
            id="DmuazFb368B"
            // // valueType={DHIS2_TYPES.TEXT}
            rules={{
              required: t("thisFieldIsRequired"),
            }}
            defaultValue={params.get("sex")}
          />
        </Grid>
      </Grid>
      {/*Captcha*/}
      <Box pt={3} textAlign="center">
        <NewSimpleCaptcha
          value={captchaValue}
          onChange={(e) => {
            setCaptchaValue(e.target.value);
            setCaptchaMessage("");
          }}
          placeholder={t("captchaText")}
          reloadText={t("reloadCaptcha")}
          error={!!captchaMessage}
          captchaValue={rootCaptchaValue}
          setCaptchaValue={setRootCaptchaValue}
        />
        {captchaMessage && (
          <FormHelperText
            style={{
              textAlign: "center",
            }}
            error
          >
            {t("wrongValue")}
          </FormHelperText>
        )}
      </Box>
      <Box pt={5} textAlign="center">
        <Button color="primary" variant="contained" type="submit">
          <Grid container alignItems="center" justify="center">
            {t("generate")}
          </Grid>
        </Button>
      </Box>
    </form>
  );
};

export default SearchForm;
