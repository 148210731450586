import React from "react";

import { Grid, makeStyles } from "@material-ui/core";
import logo from "../../asset/logo.png";
import moh from "../../asset/MOH.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    // maxWidth: 40,
    maxHeight: 30,
    marginRight: theme.spacing(1),
  },
}));

const Logo = () => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" wrap="nowrap">
      <img className={classes.logo} src={logo} />
      <img className={classes.logo} src={moh} />
    </Grid>
  );
};

export default Logo;
