import React from "react";
import ResultDialogContainer from "./ResultDialogContainer";
import SubmitErrorDialogContainer from "./SubmitErrorDialog.container";
import SchedulingInternal2ndDoseDialogContainer from "./SchedulingInternal2ndDoseDialog.container";
import VerifyInternalDoseDialogContainer from "./VerifyInternalDoseDialog.container";
import CvrFormContainer from "./CVRForm.container";
import grey from "@material-ui/core/colors/grey";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { Divider, useTheme } from "@material-ui/core";
import Heading from "./Heading";

const CVRPage = () => {
  const theme = useTheme();
  return (
    <>
      <Box minHeight="calc(100vh - 65px)" borderRadius={0} bgcolor={grey[100]}>
        <Box mx="auto" maxWidth={theme.breakpoints.values.md}>
          <Paper elevation={0}>
            <Heading />
            <Divider />
            <CvrFormContainer />
          </Paper>
        </Box>
      </Box>
      <ResultDialogContainer />
      <SubmitErrorDialogContainer />
      {/*<VerifyExternal1StDialogContainer />*/}
      <SchedulingInternal2ndDoseDialogContainer />
      <VerifyInternalDoseDialogContainer />
    </>
  );
};

export default CVRPage;
