import orgUnits from "./orgUnits";
import attributes from "./attributes";
import dataElements from "./dataElements";
import villageHierarchy from "./villageHierarchy";
import { combineReducers } from "redux";

const metadata = combineReducers({
  orgUnits,
  attributes,
  dataElements,
  villageHierarchy,
});

export default metadata;
