import { OPEN_EXTERNAL_1ST_DOSE_VERIFY } from "./types";
import { combineReducers } from "redux";

const isOpenVerifyModal = (state = false, action) => {
  switch (action.type) {
    case OPEN_EXTERNAL_1ST_DOSE_VERIFY:
      return action.isOpen;
    default:
      return state;
  }
};
export default combineReducers({
  isOpenVerifyModal,
});
