import React from "react";
import Layout from "../common/Layout";

const withLayout = (Component) => (props) => {
  return (
    <Layout>
      <Component />
    </Layout>
  );
};

export default withLayout;
