import {
  IS_INTERNAL_PRE_SVR,
  OPEN_INTERNATIONAL_CVR,
  SET_REGISTRATION_DOSE,
} from "./types";
import { combineReducers } from "redux";
import internalFirstDose from "./internalFirstDose";
import externalFirstDose from "./externalFirstDose";
import result from "./result";

const doseNumber = (state = null, action) => {
  switch (action.type) {
    case SET_REGISTRATION_DOSE:
      return action.doseNumber;
    default:
      return state;
  }
};

const isInternalPreCVR = (state = null, action) => {
  // use internal system vaccinatelaos.la
  switch (action.type) {
    case IS_INTERNAL_PRE_SVR:
      return action.isInternal;
    default:
      return state;
  }
};

const openInternationalCVR = (state = null, action) => {
  switch (action.type) {
    case OPEN_INTERNATIONAL_CVR:
      return action.open;
    default:
      return state;
  }
};

export default combineReducers({
  doseNumber,
  isInternalPreCVR,
  openInternationalCVR,
  internalFirstDose,
  externalFirstDose,
  result,
});
