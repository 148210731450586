import React from "react";
import page1 from "../../asset/images/Vaccines_1.png";
import page2 from "../../asset/images/Vaccines_2.png";
import page3 from "../../asset/images/ghvh__1_.png";
import page4 from "../../asset/images/ghvh__2_.png";
import page5 from "../../asset/images/ອາການຂ້າງຄຽງທີ່ພົບເຫັນທົ່ວໄປຫຼັງຈາກການຮັບວັກຊີນກັນພະຍາດໂຄວິດ-19.png";
import page6 from "../../asset/images/VPD_COVID-19 vaccine job aide_Pao_04jun21_final-1.jpg";
import page7 from "../../asset/images/VPD_COVID-19 vaccine job aide_Pao_04jun21_final-2.jpg";
import page8 from "../../asset/images/VPD_COVID-19 vaccine job aide_Pao_04jun21_final-3.jpg";
import { Box, Container, Grid } from "@material-ui/core";
const Guide = () => {
  // const [size, setSize] = useState({
  //   width: 0,
  //   height: 0,
  // });
  // const offset = 6;
  // const defaultHeaderHeight = 64;
  //
  // const setWindowSize = throttle(() => {
  //   const $headers = document.getElementsByTagName("header");
  //   let headerHeight = defaultHeaderHeight;
  //   if ($headers && $headers.length) {
  //     const $header = $headers[0];
  //     headerHeight = $header.getBoundingClientRect().height;
  //   }
  //   setSize({
  //     width: window.innerWidth,
  //     height: window.innerHeight - (headerHeight + offset),
  //   });
  // }, 200);

  // useLayoutEffect(() => {
  //   setWindowSize();
  //   window.addEventListener("resize", setWindowSize);
  //   return () => window.removeEventListener("resize", setWindowSize);
  // }, []);

  return (
    <Container disableGutters maxWidth="md" p={0}>
      <Box component={"img"} maxWidth={1} src={page1} />
      <Box component={"img"} maxWidth={1} src={page2} />
      <Box component={"img"} maxWidth={1} src={page3} />
      <Box component={"img"} maxWidth={1} src={page4} />
      <Box component={"img"} maxWidth={1} src={page5} />
      <Box component={"img"} maxWidth={1} src={page6} />
      <Box component={"img"} maxWidth={1} src={page7} />
      <Box component={"img"} maxWidth={1} src={page8} />
      {/*<embed*/}
      {/*    frameBorder="0"*/}
      {/*    scrolling="auto"*/}
      {/*    src={pdfFile}*/}
      {/*    width="100%"*/}
      {/*    height={size.height}*/}
      {/*/>*/}
    </Container>
  );
};

export default Guide;
