import { GET_DATA_ELEMENTS_SUCCEED, GET_DATA_ELEMENTS } from "./types";

export const getDataElements = (locale) => ({
  type: GET_DATA_ELEMENTS,
  locale,
});

export const getDataElementsSucceed = (dataElements) => ({
  type: GET_DATA_ELEMENTS_SUCCEED,
  dataElements,
});
