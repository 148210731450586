const DHIS2_TYPES = {
  TEXT: "TEXT",
  INTEGER_POSITIVE: "INTEGER_POSITIVE",
  INTEGER_NEGATIVE: "INTEGER_NEGATIVE",
  INTEGER_ZERO_OR_POSITIVE: "INTEGER_ZERO_OR_POSITIVE",
  PERCENTAGE: "PERCENTAGE",
  NUMBER: "NUMBER",
  INTEGER: "INTEGER",
  PHONE_NUMBER: "PHONE_NUMBER",
  EMAIL: "EMAIL",
  LONG_TEXT: "LONG_TEXT",
  DATE: "DATE",
  DATETIME: "DATETIME",
  TIME: "TIME",
  BOOLEAN: "BOOLEAN",
  TRUE_ONLY: "TRUE_ONLY",
  AGE: "AGE",
  CALENDAR: "CALENDAR",
  VILLAGE_SELECTOR: "VILLAGE_SELECTOR",
  OTHER: "OTHER",
  FILE_RESOURCE: "FILE_RESOURCE",
  YES_NO: "YES_NO",
};

export { DHIS2_TYPES };
