import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import VaccinationSiteSelectContainer from "./VaccinationSiteSelect.container";
import ScheduledDateSelectContainer from "./ScheduledDateSelect.container";
import VaccinationSlotButtonSelectContainer from "./VaccinationSlotButtonSelect.container";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../common/LoadingButton";
import { useSelector } from "react-redux";
import { DOSE_TYPES } from "../../common/TypedInput/DoseSelectRadioGroup";

const SchedulingInternal2ndDoseDialog = ({
  open,
  onCancel,
  onSubmit,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, register } = useForm({
    mode: "onChange",
  });

  const doseNumber = useSelector((state) => state.cvr.doseNumber);

  useEffect(() => {
    register("doseNumber", {
      value: doseNumber,
    });
  }, []);

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>
        {doseNumber === DOSE_TYPES.SECOND
          ? t("scheduleYour2ndDose")
          : t("scheduleYourBoosterDose")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={0} direction="column">
          <VaccinationSiteSelectContainer
            control={control}
            id="vaccinationSite"
          />
          <ScheduledDateSelectContainer id="ScsGETliG8f" control={control} />
          <VaccinationSlotButtonSelectContainer
            id="uG58XyIlYFr"
            control={control}
            // formState={formState}
            // Other dependent form fields inside the component
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t("cancel")}</Button>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LoadingButton
            CircularProgressProps={{
              size: 16,
            }}
            spacing={1}
            loading={isLoading}
            disabled={isLoading}
            type="submit"
          >
            {t("submit")}
          </LoadingButton>
        </form>
      </DialogActions>
    </Dialog>
  );
};

export default SchedulingInternal2ndDoseDialog;
