import { GET_ORG_UNITS, GET_ORG_UNITS_SUCCEED } from "./types";

const orgUnits = (state = null, action) => {
  switch (action.type) {
    case GET_ORG_UNITS:
      return null;
    case GET_ORG_UNITS_SUCCEED:
      return action.orgUnits;
    default:
      return state;
  }
};

export default orgUnits;
