import React, { useState, forwardRef, useImperativeHandle } from "react";
import { FormHelperText, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#000",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    outline: "none",
    transition: "border .24s ease-in-out"
  },
  thumb: {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    height: "auto",
    padding: 4,
    boxSizing: "border-box"
  },
  img: {
    display: "block",
    maxWidth: 300,
    maxHeight: 400,
    objectFit: "scale-down",
    [theme.breakpoints.up("md")]: {
      maxWidth: 600
    }
  },
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16
  }
}));

const DragDropFile = forwardRef(
  (
    {
      // dhis2 data
      label,
      id,
      // MUI
      value,
      onChange,
      ...props
    },
    ref
  ) => {
    const { thumb, thumbInner, img, container, thumbsContainer } = useStyles();
    const { t } = useTranslation();
    const [files, setFiles] = useState(null);
    const { getRootProps, getInputProps, open } = useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        );

        Object.values(acceptedFiles).forEach((file) => {
          let blobFile = new Blob([file]);
          onChange({ name: file.name, data: blobFile });
        });
        console.log("acceptedFiles", acceptedFiles);
      }
    });

    useImperativeHandle(ref, () => ({
      onRemove() {
        console.log("onRemove");
        setFiles(null);
      }
    }));

    return (
      <>
        <InputLabel
          error={props.error}
          required={props.required}
          // variant={variant}
        >
          {label}
        </InputLabel>
        <section className={container}>
          <div {...getRootProps({ className: "dropzone" })} style={{ textAlign: "center" }}>
            <input {...getInputProps()} />
            {/* <p>{t("passportAction")}</p> */}
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                open();
              }}
            >
              {t("choosePhoto")}
            </button>
          </div>
          {files && (
            <aside className={thumbsContainer}>
              <div className={thumb} key={1}>
                <div className={thumbInner}>
                  <img src={files[0].preview} className={img} />
                </div>
              </div>
            </aside>
          )}
          {props.error && <FormHelperText error>{props.helperText}</FormHelperText>}
        </section>
      </>
    );
  }
);

export default DragDropFile;
