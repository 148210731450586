import React from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Grid } from "@material-ui/core";
// import "./CascadeSelector.css";

const CascadeSelector = ({
  hierarchy,
  labels,
  selections,
  change,
  ...props
}) => {
  return (
    <Grid container direction="column" spacing={2}>
      {labels.map((label, index) => {
        const options = hierarchy.filter((item) => {
          const splitted = item.path.split("/");
          if (index === 0) {
            return splitted.length === index + 1;
          } else if (selections[index - 1]) {
            return (
              splitted.length === index + 1 &&
              splitted[index - 1] === selections[index - 1]
            );
          }
        });
        return (
          <Grid item>
            <FormControl
              disabled={options.length}
              variant={props.variant}
              size={props.size}
              fullWidth
            >
              <InputLabel disabled={options.length} variant={props.variant}>
                {label}
              </InputLabel>
              <Select
                disabled={!options.length}
                variant={props.variant}
                label={label}
                // showSearch
                // allowClear={true}
                // value={selections[index]}
                // style={{ width: "100%" }}
                // onChange={(value, option) => {
                //     selections[index] = value;
                //     for (let i = labels.length - 1; i > index; i--) {
                //         selections[i] = undefined;
                //     }
                //     change(selections);
                // }}
              >
                {options.map((item) => {
                  return (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CascadeSelector;
