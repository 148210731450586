import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, useTheme } from "@material-ui/core";
import withSkeletonLoading from "../../hocs/withSkeletonLoading";
import ResultDialogContainer from "./ResultDialogContainer";
import grey from "@material-ui/core/colors/grey";
import RequestForm from "./RequestForm";
import { useTranslation } from "react-i18next";
import { checkStatusForSecondDose, requestInternationalCertificate, upFile } from "../../services/api";
import {
  openResultModal,
  setResultData,
  setResultError,
  setResultLoading
} from "../../redux/reducers/cvr/result/actions";
import { useDispatch, useSelector } from "react-redux";
import { openInternationalCVR } from "../../redux/reducers/cvr/actions";
import CommonDialog from "../../common/CommonDialog/CommonDialog";
import moment from "moment";
import { getTrackedEntityAttributes } from "../../services/api";

const RequestFormLoading = withSkeletonLoading()(RequestForm);

const InternationalCertificatePage = () => {
  const { i18n, t } = useTranslation();
  const [openResultDialog, setOpenResultDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const dispatch = useDispatch();
  const [patientData, setPatientData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const theme = useTheme();

  useEffect(() => {
    // dispatch(openResultModal(true));
    // dispatch(
    //   setResultData({
    //     dateToComePickup: "1",
    //     placeComeToPick: "1",
    //     fullName: "1",
    //     passportNumber: "1",
    //   })
    // );
  }, []);

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  };

  const onSubmit = async ({
    dETjvpUuvjV: content,
    Qle5f5LHdm7: contentGreenCard,
    I0GHGtoEoUq: dataOfIssue,
    ...restVal
  }) => {
    setLoading(true);

    let fileUploadResult = null;
    let fileUploadResultGreenCard = null;
    // const formData = new FormData();
    // formData.append("file", content.data, content.name);
    // fileUploadResult = await upFile(formData);
    // const formDataGC = new FormData();
    // formDataGC.append("file", contentGreenCard.data, contentGreenCard.name);
    // fileUploadResultGreenCard = await upFile(formDataGC);
    const passportImage = await blobToBase64(content.data);
    const greencardImage = await blobToBase64(contentGreenCard.data);

    requestInternationalCertificate({
      ...patientData,
      ...restVal,
      I0GHGtoEoUq: moment(dataOfIssue, "DD/MM/YYYY").format("YYYY-MM-DD"),
      mdOFE0v5ky2: "pending",
      passportImage,
      greencardImage,
      passportImageName: content.name,
      greencardImageName: contentGreenCard.name
    })
      .then(async (result) => {
        setLoading(false);
        onSuccess(null, result);
      })
      .finally(() => {
        setLoading(false);
        dispatch(openInternationalCVR(false));
      });

    setError("");
  };

  const onSuccess = (res, data) => {
    dispatch(openResultModal(true));
    dispatch(setResultData(data));
    dispatch(setResultError(null));
  };

  const onNotFound = () => {
    // setShowProceedButton(true);
    // dispatch(setVerifyInternal1stError(t("registerAgain")));
  };

  const onError = (res) => {
    // try {
    //   res.json().then((res) => {
    //     dispatch(openInternalFirstDoseVerifyModal(false));
    //     dispatch(setResultError(res.message));
    //   });
    // } catch (e) {
    //   dispatch(openInternalFirstDoseVerifyModal(false));
    //   dispatch(setResultError(t("unknownServerError")));
    // }
  };

  const searchSubmit = (values) => {
    const { ciCR6BBvIT4: phoneNumber, corXnplgfQ7: CVID, tQeFLjYbqzv: dob } = values;
    setLoading(true);
    checkStatusForSecondDose({
      CVID,
      phoneNumber: phoneNumber ? phoneNumber : "",
      locale: i18n.language,
      dob: dob || ""
    })
      .then((res) => {
        dispatch(openInternationalCVR(true));
        setPatientData(values);
      })
      .catch((res) => {
        switch (res.status) {
          // 406: 1 dose type:Eligible for booster dose, not eligible for second dose
          // 413: vaccinated
          case 405:
          case 406:
          case 413: {
            dispatch(openInternationalCVR(true));
            setPatientData(values);
            break;
          }
          case 411:
          case 412:
          case 414:
          case 408: {
            dispatch(openInternationalCVR(false));
            setOpenResultDialog(true);
            setDialogTitle(t("profileSeachResultTitle"));
            setDialogContent(t("profileNotEligible"));
            break;
          }
          // Not found
          case 404: {
            onNotFound();
            dispatch(openInternationalCVR(false));
            setOpenResultDialog(true);
            setDialogTitle(t("profileSeachResultTitle"));
            setDialogContent(t("profileNotFound"));
            break;
          }
          default: {
            onError(res);
            dispatch(openInternationalCVR(false));
          }
        }
      })
      .finally(() => {
        // dispatch(setVerifyInternal1stDialogLoading(false));
        setLoading(false);
      });
  };
  const onClose = () => {
    setOpenResultDialog(false);
  };

  return (
    <Box borderRadius={0} bgcolor={grey[100]}>
      <Box
        style={{ minHeight: "calc(100vh - 65px)" }}
        bgcolor={"white"}
        mx={"auto"}
        maxWidth={theme.breakpoints.values.md}
      >
        <Paper elevation={0}>
          <Box padding={3}>
            <Box mb={2} mt={1}>
              <Typography component={"h1"} variant={"h5"} align={"center"}>
                {t("generateInternationalCertificate")}
              </Typography>
              <Typography
                variant={"body1"}
                align={"center"}
                dangerouslySetInnerHTML={{ __html: t("travelCertAnnouncement") }}
              ></Typography>
              <Typography variant={"body1"} align={"center"} style={{ color: "red" }}>
                {t("travelCertRequirement")}
              </Typography>
              <Typography variant={"body1"} align={"center"} style={{ color: "red" }}>
                {t("travelCertRequirement2")}
              </Typography>
            </Box>
            <ResultDialogContainer />
            <CommonDialog
              open={openResultDialog}
              data={{
                title: dialogTitle,
                content: dialogContent
              }}
              onClose={onClose}
            />
            <RequestFormLoading
              onSubmit={onSubmit}
              searchSubmit={searchSubmit}
              setLoading={setLoading}
              loading={loading}
              loaded={true}
              mask
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default InternationalCertificatePage;
