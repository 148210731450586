import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import metadata from "./metadata";
import cvr from "./cvr";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    metadata,
    cvr,
  });

export default createRootReducer;
