import React from "react";
import { useLocation } from "react-router-dom";

export default function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

// AEFI date later than 1st Decision date
// VM5185:894 AEFI date later than 1st Decision date
