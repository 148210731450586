import React from "react";
import SubmitErrorDialog from "./SubmitErrorDialog";
import { useDispatch, useSelector } from "react-redux";
import { setResultError } from "../../redux/reducers/cvr/result/actions";
import withSkeletonLoading from "../../hocs/withSkeletonLoading";

const LoadingSubmitErrorDialog = withSkeletonLoading(() => null)(
  SubmitErrorDialog
);

const SubmitErrorDialogContainer = () => {
  const { error, isLoading } = useSelector((state) => state.cvr.result);
  const dispatch = useDispatch();
  return (
    <LoadingSubmitErrorDialog
      loading={isLoading}
      loaded={!!error}
      open={Array.isArray(error) ? !!error.length : !!error}
      onClose={() => {
        dispatch(setResultError(null));
        window.location.reload();
      }}
      errors={error}
    />
  );
};

export default SubmitErrorDialogContainer;
