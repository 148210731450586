import {
  IS_INTERNAL_PRE_SVR,
  OPEN_INTERNATIONAL_CVR,
  SET_REGISTRATION_DOSE,
} from "./types";

export const setRegistrationDose = (doseNumber) => ({
  type: SET_REGISTRATION_DOSE,
  doseNumber,
});

export const setIsInternalPreSVR = (isInternal) => ({
  type: IS_INTERNAL_PRE_SVR,
  isInternal,
});

export const openInternationalCVR = (open) => ({
  type: OPEN_INTERNATIONAL_CVR,
  open,
});
