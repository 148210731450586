import React from "react";
import { Box, Container, Grid, Typography, Button, makeStyles } from "@material-ui/core";
import iosDownload from "../../../assets/images/ios_download.png";
import androidDownload from "../../../assets/images/android_download.png";
import apkDownload from "../../../assets/images/apk_download.png";
import banner from "../../../assets/images/banner2.png";
import ResponsiveAppBar from "./ResponsiveAppBar";

const useStyles = makeStyles((theme) => ({
  logoImg: {
    width: 180,
    height: "unset",
    [theme.breakpoints.up("md")]: {
      width: "unset",
      height: 65
    }
  },
  bannerClass: {
    backgroundColor: "#abe9cd",
    backgroundImage: "linear-gradient(315deg, #abe9cd 0%, #3eadcf 74%)"
  },
  wrapper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10)
    }
  },
  title: {
    fontSize: "2rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "3rem"
    }
  },
  subTitle: {
    fontSize: "1rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem"
    }
  },
  leftSide: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginBottom: 0
    }
  }
}));

const Banner = () => {
  const { bannerClass, wrapper, title, subTitle, leftSide, logoImg } = useStyles();
  return (
    <Box className={bannerClass}>
      <ResponsiveAppBar />
      <Box className={wrapper}>
        <Container>
          <Grid container alignItems="center">
            <Grid className={leftSide} item container spacing={2} direction="column" xs={12} md={6}>
              <Grid item>
                <Box component={Typography} mb={2} variant="h3" color="white" className={title}>
                  <strong>Lao Covid DP</strong>
                </Box>
                <Box className={subTitle} component={Typography} variant="h5" color="white">
                  <strong>Lao Covid Digital Proof, National Application</strong>
                </Box>
              </Grid>
              <Grid container item>
                <Grid item>
                  <Button
                    component="a"
                    href="https://play.google.com/store/apps/details?id=org.hispvietnam.laocoviddp"
                    target="_blank"
                  >
                    <img className={logoImg} src={androidDownload} />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    component="a"
                    href="https://apps.apple.com/app/laos-covid-digital-proof/id1593158557"
                    target="_blank"
                  >
                    <img className={logoImg} src={iosDownload} />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    component="a"
                    href="https://drive.google.com/file/d/1a4lemn6PkvswQ_ruWEcHmzVTsenaYodv/view"
                    target="_blank"
                  >
                    <img className={logoImg} src={apkDownload} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                textAlign: "center"
              }}
              item
              xs={12}
              md={6}
            >
              <Box
                style={{
                  maxWidth: 600,
                  width: "100%"
                }}
                component="img"
                src={banner}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Banner;
