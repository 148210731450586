import { takeLatest, put } from "redux-saga/effects";
import { GET_DATA_ELEMENTS } from "../reducers/metadata/dataElements/types";
import { getDataElementsSucceed } from "../reducers/metadata/dataElements/actions";
import { recursiveTranslate } from "../../utils";
import dataElements from "../../config/dataElements.json";

function* getDataElementsFlow({ locale }) {
  yield put(getDataElementsSucceed(recursiveTranslate(locale)(dataElements)));
}

function* getDataElements() {
  yield takeLatest(GET_DATA_ELEMENTS, getDataElementsFlow);
}

export default getDataElements;
