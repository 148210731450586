import React, { useMemo, useState } from "react";
import ScheduledDateSelect from "./ScheduledDateSelect";
import { compose } from "redux";
import withDhis2Metadata from "../../hocs/withDhis2Metadata";
import withFormController from "../../hocs/withFormController";
import { Box, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";
import withSkeletonLoading from "../../hocs/withSkeletonLoading";
import InputSkeleton from "../../skeletons/Input.skeleton";
import { DOSE_PARAM_TYPES, getAvailableSlotsInMonth } from "../../services/api";
import { useSelector } from "react-redux";
import { DOSE_TYPES } from "../../common/TypedInput/DoseSelectRadioGroup";

const ScheduledDateSelectContainer = ({ id, control }) => {
  const attributes = useSelector((state) => state.metadata.attributes);
  const doseNumber = useSelector((state) => state.cvr.doseNumber);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const ScheduledDateSelectFormInput = useMemo(
    () =>
      compose(
        withDhis2Metadata(
          attributes,
          (metadata) => (id) => {
            return metadata.programTrackedEntityAttributes.find(
              ({ trackedEntityAttribute: { id: attrId } }) => {
                return attrId === id;
              }
            );
          },
          (item) => item.trackedEntityAttribute,
          {
            pickedProps: [
              "id",
              ["label", ["formName", "name"]],
              "optionSet",
              "valueType",
            ],
          }
        ),
        withFormController({ control }),
        withSkeletonLoading(InputSkeleton)
      )(ScheduledDateSelect),
    [attributes]
  );

  const getDoseParam = (doseNumber) => {
    switch (doseNumber) {
      case DOSE_TYPES.FIRST:
        return DOSE_PARAM_TYPES.FIRST;
      case DOSE_TYPES.SECOND:
        return DOSE_PARAM_TYPES.SECOND;
      case DOSE_TYPES.BOOSTER:
        return DOSE_PARAM_TYPES.BOOSTER;
      default:
        return "";
    }
  };

  const getAvailableSlots = async ({
    vaccinationSite,
    doseNumber,
    dateInMonth = moment(),
  }) =>
    getAvailableSlotsInMonth({
      dose: getDoseParam(doseNumber),
      ou: vaccinationSite,
      startDate: moment(dateInMonth).startOf("month").format("YYYY-MM-DD"),
      endDate: moment(dateInMonth).endOf("month").format("YYYY-MM-DD"),
    })
      .then((data) => setData(data))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));

  const onMonthChange = async ({ vaccinationSite, date }) => {
    return getAvailableSlots({
      vaccinationSite,
      doseNumber,
      dateInMonth: date,
    });
  };

  return (
    <ScheduledDateSelectFormInput
      // Control Props
      id={id}
      ParentComponent={Grid}
      ParentComponentProps={{
        item: true,
        component: Box,
        pt: 3,
      }}
      rules={{
        required: t("thisFieldIsRequired"),
      }}
      defaultValue={null}
      dependentFields="vaccinationSite"
      onDependentChangedRenderer={(vaccinationSite) => !!vaccinationSite}
      onDependentChangedProps={(vaccinationSite) => {
        if (vaccinationSite) {
          setLoading(true);
          getAvailableSlots({
            vaccinationSite,
            doseNumber,
            dateInMonth: moment(),
          }).finally(() => setLoading(false));
        } else {
          setData(null);
        }
        return {
          vaccinationSite,
        };
      }}
      // Loading Props
      loading={loading}
      loaded={!!data}
      mask
      // Component Props
      onMonthChange={onMonthChange}
      availableSlotsInMonthData={data}
    />
  );
};

export default ScheduledDateSelectContainer;
