import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid } from "@material-ui/core";
import fileSaver from "file-saver";
import { Image, PictureAsPdf, Print } from "@material-ui/icons";

const NewResult = ({ pdfURL }) => {
  const { t } = useTranslation();
  return (
    <div>
      <iframe
        hidden
        id="certificate"
        title="Certificate"
        height={1132}
        width={"100%"}
        src={pdfURL + "#toolbar=0&navpanes=0&scrollbar=0"}
        frameBorder="0"
        scrolling="auto"
        type="application/pdf"
      />
      <Box textAlign="center" my={3}>
        <Box border={1} display="inline-block" borderColor="grey.300">
          <canvas
            style={{ maxWidth: "100%" }}
            id="pdf-canvas"
            width="595.276"
          />
        </Box>
      </Box>
      <Grid container spacing={1} alignItems="flex-end" justify="flex-end">
        <Box p={1}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              fileSaver.saveAs(pdfURL, "certificate.pdf");
            }}
            startIcon={<PictureAsPdf />}
          >
            {t("downloadAsPdf")}
          </Button>
        </Box>
        <Box p={1}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              const link = document.createElement("a");
              link.download = "certificate.png";
              link.href = document.getElementById("pdf-canvas").toDataURL();
              link.click();
            }}
            startIcon={<Image />}
          >
            {t("downloadAsPng")}
          </Button>
        </Box>
        <Box p={1}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              document.getElementById("certificate").contentWindow.print();
            }}
            startIcon={<Print />}
          >
            {t("print")}
          </Button>
        </Box>
      </Grid>
    </div>
  );
};

export default NewResult;
