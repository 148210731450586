const locale = {
  en: {
    translation: {
      register: "SUBMIT",
      title: "COVID-19 Vaccination Scheduling System",
      nameAndOccupation: "Name & Occupation",
      firstName: "First name",
      lastName: "Last name",
      occupation: "Occupation",
      identification: "Identification",
      nationalId: "National ID",
      passportNumber: "Passport number",
      sexAndAge: "Sex & Age",
      sex: "Sex",
      dateOfBirth: "Date of birth",
      dateOfBirthIsEstimated: "Date of birth is estimated?",
      ageInYear: "Age in years",
      address: "Address",
      contact: "Contact",
      mobilePhone: "Mobile phone",
      whatsApp: "Whatsapp",
      line: "Line",
      telegram: "Telegram",
      conditions: "Conditions",
      underlyingConditions: "Any underlying conditions?",
      chronicKidneyDisease: "Chronic kidney disease",
      diabetes: "Diabetes",
      chronicHeartDisease: "Chronic heart disease",
      chronicRespiratoryDisease: "Chronic respiratory disease (excluding mild asthma)",
      chronicLiverDisease: "Chronic liver disease",
      obesity: "Obesity (BMI 30kg/m2 or higher)",
      cancer: "Cancer",
      immunocompromised: "Immunocompromised (including solid organ transplant)",
      hypertension: "Hypertension",
      downSyndrome: "Down syndrome",
      otherConditions: "Others (please specify)",
      vaccinationScheduling: "Vaccination Scheduling",
      selectDate: "Select Date",
      selectTimeSlot: "Select an available timeslot",
      enterCode: "Please enter this number",
      preRegister: "PRE-REGISTER FOR COVID-19 VACCINATION",
      preRegisterSubText: "Get the facts. Get the shot. Defeat COVID-19. #VaccinateLaos",
      preRegisterInstruction: "Before you pre-register, please read the instructions below carefully.",
      preRegisterInstruction1: "Only the people who belong to the target groups can pre-register.",
      preRegisterInstruction2: "If you do not belong to the target groups, please do not proceed. You will have the opportunity.",
      preRegisterInstruction3: "Please ensure that the information provided is accurate for your own safety.",
      preRegisterInstruction4: "You only need to pre-register one time. Please do not submit the form more than once.",
      menuRegister: "Register",
      menuVaccinationGuide: "Vaccination guide",
      menuCheckStatus: "Check status",
      check: "CHECK",
      lao: "Lao",
      english: "English",
      success: "Success",
      thankYou: "Thank you for registering!!",
      registerCode: "Register code",
      wrongNumber: "Wrong number",
      menu: "Menu",
      today: "Today",
      selectVaccinationSite: "Scheduled vaccination site",
      captchaText: "Enter captcha",
      thisFieldIsRequired: "This field is required",
      searchType: "Search type",
      searchValue: "Search value",
      yes: "Yes",
      no: "No",
      wrongValue: "Wrong value",
      preRegistrationCompleted: "Pre-Registration Completed!",
      yourPreRegistrationCode: "Your pre-registration code",
      pleaseTakeAPicture: "Please save this picture of the QR Code for checking status later",
      ok: "OK",
      cancel: "Cancel",
      clear: "Clear",
      haveYouGot1stDose: "Have you already got 1st dose ?",
      dateOf1stDose: "Date of first dose",
      selectVaccineType: "Select the vaccine type of your 1st dose",
      areYouReadyFor2ndDose: "Are you ready for 2nd dose ?",
      youAreReadyFor2ndDose: "You're ready for 2nd dose",
      youAreNotReadyFor2ndDose: "You're NOT ready for 2nd dose",
      under5yo: "Sorry, under 5 years old are currently not eligible",
      under120years: "Sorry, age must be <= 120 years",
      download: "Download",
      preRegisterInstruction5:
        "<b>You will not be able to select the type of vaccine before hand. The type of vaccine will be determined based on the target group that you belong and the availability of the vaccines</b>",
      confirmText:
        "By clicking this checkbox I confirm that I am aware that this vaccine has been given to people in many countries. It is an emergency vaccination to be used for preventing and controlling the COVID-19 pandemic. I have read and acknowledged the detail of this vaccine. I also had the opportunity to review all the information, concerns and received complete answers about its benefits and its side effect that might occur after receiving this COVID-19 vaccine. \n Therefore, I agree and voluntarily accept to be vaccinated.",
      unknownServerError: "Something went wrong, please contact the administrator",
      phoneNumberHasMaximum11Digits: "Phone number has maximum 11 digits",
      phoneNumberHasToStartWith020: "Phone number has to start with 020 or 021 or 030",
      phoneNumberHasToStartWithLaosAndVietnamese: "Phone number must be valid Laos or Vietnamese phone number",
      wrongEmailFormat: "Wrong email format",
      wrongCVIDFormat: "Wrong CVID format",
      noSlot: "The date you have selected has no vaccine slots available, please choose another date",
      preRegisterInstruction6: "COVID-19 vaccine 1st dose booking is open now with limited slots.",
      preRegisterInstruction7:
        "1st dose vaccine will be reserved mainly for elderly people(>=60) and people with underlying conditions but general people can also pre-register.",
      preRegisterInstruction8: "For 2nd dose, you don’t need to pre-register now. Please bring your green card with you to the vaccination site.",
      preRegisterInstruction10: "Please ensure that the information provided is accurate for your own safety.",

      errorTitle: "ERROR",
      errorContent: "errorContent",
      reloadCaptcha: "Reload captcha",
      firstDoseYes: "Yes",
      firstDoseNo: "No",
      sinopharm: "Sinopharm",
      covovax: "COVOVAX",
      sputnikV: "Gamaleya Sputnik V",
      pfizer: "Pfizer-BioNTech",
      sinovac: "Sinovac",
      moderna: "Moderna",
      az: "Astrazeneca - Covishield",
      noSlotMessage: "The slots for this date have been booked up. Please look for another date.",
      vaccinationSite: "Vaccination site",
      status: "Status",
      pending: "PENDING",
      approved: "APPROVED",
      rejected: "REJECTED",
      didYouRegister1stPreCVRSystem: "Did you register for 1st dose using pre-registration system ( vaccinatelaos.la ) ?",
      scheduleYour2ndDose: "Schedule your 2nd dose",
      submit: "Submit",
      verify: "Verify",
      notFound: "Not Found",
      eligible: "You are eligible to get 2nd dose",
      eligibleBooster: "You are eligible to get booster dose",
      eligibleButForBooster: "You are eligible to get booster dose since your first shot was single dose vaccine",
      notEligible: "You are not eligible to get 2nd dose",
      notEligibleBooster: "You are not eligible to get booster dose",
      secondDoseRegistrationAlreadyExist: "2nd Dose Registration Already Exist",
      boosterDoseRegistrationAlreadyExist: "Booster Dose Registration Already Exist",
      secondDoseRegistrationCompleted: "2nd Dose Registration Completed!",
      verifyCertificate: "Verify certificate",
      internationalCertificate: "Request travel certificate",
      scanYourCovidCertificateQRCode: "Scan your Covid certificate QR Code",
      name: "Name",
      doseNumber: "Dose number",
      failed: "Failed",
      qrScanner: "QR Scanner",
      noDeviceFound: "No device found!",
      invalidQRCode: "Invalid QRCode",
      unknownError: "Unknown error",
      cannotUseCanOnThisBrowser: "Can not use scanner on this browser",
      uniqueCertificateIdentifier: "Unique Certificate Identifier",
      vaccineAgainst: "Disease or agent targeted",
      vaccineProphylaxis: "Vaccine/prophylaxis",
      vaccineMedicinalProduct: "Vaccine medicinal product",
      vaccineName: "Vaccine name",
      vaccineDoseNumber: "Number in a series of vaccinations",
      dateOfVaccination: "Date of vaccination",
      vaccinatedAt: "Country of Vaccination",
      certificateIssuer: "Certificate issuer",
      qrCodeIsNotRecognized: "QR code is not recognized",
      vaccinationCertificate: "Vaccination Certificate",
      generate: "Generate",
      generateCertificate: "Generate Certificate",
      generateVaccinationCertificate: "Generate Vaccination Certificate",
      generateInternationalCertificate: "Request Travel Certificate",
      downloadAsPdf: "Download as PDF",
      downloadAsPng: "Download as PNG",
      print: "Print",
      youHaveNotCompleteYourVaccination: "Sorry, you haven't complete your vaccination",
      nameAndOccupationWarning:
        "Please use your legal name as written in official identification documents. Don't use a nick name or other names. As it will be reflected on your vaccine certificate and passport in the future",
      youShouldEnterEitherPhoneOrDOB: "You should enter either phone number or date of birth",
      doseSelectLabel: "You are registering for dose number ?",
      firstDose: "First dose",
      secondDose: "Second dose",
      boosterDose: "Booster dose",
      scheduleYourBoosterDose: "Schedule your booster dose",
      boosterDoseRegistrationCompleted: "Booster Dose Registration Completed!",
      verify2ndDose: "Verify for second dose",
      verifyBoosterDose: "Verify for booster dose",
      pleaseEnterEitherPhoneOrDOB: "Please enter either phone number or date of birth",
      goToBoosterDose: "Go to booster dose registration",
      youVerifyBoosterDose: "Verifying for booster dose",
      proceed: "Proceed",
      registerAgain: "Sorry, we couldn't find your record, please try again, or continue to register by inputting your information.",
      verifyForBoosterDose: "Verify for booster dose",
      laoDP: "Lao DP",
      qrCode: "QR Code",
      qrCodeBrife:
        "Unique cryptographic verifiable QR code that uses international standard PKI to provide a proof of a your covid-19 vaccination status that is accepted locally and internationally.",
      covid19InfoCard: "Covid-19 vaccination Information Card",
      covid19InfoCardBrife:
        "Proof of your covid vaccine status will go with you everywhere along your smartphone. You can show your proof of vaccination anytime that is required. Apart from that, your covid vaccine data is securely stored in the cloud so you will never lose it. But if you do, you can retrieve them anytime by yourself.",
      covid19VaccineSystem: "COVID-19 Vaccination Scheduling System",
      covid19VaccineSystemBrife:
        "Don’t know where to get the vaccines or don’t want to waste unnecessary time waiting to get your vaccine and expose yourself at risk of infection at the vaccination site? This app provides you the list of available vaccination sites with date, time and remaining slots so you can be assured that you will get your vaccine on time and at the place of your convenience.",
      verification: "Verification",
      verificationBrife:
        "Any smartphone with a camera can be used to verify the status of vaccination of every individual by scanning his/her cryptographic verifiable QR code. The verification doesn’t require an internet connection and no special tools are needed.",
      downloadAvailable: "Available for iOS and Android",
      secondOrBoosterDose: "Second dose or booster dose",
      verifyForSecondOrBoosterDose: "Verify for second dose or booster dose",
      vaccinatedSecondDose: "You already got second dose",
      isOutsideCountry: "Did you get 1st dose or 2nd dose in another country?",
      selectProvince: "Province",
      passportAction: "Drag 'n' drop your passport photo here, or click to select file",
      greencardAction: "Drag 'n' drop your green card photo here, or click to select file",
      ICRresult: "Your request is complete",
      internationalRequestComplete: "Your international certificate request is completed.",
      profileSeachResultTitle: "Search result",
      profileNotFound: `We can't find your record in the system. Please bring a copy of your passport and a copy of vaccination card to the travel covid certificate office. <a target="_blank" href="https://docs.google.com/presentation/d/1192utGCLph-wvqQtThb5xAVak7KI4oH9/edit?usp=sharing&ouid=110464952772390201457&rtpof=true&sd=true">Click here to see all available locations</a>`,
      search: "Search",
      request: "Request",
      dateToComeToPickup: "Date to come to pickup",
      placeToComeToPickup: "Place to come to pickup",
      fullName: "Full name",
      passportNumber: "Passport number",
      helpdeskEmail: "Helpdesk email",
      travelCertAnnouncement: `<a target="_blank" href="https://docs.google.com/presentation/d/1192utGCLph-wvqQtThb5xAVak7KI4oH9/edit?usp=sharing&ouid=110464952772390201457&rtpof=true&sd=true">Click here to see the guidelines</a>`,
      dateOfPassportIssuance: "Date of passport issuance",
      interCertReceiptNote:
        "Please screenshot this receipt and give the printout of this receipt to the officer when coming to collect the certificate",
      interCertReceiptNoteEmail:
        "Currently, we are receiving a huge number of requests. If you have not received anything after 5 working days. Please apply again. We are deeply sorry for the dealy and inconveniences",
      choosePhoto: "Choose photo",
      travelCertRequirement: "The certificate will be issued only if you have at least one booster. Otherwise, it will be rejected.",
      travelCertRequirement2: "For children under 12, required at least 1 dose of any Covid-19 Vaccine shot"
    }
  },
  lo: {
    translation: {
      register: "ສົ່ງຂໍ້ມູນ",
      title: "ລະບົບການຈັດຕາຕະລາງການໃຫ້ວັກຊີນກັນພະຍາດໂຄວິດ-19",
      nameAndOccupation: "ຊື່ & ອາຊີບ",
      firstName: "ຊື່ (ພາສາລາວ)",
      lastName: "ນາມສະກຸນ (ພາສາລາວ)",
      occupation: "ອາຊີບ",
      identification: "ການຍັ້ງຢືນຕົວຕົນ",
      nationalId: "ເລກບັດປະຈຳຕົວ",
      passportNumber: "ເລກທີໜັງສືຜ່ານແດນ",
      sexAndAge: "ເພດ & ອາຍຸ",
      sex: "ເພດ",
      dateOfBirth: "ວັນເດືອນປີເກີດ (ວ/ດ/ປ)",
      dateOfBirthIsEstimated: "ວັນເດືອນປີເກີດທີ່ຄາດຄະເນ?",
      ageInYear: "ອາຍຸ",
      address: "ທີ່ຢູ່",
      contact: "ຂໍ້ມູນຕິດຕໍ່",
      mobilePhone: "ເບີໂທລະສັບ",
      whatsApp: "Whatsapp",
      line: "Line",
      telegram: "Telegram",
      conditions: "ເງື່ອນໄຂ",
      underlyingConditions: "ມີພະຍາດປະຈຳຕົວບໍ?",
      chronicKidneyDisease: "ພະຍາດໄຂ່ຫຼັງຊຳເຮື້ອ",
      diabetes: "ເບົາຫວານ",
      chronicHeartDisease: "ພະຍາດຫົວໃຈຊຳເຮື້ອ",
      chronicRespiratoryDisease: "ພະຍາດທາງເດີນຫາຍໃຈຊຳເຮື້ອ (ບໍ່ລວມພະຍາດຫອບຫືດແບບເບົາບາງ)",
      chronicLiverDisease: "ພະຍາດຕັບຊຳເຮື້ອ",
      obesity: "ພະຍາດຕຸ້ຍ (BMI 30kg/m2 ຫຼື ສູງກວ່າ)",
      cancer: "ມະເຮັງ",
      immunocompromised: "ພູມຄຸ້ມກັນບົກພ່ອງ (ລວມທັງຜູ້ທີ່ປ່ຽນຖ່າຍອະໄວຍະວະເນື້ອແຂງ)",
      hypertension: "ຄວາມດັນ",
      downSyndrome: "ພະຍາດພັນທຸກໍາກຸ່ມອາການດາວຊິງໂດຣມ",
      otherConditions: "ອື່ນໆ (ໃຫ້ລະບຸ)",
      vaccinationScheduling: "ການນັດສັກວັກຊີນ",
      selectDate: "ເລືອກຂໍ້ມູນທີ່ມີ",
      selectTimeSlot: "ເລືອກຊ່ວງເວລາໃດໜື່ງທີ່ມີ",
      enterCode: "ກະລຸນາໃສ່ເບີໂທ",
      preRegister: "ການລົງທະບຽນລ່ວງໜ້າສຳລັບການຮັບວັກຊີນກັນພະຍາດໂຄວິດ-19",
      preRegisterSubText: "ເຮົາເຊື່ອໝັ້ນສູ້ໄປພ້ອມກັນຕ້ານໂຄວິດ-19. #VaccinateLaos",
      preRegisterInstruction: "ກ່ອນທີ່ຈະລົງທະບຽນລ່ວງໜ້າ, ກະລຸນາອ່ານຄຳແນະນຳດັ່ງລຸ່ມນີ້ຢ່າງລະອຽດ.",
      preRegisterInstruction1: "ສະເພາະບຸກຄົນທີ່ນອນຢູ່ໃນກຸ່ມເປົ້າໝາຍເທົ່ານັ້ນ ຈື່ງສາມາດລົງທະບຽນລ່ວງໜ້າໄດ້.",
      preRegisterInstruction2: "ຖ້າທ່ານບໍ່ນອນຢູ່ໃນກຸ່ມເປົ້າໝາຍ, ກະລຸນາຢ່າດຳເນີນຕໍ່ໄປ. ທ່ານຍັງຈະມີໂອກາດ.",
      preRegisterInstruction3: "ໃຫ້ໝັ້ນໃຈວ່າຂໍ້ມູນຂ່າວສານທີ່ສະໜອງໃຫ້ ຖືກຕ້ອງກັບຄວາມປອດໄພຂອງທ່ານ.",
      preRegisterInstruction4: "ທ່ານພຽງແຕ່ລົງທະບຽນລ່ວງໜ້າພຽງຄັ້ງດຽວເທົ່ານັ້ນ. ກະລຸນາບໍ່ຕ້ອງສົ່ງຟອມຫຼາຍຄັ້ງ.",
      menuRegister: "ລົງທະບຽນ",
      menuVaccinationGuide: "ຄູ່ມືການສັກວັກຊີນ",
      menuCheckStatus: "ກວດສະຖານະການລົງທະບຽນສັກວັກຊີນ",
      check: "ກວດສອບ",
      lao: "Lao",
      english: "English",
      success: "ສຳເລັດ",
      thankYou: "ຂອບໃຈສຳຫລັບການລົງທະບຽນ",
      registerCode: "ລະຫັດການລົງທະບຽນ",
      wrongNumber: "ເບິໂທລະສັບບໍ່ຖືກຕ້ໍອງ",
      menu: "Menu",
      today: "ມື້ນີ້",
      selectVaccinationSite: "ສະຖານທີ່ສັກວັກຊີນ",
      captchaText: "ປ້ອນອັກສອນຂ້າງເທີງ",
      thisFieldIsRequired: "ຂໍ້ມູນນີ້ແມ່ນຕ້ອງປ້ອນ",
      searchType: "ປະເພດການຄົ້ນຫາ",
      searchValue: "ຂໍ້ມູນທີ່ຈະໃຊ້ຄົ້ນຫາ",
      yes: "ເຈົ້າ",
      no: "ບໍ່",
      wrongValue: "ຂໍ້ມູນບໍ່ຖືກຕ້ໍອງ",
      preRegistrationCompleted: "ການລົງທະບຽນລ່ວງໜ້າສຳເລັດແລ້ວ!",
      yourPreRegistrationCode: "ເລກລະຫັດການລົງທະບຽນຂອງທ່ານ",
      pleaseTakeAPicture: "<b>ສຳຄັນ:</b> ກະລຸນາບັນທຶກຮຸບນີ້ໄວ້ໂດຍການກົດປຸ່ມບັນທືກທາງດ້ານລຸ່ມເພື່ອໃຊ້ຢືນຍັນເວລາມາຮັບວັກຊີນ",
      cancel: "ຍົກເລີກ",
      clear: "ລ້າງຂໍ້ມູນ",
      ok: "OK",
      haveYouGot1stDose: "ທ່ານໄດ້ຮັບວັກຊີນເຂັມທີ 1 ແລ້ວບໍ?",
      dateOf1stDose: "ວັນທີທີ່ທ່ານໄດ້ຮັບວັກຊີນເຂັມ 1",
      selectVaccineType: "ເລືອກປະເພດວັກຊີນເຂັມທີ 1 ທີ່ທ່ານໄດ້ຮັບ",
      areYouReadyFor2ndDose: "ຂ້ອຍສາມາດຮັບວັກຊີນເຂັມ 2 ແລ້ວຫຼືຍັງ?",
      youAreReadyFor2ndDose: "ທ່ານສາມາດຮັບວັກຊິນເຂັມ 2 ໄດ້ແລ້ວ",
      youAreNotReadyFor2ndDose: "ທ່ານຍັງບໍ່ສາມາດຮັບວັກຊິນເຂັມ 2 ໄດ້ເທື່ອ",
      under5yo: "ຂໍອະໄພ, ຕອນນີ້ຄົນອາຍຸຕ່ຳກວ່າ 5ປີ ຍັງບໍ່ສາມາດຮັບວັກຊີນໄດ້",
      under120years: "[LAOS]Sorry, age must be <= 120 years",
      download: "ບັນທຶກໄວ້ໃນເຄື່ອງ",
      preRegisterInstruction5:
        "<b>ທ່ານຈະບໍ່ສາມາດເລືອກຊະນິດວັກຊີນໄດ້ ຊະນິດຂອງວັກຊີນຈະຖືກກຳໜົດໃຫ້ຂຶ້ນກັບວ່າທ່ານເປັນກຸ່ມເປົ້າໝາຍປະເພດໃດແລະປະເພດວັກຊີນທີ່ພ້ອມໃຫ້ບໍລິການ</b>",
      confirmText:
        "ໂດຍການກົດປຸ່ມຍອມຮັບນີ້, ຂ້າພະເຈົ້າຮັບຮູ້ວ່າວັກຊີນຊະນິດນີ້ແມ່ນໄດ້ສັກໃຫ້ປະຊາຊົນຫຼາຍປະເທດແລ້ວ, ເປັນວັກຊີນໃຊ້ສຸກເສີນເພື່ອປ້ອງກັນແລະຄວບຄຸມການລະບາດຂອງພະຍາດ COVID-19. ຂ້າພະເຈົ້າໄດ້ຮັບຊາບລາຍລະອຽດຂອງວັກຊີນດັ່ງກ່າວ ແລະ ກໍໄດ້ມີໂອກາດໄດ້ພິຈາລະນາຂໍ້ມູນທັງໝົດ ລວມທັງໄດ້ສອບຖາມຂໍ້ຂ້ອງໃຈທີ່ມີແລະໄດ້ຮັບຄຳຕອບຢ່າງຄົບຖ້ວນກ່ຽວກັບຜົນດີທີ່ຈະໄດ້ຮັບ ແລະ ອາການຂ້າງຄຽງເບົາບາງທີ່ອາດເກີດຂຶ້ນ ພາຍຫຼັງໄດ້ຮັບວັກຊີນ COVID-19. ດັ່ງນັ້ນ, ຂ້າພະເຈົ້າຍິນດີຮັບວັກຊີນດ້ວຍຄວາມສະໝັກໃຈເພື່ອການສ້າງພູມຕ້ານທານໃຫ້ກັບຮ່າງກາຍຕົນເອງ ທັງເປັນການຫຼຸດຜ່ອນການແຜ່ລະບາດຂອງພະຍາດ COVID-19 ພາຍໃນປະເທດ ແລະ ໃນໂລກ.",
      unknownServerError: "ມີບາງຍ່າງຜິດພາດ, ກະລຸນາຕິດຕໍ່ຜູ້ຄຸມລະບົບ",
      phoneNumberHasMaximum11Digits: "ເບິໂທຕິດຕໍ່ມີຄວາມຍາວສູງສຸດ 11 ຕົວເລກ",
      phoneNumberHasToStartWith020: "ເບິໂທຕິດຕໍ່ຕ້ອງເລີ່ມຕົ້ນດ້ວຍ 020, 021, 030",
      phoneNumberHasToStartWithLaosAndVietnamese: "LAO Phone number must be valid Laos or Vietnamese phone number",
      wrongEmailFormat: "ອີເມວບໍ່ຖືກຕ້ອງ",
      wrongCVIDFormat: "ລະຫັດ CVID ບໍ່ຖືກຕ້ອງ",
      noSlot: "ວັນທີທີ່ທ່ານໄດ້ເລືອກບໍ່ທັນມີກຳນົດການສັກວັກຊີນເທື່ອ, ກະລຸນາເລືອກວັນທີອື່ນ",
      preRegisterInstruction6: "ການລົງທະບຽນຮັບວັກຊີນ COVID-19 ເຂັມທີ 1 ລ່ວງໜ້າຕອນນີ້ແມ່ນເປີດໃຫ້ລົງທະບຽນແລ້ວໂດຍມີຈຳນວນຈຳກັດ.",
      preRegisterInstruction7:
        "ວັກຊີນ COVID-19 ເຂັມທີ 1 ຈະຂໍສະຫງວນສິດໃຫ້ຜູ້ທີ່ອາຍຸຫຼາຍກວ່າ 60 ປີ ຫຼື ຜູ້ທີ່ມີພະຍາດປະຈຳໂຕກ່ອນ ແຕ່ບຸກຄົນທົ່ວໄປກໍສາມາດລົງໄດ້.",
      preRegisterInstruction8: "ສຳລັບວັກຊີນເຂັມ 2 ແມ່ນບໍ່ຈຳເປັນຕ້ອງລົງທະບຽນ. ພຽງແຕ່ນຳບັດຂຽວມາຈຸດຮັບວັກຊີນ.",
      preRegisterInstruction10: "ໃຫ້ໝັ້ນໃຈວ່າຂໍ້ມູນທີ່ທ່ານຕື່ມແມ່ນຖືກຕ້ອງເພື່ອຄວາມປອດໄພຂອງທ່ານເອງ.",
      errorTitle: "ERROR",
      errorContent: "errorContent",
      reloadCaptcha: "ປ່ຽນລະຫັດການຍືນຢັນ",
      firstDoseYes: "ໄດ້ຮັບແລ້ວ",
      firstDoseNo: "ຍັງບໍ່ທັນໄດ້ຮັບ",
      sinopharm: "ຊິໂນຟາມ",
      covovax: "COVOVAX",
      sputnikV: "Gamaleya Sputnik V",
      pfizer: "ໄຟເຊີ້",
      sinovac: "Sinovac",
      moderna: "Moderna",
      az: "ແອັສຕ້າເຊເນກ້າ",
      noSlotMessage: "ວັກຊີນສຳລັບມື້ທີ່ທ່ານເລືອກຖືກຈອງໝົດແລ້ວ. ກະລຸນາເລືອກມື້ໃໝ່.",
      vaccinationSite: "ສະຖານທີ່ຮັບວັກຊີນ",
      status: "ສະຖານະ",
      pending: "ລໍຖ້າ",
      approved: "ໄດ້ຮັບການອະນຸມັດ",
      rejected: "ໄດ້ຮັບການປະຕີເສດ",
      didYouRegister1stPreCVRSystem: "ທ່ານຜ່ານມາເຈົ້າໄດ້ລົງທະບຽນເຂັມ 1 ຜ່ານໜ້າເວັບໂຕນີ້ຂອງເຮົາບໍ່?",
      scheduleYour2ndDose: "ນັດເວລາເພື່ອຮັບວັກຊີນເຂັມ 2",
      submit: "ສົ່ງ",
      verify: "ກວດສອບ",
      notFound: "ຂໍອະໄພ ພວກເຮົາບໍ່ພົບຂໍ້ມູນຂອງທ່ານ",
      eligible: "ທ່ານສາມາດຮັບວັກຊີນເຂັມ 2 ໄດ້ແລ້ວ",
      notEligible: "ທ່ານຍັງບໍ່ສາມາດຮັບວັກຊີນເຂັມ 2 ໄດ້ເທື່ອ",
      secondDoseRegistrationAlreadyExist: "(LAOS) 2nd Dose Registration Already Exist",
      secondDoseRegistrationCompleted: "(LAOS) 2nd Dose Registration Completed!",
      verifyCertificate: "ກວດກາໃບຢັ້ງຢືນ",
      internationalCertificate: "ຄຳຮ້ອງຂໍໃບຢັ້ງຢຶນການຮັບວັກຊີນເພື່ອເດີນທາງ",
      scanYourCovidCertificateQRCode: "ສະແກນໃບຢັ້ງຢືນກາານຮັບວັກຊີນທີ່ມີລະຫັດ QR",
      name: "ຊື່",
      doseNumber: "ເຂັມທີ",
      failed: "(LAOS - PENDING) Failed",
      qrScanner: "(LAOS) QR Code Scanner",
      noDeviceFound: "ບໍ່ພົບເຄື່ອງທີ່ສາມາດສະແກນໄດ້",
      invalidQRCode: "ລະຫັດ QR ບໍ່ຖືກຕ້ອງ",
      cannotUseCanOnThisBrowser: "(LAOS) Can not use scanner on this browser",
      uniqueCertificateIdentifier: "ລະຫັດໃບຢັ້ງຢືນສະເພາະ",
      vaccineAgainst: "ພະຍາດທີ່ວັກຊີນຈະປ້ອງກັນ",
      vaccineProphylaxis: "ວັກຊີນ",
      vaccineMedicinalProduct: "(LAOS) Vaccine medicinal product",
      vaccineName: "ຊື່ຂອງວັກຊີນ",
      vaccineDoseNumber: "ຈຳນວນເຂັມຕາມຄຳແນະນຳຂອງກະຊວງສາທາ",
      dateOfVaccination: "ວັນທີ່ຮັບວັກຊີນ",
      vaccinatedAt: "ໄດ້ຮັບວັກຊີນຢູ່ປະເທດ",
      certificateIssuer: "ຜູ້ອອກໃບຢັ້ງຢືນ",
      qrCodeIsNotRecognized: "ລະຫັດ QR ນີ້ບໍ່ຖືກຕ້ອງ",
      vaccinationCertificate: "ໃບຢັ້ງຢືນການຮັບວັກຊີນ",
      generate: "ສ້າງ",
      generateCertificate: "ສ້າງໃບຢັ້ງຢືນການຮັບວັກຊີນ",
      generateVaccinationCertificate: "ສ້າງໃບຢັ້ງຢືນການຮັບວັກຊີນ",
      generateInternationalCertificate: "ຄຳຮ້ອງຂໍໃບຢັ້ງຢຶນການຮັບວັກຊີນເພື່ອເດີນທາງ",
      downloadAsPdf: "ດາວໂຫຼດເປັນ PDF",
      downloadAsPng: "ດາວໂຫຼດເປັນຮູບ",
      print: "ພິມ",
      youHaveNotCompleteYourVaccination: "ຂໍອະໄພ, ທ່ານຍັງຮັບວັກຊີນບໍ່ທັນຄົບເທື່ອ",
      nameAndOccupationWarning:
        "ກະລຸນາໃຊ້ຊື່ດຽວກັບທີ່ໃຊ້ໃນເອກະສານທາງການ. ຫຼີກລ້ຽງການໃສ່ຊື່ຫຼິ້ນເນື່ອງຈາກຊື່ທີ່ທ່ານປ້ອນຈະຖືກເອົາໄປພິມໃສ່ໃບຢັ້ງຢືນການຮັບວັກຊີນແລະວັກຊີນພາສປອດໃນອະນາຄົດ",
      youShouldEnterEitherPhoneOrDOB: "ທ່ານຕ້ອງປ້ອນເບີໂທ ຫຼື ວັນເດືອນປີເກິດ",
      doseSelectLabel: "ທ່ານຕ້ອງການລົງທະບຽນຮັບວັກຊິນເຂັມໃດ?",
      firstDose: "ເຂັມ 1",
      secondDose: "ເຂັມ 2",
      boosterDose: "ເຂັມກະຕຸ້ນ",
      scheduleYourBoosterDose: "ເລືອກເວລານັດຮັບເຂັມກະຕຸ້ນ",
      boosterDoseRegistrationCompleted: "ການລົງທະບຽນຮັບເຂັມກະຕຸ້ນສຳເລັດແລ້ວ",
      verify2ndDose: "ກວດສອບການຮັບເຂັມ 2",
      verifyBoosterDose: "ກວດສອບການຮັບເຂັມກະຕຸ້ນ",
      pleaseEnterEitherPhoneOrDOB: "ກະລຸນາປ້ອນເບິໂທ ຫຼື ວັນເດືອນປີເກີດ ຢ່າງໃດຢ່າງໜຶ່ງ",
      goToBoosterDose: "ໄປໜ້າລົງທະບຽນເຂັມກະຕຸ້ນ",
      youVerifyBoosterDose: "ກວດສອບການຮັບເຂັມກະຕຸ້ນ",
      proceed: "ລົງທະບຽນໃໝ່",
      registerAgain: "ຂໍອະໄພ ພວກເຮົາກວດບໍ່ເຫັນຂໍ້ມູນຂອງທ່ານໃນຖານຂໍ້ມູນ, ກະລຸນາກວດເບິ່ງຂໍ້ມູນຄືນວ່າປ້ອນຖືກແລ້ວ ຫຼື ກົດປຸ່ມລົງທະບຽນໃໝ່ເລີຍ",
      verifyForBoosterDose: "LAOS Verify for booster dose",
      laoDP: "Lao DP",
      qrCode: "ລະຫັດ QR",
      qrCodeBrife:
        "ລະຫັດ QR ທີ່ເຂົ້າລະຫັດແລະສາມາດຢັ້ງຢືນໄດ້ທີ່ນຳໃຊ້ມາດຕະຖານສາກົນໃນການສ້າງ ເພື່ອໃຊ້ໄວ້ຢັ້ງຢືນສະຖານນະການຮັບວັກຊີນປ້ອງກັນພະຍາດໂຄວິດຂອງທ່ານທີ່ພາຍໃນແລະຕ່າງປະເທດຍອມຮັບ.",
      covid19InfoCard: "ບັດສະແດງຂໍ້ມູນການຮັບວັກຊີນປ້ອງກັນ covid-19",
      covid19InfoCardBrife:
        "ຫຼັກຖານສະຖານນະການຮັບວັກຊີນປ້ອງກັນພະຍາດໂຄວິດຂອງທ່ານຈະຕິດໂຕທ່ານໄປຕະຫຼອດເວລາຢູ່ໃນໂທລະສັບຂອງທ່ານ. ທ່ານສາມາດສະແດງຫຼັກຖານນີ້ໄດ້ທັນທີ່ໆຕ້ອງການໂດຍບໍ່ຈຳເປັນຕ້ອງມີອິນເຕີເນັດ. ນອກຈາກນີ້, ຂໍ້ມູນການຮັບວັກຊີນຂອງທ່ານຍັງຖືກເກັບຮັກສາຢ່າງດີແລະປອດໄພຢູ່ຖານຂໍ້ມູນຜ່ານອິນເຕີເນັດ ສະນັ້ນ ບໍ່ຕ້ອງກັງວົນວ່າທ່ານຈະເຮັດມັນເສຍ. ແຕ່ຖ້າເສຍ, ທ່ານສາມາດກູ້ຄືນເອງໄດ້ຕະຫຼອດເວລາ.",
      covid19VaccineSystem: "ລະບົບການຈອງຮັບວັກຊີນລ່ວງໜ້າ",
      covid19VaccineSystemBrife:
        "ບໍ່ຮູ້ຈະໄປຮັບວັກຊີນຢູ່ບ່ອນໃດ ຫຼື ບໍ່ຢາກເສຍເວລາລໍຖ້າເພື່ອຮັບວັກຊີນ ແລະ ເພີ່ມຄວາມສ່ຽງໃຫ້ຕົນເອງຢູ່ສະຖານທີ່ຮັບວັກຊີນຍ້ອນຖ້າຄິວຍາວ? ໂດຍການນຳໃຊ້ແອັບນີ້ ທ່ານສາມາດຮູ້ວ່າສະຖານທີ່ໃດທີ່ທ່ານສາມາດໄປຮັບວັກຊີນໄດ້ ພ້ອມທັງສາມາດເລືອກມື້ ແລະ ເວລາເອງໄດ້ອີກ. ສະນັ້ນ, ທ່ານຈົ່ງໝັ້ນໃຈວ່າຈະໄດ້ຮັບວັກຊີນຕາມວັນ ເວລາ ແລະ ສະຖານທີ່ໆທ່ານກຳໜົດເອງ.",
      verification: "ການຢັ້ງຢືນ",
      verificationBrife:
        "ໂທລະສັບສະມາດໂຟນທີ່ມີກ້ອງຖ່າຍຮູບ ສາມາດເອົາມາໃຊ້ເພື່ອຢັ້ງຢືນສະຖານະການຮັບວັກຊີນຂອງບຸກຄົນອື່ນໄດ້ ໂດຍການເປີດແອັບນີ້ແລ້ວແນໃສ່ລະຫັດ QR ຂອງບຸກຄົນນັ້ນໆ. ການຢັ້ງຢືນນີ້ສາມາດໃຊ້ໄດ້ເຖິງວ່າຈະບໍ່ມີອິນເຕີເນັດກໍຕາມ ແລະ ບໍ່ຕ້ອງການອຸປະກອນພິເສດໃດໆເພີ່ມເຕີມ.",
      downloadAvailable: "ສາມາດດາວໂຫລດໄດ້ສຳຫລັບ iOS ແລະ​ Android",
      secondOrBoosterDose: "ເຂັມ 2 ຫຼື ເຂັມກະຕຸ້ນ",
      verifyForSecondOrBoosterDose: "ກວດສອບຄວາມພ້ອມສຳລັບເຂັມ 2 ຫຼື ເຂັມກະຕຸ້ນ",
      vaccinatedSecondDose: "ທ່ານໄດ້ຮັບເຂັມ 2 ແລ້ວ",
      isOutsideCountry: "ທ່ານໄດ້ຮັບວັກຊີນເຂັມ 1 ຫຼື ເຂັມ 2 ຢູ່ປະເທດອື່ນບໍ່?",
      selectProvince: "ແຂວງທີ່ຕ້ອງການຮັບວັກຊີນ",
      passportAction: "ລາກຮູບພາສປອດມາໄວ້ບ່ອນນີ່້ ຫຼື ກົດບ່ອນນີ້ເພື່ອເລືອກຮູບ",
      greencardAction: "ລາກ ແລະ ວາງຮູບໜັງສືຜ່ານແດນຂອງທ່ານຢູ່ບ່ອນນີ້, ຫຼືກົດເພື່ອເລືອກຮູບ",
      ICRresult: "ໃບຄຳຮ້ອງຂອງທ່ານສຳເລັດແລ້ວ",
      internationalRequestComplete: "LAO Your international certificate request is completed.",
      profileSeachResultTitle: "ຜົນການຄົ້ນຫາ",
      profileNotFound: `ພວກເຮົາຄົ້ນຫາຂໍ້ມູນຂອງທ່ານບໍ່ເຫັນ. ກະລຸນາເອົາໃບກັອບປີ້ໜ້າໜັງສືຜ່ານແດນ (ພາສປອດ) ແລະ ກັອບປີ້ບັດຮັບວັກຊີນ (ບັດຂຽວ) ຂອງທ່ານມາຍື່ນຢູ່ຈູດຮັບບໍລິການ. <a target="_blank" href="https://docs.google.com/presentation/d/1192utGCLph-wvqQtThb5xAVak7KI4oH9/edit?usp=sharing&ouid=110464952772390201457&rtpof=true&sd=true">ກົດບ່ອນນີ້ເພື່ອເບິ່ງວ່າມີຈຸດບໍລິການຢູ່ໃສແນ່</a>`,
      search: "ຄົ້ນຫາ",
      request: "ສົ່ງຄຳຮ້ອງ",
      dateToComeToPickup: "ວັນທີຮັບໃບຢັ້ງຢຶນ",
      placeToComeToPickup: "ສະຖານທີ່ຮັບໃບຢັ້ງຢຶນ",
      fullName: "ຊື່ແລະນາມສະກຸນ",
      passportNumber: "ເລກທີໜັງສືເດີນທາງ",
      helpdeskEmail: "ອີເມລຈຸດໃຫ້ບໍລິການ",
      travelCertAnnouncement: `<a target="_blank" href="https://docs.google.com/presentation/d/1192utGCLph-wvqQtThb5xAVak7KI4oH9/edit?usp=sharing&ouid=110464952772390201457&rtpof=true&sd=true">ກົດບ່ອນນີ້ເພື່ອເບິ່ງຄູ່ມື</a>`,
      dateOfPassportIssuance: "ວັນທີເດືອນປີອອກໜັງສືຜ່ານແດນ",
      interCertReceiptNote: "ກະລຸນາບັນທຶກໜ້າຈໍບັດນັດນີ້ໄວ້ ແລ້ວພິມອອກມາຍື່ນໃຫ້ເຈົ້າໜ້າທີ່ໃນມື້ທີ່ມາຮັບເອົາໃບຢັ້ງຢືນ",
      interCertReceiptNoteEmail:
        "ຕອນນີ້ພວກເຮົາໄດ້ຮັບຄຳຮ້ອງມາເປັນຈຳນວນຫຼວງຫຼາຍ. ຖ້າກາຍ 5 ມື້ລັດຖະການແລ້ວທ່ານຍັງບໍ່ໄດ້ຮັບໃບຢັ້ງຢືນພວກເຮົາຂໍໃຫ້ທ່ານສົ່ງຄຳຮ້ອງໃໝ່ອີກຄັ້ງ. ພວກເຮົາຂໍອະໄພໃນຄວາມລ່າຊ້າແລະບໍ່ສະດວກ",
      choosePhoto: "ເລືອກຮູບ",
      travelCertRequirement:
        "ໃບຢັ້ງຢຶນເພື່ອການເດີນທາງນີ້ຈະອອກໃຫ້ແຕ່ຜູ້ທີ່ໄດ້ຮັບວັກຊີນໂຄວິດເຂັມກະຕຸ້ນຢ່າງໜ້ອຍ 1 ເຂັມແລ້ວເທົ່ານັ້ນ. ຖ້າທ່ານຍັງບໍ່ທັນໄດ້ຮັບເຂັມກະຕຸ້ນເທື່ອລະບົບຈະບໍ່ອອກໃບຢັ້ງຢືນໃຫ້.",
      travelCertRequirement2: "ສຳລັບເດັກຕ່ຳກວ່າ 12 ປີລົງມາ, ຕ້ອງໄດ້ຮັບວັກຊີນປ້ອງກັນໂຄວິດຊະນິດໃດກໍໄດ້ຢ່າງໜ້ອຍ 1 ເຂັມ"
    }
  }
};

export default locale;
