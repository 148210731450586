import React from "react";
import { green, grey } from "@material-ui/core/colors";
import { Box, Chip, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import LocalInformation from "./LocalInformation";
import InterInformation from "./InterInformation";
import { SINGLE_DOSE_VACCINE_TYPES } from "../../utils";
import moment from "moment";

const VP_MAPPING = [
  {
    vp: "1119305005",
    description: "SARS-CoV-2 antigen vaccine"
  },
  {
    vp: "1119349007",
    description: "SARS-CoV-2 mRNA vaccine"
  }
];

const MAPPING = [
  {
    maDes: "China Sinopharm International Corp. - Beijing location",
    mpDes: "Verocell",
    mp: "ORG-100020693",
    ma: "ORG-100020693"
  },
  {
    maDes: "Gamaleya Research Institute",
    mpDes: "Sputnik-V",
    mp: "Gamaleya-Research-Institute",
    ma: "Gamaleya-Research-Institute"
  },
  {
    maDes: "AstraZeneca AB",
    mpDes: "Vaxzevria",
    mp: "ORG-100001699",
    ma: "ORG-100001699"
  },
  {
    maDes: "Biontech Manufacturing GmbH",
    mpDes: "Comirnaty",
    mp: "ORG-100030215",
    ma: "ORG-100030215"
  },
  {
    maDes: "Sinovac Biotech",
    mpDes: "Coronavac",
    mp: "Sinovac-Biotech",
    ma: "Sinovac-Biotech"
  },
  {
    maDes: "Moderna Biotech Spain S.L.",
    mpDes: "Spikevax",
    mp: "ORG-100031184",
    ma: "ORG-100031184"
  },
  {
    maDes: "Bharat Biotech",
    mpDes: "Covishield",
    mp: "Bharat-Biotech",
    ma: "Bharat-Biotech"
  },
  {
    maDes: "Janssen-Cilag International",
    mpDes: "Janssen",
    mp: "Janssen-Cilag International",
    ma: "ORG-100001417"
  }
];

const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor: green[500]
  },
  noBordered: {
    display: "inline-block",
    border: "none",
    paddingRight: "0 !important",
    paddingLeft: "0 !important",
    "& strong": {
      display: "block",
      whiteSpace: "noWrap"
    }
  },
  equaledTableCell: {
    width: "50%"
  }
});

const StyledTableCell = (props) => {
  const { noBordered } = useStyles();
  return (
    <TableCell
      classes={{
        root: noBordered
      }}
      {...props}
    />
  );
};

const EqualledTableCell = (props) => {
  const { equaledTableCell } = useStyles();
  return (
    <TableCell
      classes={{
        root: equaledTableCell
      }}
      {...props}
    />
  );
};

const PatientInformation = ({
  data: {
    status,
    metadata: {
      certificateIssuer: { commonName, countryName }
    },
    data: {
      nam: { fn, gn, fnt, gnt },
      dob: dateOfBirth,
      s,
      pas,
      dpi,
      v: [{ ma, dn, ld, dt, ci }]
    }
  }
}) => {
  const { colorPrimary } = useStyles();

  let fullName = gnt + " " + fnt;
  let fullLaoName = gn + " " + fn;

  return (
    <Box textAlign="center">
      <Box borderRadius="borderRadius" py={5} mx="auto" border={1} borderColor={grey[300]} maxWidth={1000} width={1}>
        <Box textAlign="center" mb={3}>
          <Chip
            size="large"
            icon={<DoneIcon />}
            label={status}
            clickable={false}
            color="primary"
            classes={{
              colorPrimary
            }}
          />
        </Box>
        {pas && dpi && s ? (
          <InterInformation
            fullLaoName={fullLaoName}
            fullName={fullName}
            dateOfBirth={dateOfBirth}
            p={pas}
            dopi={dpi}
            s={s}
            ci={ci}
            ma={ma}
            dn={dn}
            dt={dt}
            commonName={commonName}
          />
        ) : (
          <LocalInformation fullName={fullLaoName} dateOfBirth={dateOfBirth} ci={ci} ma={ma} dn={dn} dt={dt} commonName={commonName} />
        )}
      </Box>
    </Box>
  );
};

export default PatientInformation;
