const bindApi =
  (apiServerUrl) =>
  (url, ...params) => {
    return fetch(apiServerUrl + url, ...params).then((response) => {
      if (!response.ok) {
        // make the promise be rejected if we didn't get a 2xx response
        throw response;
      } else {
        return response.json();
      }
    });
  };

export { bindApi };
