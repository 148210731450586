import * as React from "react";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Grid,
  Box,
  Divider,
  Button,
} from "@material-ui/core";
import appLogo from "../../../assets/images/ic_launcher.png";
import elephantLogo from "../../../assets/images/elephant.png";
import mohLogo from "../../../assets/images/logo-lg.png";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Laos from "../../../assets/images/languages/laos.png";
import Eng from "../../../assets/images/languages/eng.png";
import { useTranslation } from "react-i18next";

const SMALL_SIZE = 24;
const MEDIUM_SIZE = 32;
const LARGE_SIZE = 48;

const useStyles = makeStyles((theme) => ({
  logoImg: {
    // borderRadius: "50%",
    // backgroundColor: "white",
    // padding: 4,
    maxWidth: SMALL_SIZE,
    maxHeight: SMALL_SIZE,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    [theme.breakpoints.up("sm")]: {
      maxWidth: MEDIUM_SIZE,
      maxHeight: MEDIUM_SIZE,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: LARGE_SIZE,
      maxHeight: LARGE_SIZE,
    },
    "& > img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  logoItem: {},
  dividerLight: {
    borderColor: ["#cccccc", "!important"],
  },
  appBar: {
    boxShadow: "none",
  },
  toolBar: {
    minHeight: 64,
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      minHeight: 90,
    },
  },
  brandName: {
    fontSize: 16,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
      marginLeft: theme.spacing(2),
    },
  },
  dividerMiddle: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
}));

const ResponsiveAppBar = () => {
  const { logoImg, dividerLight, dividerMiddle, appBar, toolBar, brandName } =
    useStyles();
  const { i18n } = useTranslation();
  return (
    <Box
      component={AppBar}
      boxShadow="none"
      position="static"
      bgcolor="transparent"
    >
      <Container maxWidth="lg">
        <Toolbar
          classes={{
            root: toolBar,
          }}
          disableGutters
        >
          <Grid
            wrap="nowrap"
            justify="flex-start"
            container
            alignItems="center"
          >
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <Grid
                component={Box}
                container
                item
                alignItems="center"
                width="unset"
              >
                <Box className={logoImg}>
                  <img src={appLogo} />
                </Box>
                <Typography
                  classes={{
                    root: brandName,
                  }}
                  color="white"
                  variant="h6"
                  noWrap
                >
                  <strong>Lao Covid DP</strong>
                </Typography>
              </Grid>
            </Link>
            <Divider
              light
              classes={{
                light: dividerLight,
                middle: dividerMiddle,
              }}
              variant="middle"
              orientation="vertical"
              flexItem
            />
            <Grid item>
              <Box className={logoImg}>
                <img src={mohLogo} />
              </Box>
            </Grid>
            <Divider
              light
              classes={{
                light: dividerLight,
                middle: dividerMiddle,
              }}
              variant="middle"
              orientation="vertical"
              flexItem
            />

            <Grid item>
              <Box className={logoImg}>
                <img src={elephantLogo} />
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            {i18n.language === "en" ? (
              <Button
                onClick={() => i18n.changeLanguage("lo")}
                style={{ minWidth: "unset" }}
                size="small"
                endIcon={<img width={20} src={Laos} />}
              >
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: 12, color:  "#545454" }}
                >
                  LO
                </Typography>
              </Button>
            ) : (
              <Button
                onClick={() => i18n.changeLanguage("en")}
                style={{ minWidth: "unset" }}
                size="small"
                endIcon={<img width={20} src={Eng} />}
              >
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: 12, color: "#545454" }}
                >
                  EN
                </Typography>
              </Button>
            )}
          </Grid>
        </Toolbar>
      </Container>
    </Box>
  );
};
export default ResponsiveAppBar;
