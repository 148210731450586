import React, { useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import withFormController from "../../hocs/withFormController";
import TypedInput from "../../common/TypedInput";
import { useTranslation } from "react-i18next";
import { DHIS2_TYPES } from "../../common/TypedInput/utils";
import { compose } from "redux";
import withDhis2Metadata from "../../hocs/withDhis2Metadata";
import CVIDInput from "./CVIDInput";
import { useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import LoadingButton from "../../common/LoadingButton";
import { DOSE_TYPES } from "../../common/TypedInput/DoseSelectRadioGroup";
import { PHONE_PREFIX } from "../../constants/global";

const VerifyInternalDoseDialog = ({
  open,
  onCancel,
  onSubmit,
  errorMessage,
  isLoading,
  // showBoosterDoseButton,
  // onBoosterDoseClick,
  switchLoading,
  // resultMessage,
  showProceedButton,
  onProceedButtonClick,
  form,
}) => {
  const attributes = useSelector((state) => state.metadata.attributes);
  const { control, handleSubmit, getValues, trigger } = form;
  const AttributeDhis2FormInput = useMemo(
    () =>
      compose(
        withDhis2Metadata(
          attributes,
          (metadata) => (id) => {
            return metadata.programTrackedEntityAttributes.find(
              ({ trackedEntityAttribute: { id: attrId } }) => {
                return attrId === id;
              }
            );
          },
          (item) => item.trackedEntityAttribute,
          {
            pickedProps: [
              "id",
              ["label", ["formName", "name"]],
              "optionSet",
              "valueType",
            ],
          }
        ),
        withFormController({ control })
      )(TypedInput),
    [attributes]
  );
  const { t } = useTranslation();

  const doseNumber = useSelector((state) => state.cvr.doseNumber);

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>{t("verifyForSecondOrBoosterDose")}</DialogTitle>
        <DialogContent>
          <Box pb={2}>
            <Alert severity="warning">{t("pleaseEnterEitherPhoneOrDOB")}</Alert>
          </Box>
          <Grid spacing={0} container direction="column">
            <Grid item>
              <AttributeDhis2FormInput
                id="corXnplgfQ7"
                Component={CVIDInput}
                valueType={DHIS2_TYPES.OTHER}
                rules={{
                  required: t("thisFieldIsRequired"),
                  pattern: {
                    value: /^H\d{4}-[A-Z]-(\d|[A-Z])\d{4}$/,
                    message: t("wrongCVIDFormat"),
                  },
                }}
              />
            </Grid>
            <Grid item>
              <Box pt={3}>
                <AttributeDhis2FormInput
                  id="ciCR6BBvIT4"
                  dependentFields="tQeFLjYbqzv"
                  onDependentChangedControllerProps={(dob) => {
                    trigger("ciCR6BBvIT4");
                    return {
                      rules: {
                        validate: {
                          eitherPhoneOrDOB: (phone) => {
                            if (!phone && !getValues("tQeFLjYbqzv")) {
                              return t("youShouldEnterEitherPhoneOrDOB");
                            }
                          },
                        },
                        maxLength: {
                          value: 11,
                          message: t("phoneNumberHasMaximum11Digits"),
                        },
                        pattern: {
                          value: PHONE_PREFIX,
                          message: t(
                            "phoneNumberHasToStartWithLaosAndVietnamese"
                          ),
                        },
                      },
                    };
                  }}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box pt={3}>
                <AttributeDhis2FormInput
                  ParentComponent={Grid}
                  ParentComponentProps={{ item: true }}
                  variant="dialog"
                  inputVariant="outlined"
                  id="tQeFLjYbqzv"
                  // valueType={DHIS2_TYPES.DATE}
                  defaultValue={null}
                  disableFuture
                  // autoOk
                  dependentFields="ciCR6BBvIT4"
                  onDependentChangedControllerProps={(phone) => {
                    trigger("tQeFLjYbqzv");
                    return {
                      rules: {
                        validate: {
                          eitherPhoneOrDOB: (dob) => {
                            if (!dob && !getValues("ciCR6BBvIT4")) {
                              return t("youShouldEnterEitherPhoneOrDOB");
                            }
                          },
                        },
                      },
                    };
                  }}
                  format="YYYY-MM-DD"
                  views={["year", "month", "date"]}
                  openTo="year"
                />
              </Box>
            </Grid>
          </Grid>
          {errorMessage && (
            <Alert severity="error" component={Box} mt={3}>
              {errorMessage}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Box
            px={2}
            pb={1}
            width="100%"
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
          >
            {/*{resultMessage && (*/}
            {/*  <Box pb={2}>*/}
            {/*    <Alert severity="info">{resultMessage}</Alert>*/}
            {/*  </Box>*/}
            {/*)}*/}
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                {/*{showBoosterDoseButton && (*/}
                {/*  <LoadingButton*/}
                {/*    disabled={switchLoading}*/}
                {/*    CircularProgressProps={{*/}
                {/*      size: 16,*/}
                {/*    }}*/}
                {/*    spacing={1}*/}
                {/*    loading={switchLoading}*/}
                {/*    onClick={onBoosterDoseClick}*/}
                {/*    color="primary"*/}
                {/*    variant="contained"*/}
                {/*  >*/}
                {/*    {t("verifyForBoosterDose")}*/}
                {/*  </LoadingButton>*/}
                {/*)}*/}
                {showProceedButton && (
                  <Button
                    onClick={onProceedButtonClick}
                    variant="contained"
                    color="primary"
                  >
                    {t("proceed")}
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Grid
                  spacing={1}
                  container
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item>
                    <Button onClick={onCancel}>{t("cancel")}</Button>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      disabled={isLoading}
                      CircularProgressProps={{
                        size: 16,
                      }}
                      spacing={1}
                      loading={isLoading}
                      type="submit"
                    >
                      {t("verify")}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VerifyInternalDoseDialog;
