const dhis2 = {
  baseUrl: "http://192.168.0.10:8080/dhis",
  // baseUrl: "http://192.168.0.16:8080/laocovid",
  // baseUrl: "http://localhost",
  // baseUrl: "https://covid.gov.la/dhis",
  //baseUrl: "https://dhis2.asia/laocovid",
  //username: "cvr",
  username: "precvr",
  password: "Abcd@2021",
  //password: "Abcd@1234"
};
const productionUrl = "https://vaccinatelaos.la";
// const productionUrl = "https://dhis2.asia/precvr";
//const productionUrl = "http://localhost:8080";

const apiServer = {
  baseUrl: "https://vaccinatelaos.la",
  // baseUrl: "https://vaccinatelaos.la"
  // baseUrl: "https://dhis2.asia/precvr",
  //baseUrl: "http://localhost:8080"
  // baseUrl: window.location.hostname + ":8080",
};

const certificationApi = {
  baseUrl: `https://vaccinatelaos.la`
};

const localCertificationApi = {
  baseUrl: `http://localhost:3000`,
  //baseUrl: `http://192.168.0.98:3000`,
  username: "certification",
  password: "be58a1c5-0800-4151-a6b7-71f85a710a21"
};

const localCertificationApiCvr = {
  baseUrl: `http://localhost:3001`,
  //baseUrl: `http://192.168.0.98:3001`,
  username: "certification",
  password: "be58a1c5-0800-4151-a6b7-71f85a710a21"
};

const localCertificationApiTravel = {
  baseUrl: `http://localhost:3002`,
  //baseUrl: `http://192.168.0.98:3001`,
  username: "certification",
  password: "be58a1c5-0800-4151-a6b7-71f85a710a21"
};

module.exports = {
  dhis2,
  productionUrl,
  apiServer,
  certificationApi,
  localCertificationApi,
  localCertificationApiCvr,
  localCertificationApiTravel
};
