import React from "react";
import Box from "@material-ui/core/Box";
import blue from "@material-ui/core/colors/blue";
import { Divider, Typography } from "@material-ui/core";
import yellow from "@material-ui/core/colors/yellow";
import { useTranslation } from "react-i18next";

const Heading = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box p={3} pt={6} bgcolor={blue[400]} color="white" textAlign="center">
        <Typography variant="h5" component="h1">
          {t("preRegister")}
        </Typography>
        <br />
        <Typography variant="h6" component="h2">
          {t("preRegisterSubText")}
        </Typography>
      </Box>
      <Divider />
      <Box p={2} bgcolor={yellow[200]}>
        <Typography>
          <strong>{t("preRegisterInstruction")}</strong>
        </Typography>
        <br />
        <Typography variant="body1" component="ul">
          {/*<li>{t("preRegisterInstruction1")}</li>*/}
          {/*<li>{t("preRegisterInstruction2")}</li>*/}
          {/* <li>{t("preRegisterInstruction3")}</li> */}
          <li>{t("preRegisterInstruction4")}</li>
          <li
            dangerouslySetInnerHTML={{
              __html: t("preRegisterInstruction5"),
            }}
          />
          {/* <li
            style={{ color: "red", fontWeight: "bold" }}
            dangerouslySetInnerHTML={{
              __html: t("preRegisterInstruction6"),
            }}
          ></li>
          <li
            style={{ color: "red", fontWeight: "bold" }}
            dangerouslySetInnerHTML={{
              __html: t("preRegisterInstruction7"),
            }}
          ></li>
          <li
            style={{ color: "red", fontWeight: "bold" }}
            dangerouslySetInnerHTML={{
              __html: t("preRegisterInstruction8"),
            }}
          ></li>
          <li
            style={{ color: "red", fontWeight: "bold" }}
            dangerouslySetInnerHTML={{
              __html: t("preRegisterInstruction10"),
            }}
          ></li> */}
        </Typography>
      </Box>
    </>
  );
};

export default Heading;
