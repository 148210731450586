import React from "react";
import { FormControl, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useTranslation } from "react-i18next";

export const DOSE_TYPES = {
  FIRST: "first",
  SECOND: "second",
  BOOSTER: "booster",
};

const DoseSelectRadioGroup = ({ label, value, onChange }) => {
  const { t } = useTranslation();
  const options = [
    {
      value: DOSE_TYPES.FIRST,
      label: t("firstDose"),
    },
    {
      value: DOSE_TYPES.SECOND,
      label: t("secondOrBoosterDose"),
    },
    // {
    //   value: DOSE_TYPES.BOOSTER,
    //   label: t("secondOrBoosterDose"),
    // },
  ];
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        value={value === DOSE_TYPES.BOOSTER ? DOSE_TYPES.SECOND : value}
        onChange={onChange}
      >
        {options.map(({ label, value }) => (
          <FormControlLabel
            value={value}
            control={<Radio color="primary" />}
            label={label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default DoseSelectRadioGroup;
