import moment from "moment";
import { PDFDocument } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

const createHierarchy = ({
  provincesRequest,
  districtsRequest,
  villagesRequest,
  provinceOrgUnits,
}) => {
  const findAttribute = (attributes, attributeId) => {
    const found = attributes.find((attr) => attr.attribute.id === attributeId);
    if (found) {
      return found.value;
    } else {
      return null;
    }
  };

  const hierarchy = [];
  const provinces = provincesRequest.options;
  const districts = districtsRequest.options;
  const villages = villagesRequest.options;

  provinces.forEach((province) => {
    hierarchy.push({
      value: province.code,
      data: null,
      path: province.code,
      label: province.name,
    });
  });
  districts.forEach((district) => {
    const provinceId = findAttribute(district.attributeValues, "REMLxBe9c4w");
    const provinceCode = provinceOrgUnits.organisationUnits.find(
      (ou) => ou.id === provinceId
    ).code;
    hierarchy.push({
      value: district.code,
      data: null,
      path: provinceCode + "/" + district.code,
      label: district.name,
    });
  });
  villages.forEach((village) => {
    const coordinates = findAttribute(village.attributeValues, "E4BKRTaQKot");
    const provinceCode = findAttribute(village.attributeValues, "REMLxBe9c4w");
    const districtCode = findAttribute(village.attributeValues, "GabcHXoJJWG");
    hierarchy.push({
      value: village.code,
      data: {
        latitude: coordinates ? parseFloat(coordinates.split(",")[0]) : "",
        longitude: coordinates ? parseFloat(coordinates.split(",")[1]) : "",
      },
      path: provinceCode + "/" + districtCode + "/" + village.code,
      label: village.name,
    });
  });
  hierarchy.sort(function (a, b) {
    var nameA = a.label.toUpperCase();
    var nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  return hierarchy;
};

const bindCeateOptions = (hierarchy) => (selections, typeIndex) =>
  hierarchy.filter((item) => {
    const splitted = item.path.split("/");
    if (typeIndex === 0) {
      return splitted.length === typeIndex + 1;
    } else if (selections[typeIndex - 1]) {
      return (
        splitted.length === typeIndex + 1 &&
        splitted[typeIndex - 1] === selections[typeIndex - 1]
      );
    }
  });

const generateTimes = () => {
  return new Array(18).fill().map((acc, index) => {
    const createTime = (step) =>
      `${format(
        moment({
          hour: Math.floor(step),
          minute: (step % 1) * 60,
        })
      )}`;
    const format = (time) => time.format("HH:mm");
    const create = (index) => {
      const step = index + 16;
      const nextStep = step + 1;
      return `${createTime(step / 2)} - ${createTime(nextStep / 2)}`;
    };
    return {
      label: create(index),
      value: create(index),
      //DUMMY SLOTS DATA
      remainingSlots: Math.floor(Math.random() * 11),
    };
  });
};

const sample = (d = [], fn = Math.random) => {
  if (d.length === 0) return;
  return d[Math.round(fn() * (d.length - 1))];
};

const generateUid = (limit = 11, fn = Math.random) => {
  const allowedLetters = [
    "abcdefghijklmnopqrstuvwxyz",
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
  ].join("");
  const allowedChars = ["0123456789", allowedLetters].join("");

  const arr = [sample(allowedLetters, fn)];

  for (let i = 0; i < limit - 1; i++) {
    arr.push(sample(allowedChars, fn));
  }

  return arr.join("");
};

const recursiveTranslate = (locale) => (item) => {
  if (!locale || !item) return item;
  if (Array.isArray(item)) {
    return item.map((childItem) => recursiveTranslate(locale)(childItem));
  } else if (typeof item === "object") {
    const { translations, ...rest } = item;
    if (!translations || !translations.length) {
      Object.entries(item).map(([key, value]) => {
        rest[key] = recursiveTranslate(locale)(value);
      });
      return rest;
    } else {
      translations
        .filter(({ locale: itemLocale }) => locale === itemLocale)
        .map(({ property, value }) => {
          switch (property) {
            case "SHORT_NAME": {
              rest.shortName = value;
              break;
            }
            case "NAME": {
              rest.name = value;
              break;
            }
            case "DESCRIPTION": {
              rest.description = value;
              break;
            }
            case "FORM_NAME": {
              rest.formName = value;
              break;
            }
            default:
          }
        });
      return recursiveTranslate(locale)(rest);
    }
  } else return item;
};

const yesNoOptions = (t) => [
  { code: "YES", name: t("yes") },
  { code: "NO", name: t("no") },
];

const createMetadataContext =
  (data, findFunc, getterFunc, defaultValue = null) =>
  (id) => {
    if (!data) return id;
    let rootItem = findFunc(data)(id);
    if (!rootItem) return defaultValue || id || null;
    rootItem = getterFunc(rootItem);
    return rootItem;
  };

const getOuDisplayName = (option) => {
  if (!option) return "";
  let displayName = "";
  if (option.shortName || option.description) {
    if (option.shortName) {
      displayName += option.shortName;
    }
    if (option.description) {
      displayName += ` (${option.description})`;
    }
  } else {
    displayName = option.name;
  }
  return displayName;
};

const sortVaccinations = (vaccinations) =>
  vaccinations.sort(
    (
      { vaccinationDate: avaccinationDate, isLastDose: aIsLastDose },
      { vaccinationDate: bvaccinationDate, isLastDose: bIsLastDose }
    ) => {
      if (aIsLastDose && !bIsLastDose) {
        return 1;
      } else if (!aIsLastDose && bIsLastDose) {
        return -1;
      } else if (avaccinationDate && bvaccinationDate) {
        return moment(avaccinationDate) - moment(bvaccinationDate);
      } else if (avaccinationDate && !bvaccinationDate) {
        return -1;
      } else if (!avaccinationDate && bvaccinationDate) {
        return 1;
      } else return 0;
    }
  );

const convertPdfDoc2FileURL = async (pdfDoc) => {
  let byteArray = await pdfDoc.save();
  let file = new Blob([byteArray], {
    type: "application/pdf;base64",
  });
  return URL.createObjectURL(file);
};

const showPage = async (pdfDoc, page_no, canvasEl) => {
  const binaryData = await pdfDoc.save();
  let _PDF_DOC;
  try {
    _PDF_DOC = await window.pdfjsLib.getDocument(binaryData).promise;
  } catch (e) {}
  const _CANVAS = canvasEl;
  const page = await _PDF_DOC.getPage(page_no);

  // original width of the pdf page at scale 1
  const pdf_original_width = page.getViewport({ scale: 1 }).width;
  // as the canvas is of a fixed width we need to adjust the scale of the viewport where page is rendered
  const scale_required = _CANVAS.width / pdf_original_width;

  // get viewport to render the page at required scale
  const viewport = page.getViewport({ scale: scale_required });

  // set canvas height same as viewport height
  _CANVAS.height = viewport.height;

  const render_context = {
    canvasContext: _CANVAS.getContext("2d"),
    viewport: viewport,
  };

  // render the page contents in the canvas
  try {
    await page.render(render_context);
  } catch (error) {
    alert(error.message);
  }
};

async function fillPdf(
  fileName,
  {
    data: {
      firstName,
      lastName,
      cvid,
      dob,
      age,
      province,
      district,
      village,
      vaccinations,
    },
    qrCode: qrCodeBase64Data,
    qrCodeLayout: { x = 315, y = 650, width = 164, height = 164 } = {},
    fontFileName,
  }
) {
  // Read pdf
  const pdfDoc = await fetch(
    `${process.env.PUBLIC_URL}/templates/${fileName}.pdf`,
    {}
  )
    .then((res) => res.arrayBuffer())
    .then((arrayBuffer) => PDFDocument.load(arrayBuffer));

  // Embed font
  pdfDoc.registerFontkit(fontkit);
  const font = await fetch(`${process.env.PUBLIC_URL}/fonts/${fontFileName}`)
    .then((res) => res.arrayBuffer())
    .then((arrayBuffer) => pdfDoc.embedFont(arrayBuffer));

  const pages = pdfDoc.getPages();

  const [dose1, dose2, dose3, dose4] = sortVaccinations(vaccinations);

  const FONT_SIZE = 10;
  const PAGE_HEIGHT = pages[0].getSize().height;
  const DOSE_SPACING = 38;

  const createVaccinationSiteConfigs = (vaccinationSite, x, startY) => {
    const VACCINATION_FONT_SIZE = FONT_SIZE - 1;
    const VACCINATION_SITE_WIDTH = 88;
    const VACCINATION_SITE_HEIGHT = DOSE_SPACING;
    const VERTICAL_SPACING = 0;
    const breakLine = (fontSize) => {
      return vaccinationSite.split(" ").reduce((result, word) => {
        let currentLineTextArray = result.pop();
        if (!currentLineTextArray) {
          currentLineTextArray = [];
        }
        const newLineTextArray = currentLineTextArray.concat([word]);
        const textInLine = newLineTextArray.join(" ");
        const textWidth = font.widthOfTextAtSize(textInLine, fontSize);
        if (textWidth <= VACCINATION_SITE_WIDTH) {
          result.push(newLineTextArray);
        } else {
          result.push(currentLineTextArray, [word]);
        }
        return result;
      }, []);
    };

    const textHeight = font.heightAtSize(VACCINATION_FONT_SIZE);

    const textLines = breakLine(VACCINATION_FONT_SIZE);

    const verticalPadding = () => {
      const size =
        textLines.length * textHeight +
        (textLines.length - 1) * VERTICAL_SPACING;
      return (VACCINATION_SITE_HEIGHT - size) / 2;
    };
    const padding = 0;

    return textLines.map((words, index) => {
      return {
        value: words.join(" "),
        x,
        y:
          index === 0
            ? startY + padding
            : padding + index * (textHeight + VERTICAL_SPACING) + startY,
        fontSize: VACCINATION_FONT_SIZE,
      };
    });
  };

  const createVaccinationRowConfig = (dose, { prefix, yOffset, doseName }) => {
    const result = [];
    if (doseName) {
      result.push({
        name: `${prefix}`,
        value: doseName,
        x: 88,
        y: yOffset + 8 + DOSE_SPACING,
      });
    }
    result.push(
      {
        name: `${prefix}Name`,
        value: dose.vaccineType,
        x: 160,
        y: yOffset + DOSE_SPACING,
      },
      {
        name: `${prefix}BatchNumber`,
        value: dose.batchNumber,
        x: 160,
        y: yOffset + 19 + DOSE_SPACING,
      },
      {
        name: `${prefix}VaccinationDate`,
        value: dose.vaccinationDate.split("-").pop(),
        x: 280,
        y: yOffset + 12 + DOSE_SPACING,
      },
      {
        name: `${prefix}VaccinationMonth`,
        value: dose.vaccinationDate.split("-")[1],
        x: 307,
        y: yOffset + 12 + DOSE_SPACING,
      },
      {
        name: `${prefix}VaccinationYear`,
        value: dose.vaccinationDate.split("-").shift(),
        x: 327,
        y: yOffset + 12 + DOSE_SPACING,
      },
      ...createVaccinationSiteConfigs(
        dose.vaccinationSite,
        355,
        yOffset + DOSE_SPACING
      )
    );
    return result;
  };

  const configs = [
    {
      name: "firstName",
      value: firstName || "",
      x: 85,
      y: 197,
    },
    {
      name: "lastName",
      value: lastName || "",
      x: 200,
      y: 197,
    },
    {
      name: "cvidSlot1",
      value: cvid.split("-")[0],
      x: 357,
      y: 197,
    },
    {
      name: "cvidSlot2",
      value: cvid.split("-")[1],
      x: 425,
      y: 197,
    },
    {
      name: "cvidSlot3",
      value: cvid.split("-")[2],
      x: 465,
      y: 197,
    },
    {
      name: "dob",
      value: moment(dob, "YYYY-MM-DD").format("DD / MM / YYYY"),
      x: 85,
      y: 238,
    },
    {
      name: "age",
      value: age,
      x: 175,
      y: 238,
    },
    {
      name: "village",
      value: village || "",
      x: 260,
      y: 238,
    },
    {
      name: "district",
      value: district || "",
      x: 347,
      y: 238,
    },
    {
      name: "province",
      value: province || "",
      x: 434,
      y: 238,
    },
    ...createVaccinationRowConfig(dose1, {
      prefix: "dose1",
      yOffset: 265,
    }),
  ];

  // 2nd dose row
  // only 2 dose vaccine type
  if (!SINGLE_DOSE_VACCINE_TYPES.includes(dose1.vaccineType)) {
    configs.push(
      ...createVaccinationRowConfig(dose2, { yOffset: 303, prefix: "dose2" })
    );
  }

  // 3rd dose row
  // 2 dose vaccine type & dose 3 || 1 dose vaccine type && dose 2
  switch (true) {
    case Boolean(
      SINGLE_DOSE_VACCINE_TYPES.includes(dose1.vaccineType) && dose2
    ):
      configs.push(
        ...createVaccinationRowConfig(dose2, {
          yOffset: 338,
          prefix: "dose3",
          doseName: "Booster",
        })
      );
      break;
    case Boolean(
      !SINGLE_DOSE_VACCINE_TYPES.includes(dose1.vaccineType) && dose3
    ):
      configs.push(
        ...createVaccinationRowConfig(dose3, {
          prefix: "dose3",
          yOffset: 338,
          doseName: "Booster",
        })
      );
      break;
    default:
  }

  switch (true) {
    case Boolean(
      SINGLE_DOSE_VACCINE_TYPES.includes(dose1.vaccineType) && dose3
    ):
      configs.push(
        ...createVaccinationRowConfig(dose3, {
          yOffset: 373,
          prefix: "dose4",
          doseName: "Booster",
        })
      );
      break;
    case Boolean(
      !SINGLE_DOSE_VACCINE_TYPES.includes(dose1.vaccineType) && dose4
    ):
      configs.push(
        ...createVaccinationRowConfig(dose4, {
          prefix: "dose4",
          yOffset: 373,
          doseName: "Booster",
        })
      );
      break;
    default:
  }

  configs.map(({ x, y, value, fontSize }) => {
    pages[0].drawText(value, {
      x,
      y: PAGE_HEIGHT - y,
      size: fontSize || FONT_SIZE,
      font,
    });
  });

  // add QRCODE
  const qrCodeImage = await pdfDoc.embedPng(qrCodeBase64Data);
  pages[0].drawImage(qrCodeImage, {
    x,
    y: pages[0].getSize().height - y,
    width,
    height,
  });
  return pdfDoc;
}

const isDoseDone = ({
  vaccineType,
  batchNumber,
  vaccinationDate,
  vaccinationSite,
  doseNumber,
}) => {
  return !!vaccineType && !!batchNumber && !!doseNumber && !!vaccinationDate;
};

const SINGLE_DOSE_VACCINE_TYPES = ["Johnson & Johnson", "Sputnik Light"];

const isVaccinationDone = ({ completed, vaccinations }) => {
  return completed;
  /*const doneVaccinations = sortVaccinations(
    vaccinations.filter((vaccination) => isDoseDone(vaccination))
  );
  const [dose1] = doneVaccinations;
  if (!dose1) return false;
  if (dose1.vaccineType === JJTYPE) {
    return doneVaccinations.length >= 1;
  }
  return (
    vaccinations.filter((vaccination) => isDoseDone(vaccination)).length > 1
  );*/
};

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export {
  createHierarchy,
  bindCeateOptions,
  generateTimes,
  generateUid,
  recursiveTranslate,
  yesNoOptions,
  createMetadataContext,
  getOuDisplayName,
  sortVaccinations,
  convertPdfDoc2FileURL,
  fillPdf,
  isDoseDone,
  showPage,
  isVaccinationDone,
  randomIntFromInterval,
  SINGLE_DOSE_VACCINE_TYPES,
};
