import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Slide
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ResultDialog = ({
  data: { status, dateToComePickup, cvid, placeComeToPick, fullName, passportNumber, pickupLocation },
  open,
  onClose
}) => {
  const { t, i18n } = useTranslation();
  return (
    <Dialog open={open}>
      <Box textAlign="center">
        <DialogTitle>{t("ICRresult")}</DialogTitle>
      </Box>
      <DialogContent>
        <Box textAlign="left">
          {status === "ok" ? (
            <>
              <DialogContentText>
                {t("dateToComeToPickup")}: <strong>{dateToComePickup}</strong>
              </DialogContentText>
              <DialogContentText>
                {t("placeToComeToPickup")}: <strong>{placeComeToPick}</strong>
              </DialogContentText>
              <DialogContentText>
                CVID: <strong>{cvid}</strong>
              </DialogContentText>
              <DialogContentText>
                {t("fullName")}: <strong>{fullName}</strong>
              </DialogContentText>
              <DialogContentText>
                {t("passportNumber")}: <strong>{passportNumber}</strong>
              </DialogContentText>
              {/* <DialogContentText>
            {t("helpdeskEmail")}: <strong>{"laohmis@gmail.com"}</strong>
          </DialogContentText> */}
              <DialogContentText>
                {pickupLocation === "email" ? (
                  <Typography variant={"body1"} align={"center"} style={{ color: "red" }}>
                    {t("interCertReceiptNoteEmail")}
                  </Typography>
                ) : (
                  <Typography variant={"body1"} align={"center"} style={{ color: "red" }}>
                    {t("interCertReceiptNote")}
                  </Typography>
                )}
              </DialogContentText>
            </>
          ) : (
            <>
              <DialogContentText>
                <Typography variant={"body1"} align={"center"} style={{ color: "red" }}>
                  {t("unknownServerError")}
                </Typography>
              </DialogContentText>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button size="large" color="primary" onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ResultDialog;
