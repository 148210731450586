import { bindApi } from "./base";
import { apiServer } from "../config/config";

const apiFetch = bindApi(apiServer.baseUrl);

export const getVaccinationSitesApi = async () =>
  apiFetch(`/api/organisationUnits`);

export const getVaccinationSlotsApi = async ({
  vaccinationDate,
  vaccinationSite,
  slot = "",
}) =>
  apiFetch(
    `/api/vaccineSlots?ou=${vaccinationSite}&registerDate=${vaccinationDate}&slot=${slot}`
  );

export const getTrackedEntityAttributes = async ({ id }) =>
  apiFetch(`/api/trackedEntityAttributes/${id}`);

export const postSubmitData = (data) =>
  apiFetch(`/api/registration`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

export const registerTEI = (data) =>
  apiFetch(`/api/registerTEI`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

export const getCheckStatus = ({ searchType, searchValue }) =>
  apiFetch(`/api/checkStatus?${searchType}=${searchValue}`);

export const DOSE_PARAM_TYPES = {
  FIRST: "first",
  SECOND: "second",
  BOOSTER: "booster",
};

export const getAvailableSlotsInMonth = ({ dose, ou, startDate, endDate }) =>
  apiFetch(
    `/api/availableSlots?ou=${ou}&startDate=${startDate}&endDate=${endDate}&dose=${dose}`
  );

export const checkStatusForSecondDose = ({ phoneNumber, CVID, dob, locale }) =>
  apiFetch(
    `/api/checkStatusForSecondDose?phoneNumber=${phoneNumber}&CVID=${CVID}&dob=${dob}&locale=${locale}`
  );

export const checkStatusForBoosterDose = ({ phoneNumber, CVID, dob, locale }) =>
  apiFetch(
    `/api/checkStatusForBoosterDose?phoneNumber=${phoneNumber}&CVID=${CVID}&dob=${dob}&locale=${locale}`
  );

export const registerForSecondDose = (payload) =>
  apiFetch(`/api/registrationForSecondDose`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

export const registerForBoosterDose = (payload) =>
  apiFetch(`/api/registrationForBoosterDose`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

export const requestInternationalCertificate = (payload) =>
  apiFetch(`/api/requestInternationalCertificate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

export const upFile = (data) => {
  return apiFetch(`/api/uploadFile`, {
    method: "POST",
    body: data,
  });
};
export const getCertificate = ({
  firstName,
  lastName,
  phoneNumber,
  systemCVID,
  dateOfBirth,
  sex,
  locale,
}) =>
  apiFetch(
    `/api/certificate?fn=${firstName}&ln=${lastName}&p=${phoneNumber}&cvid=${systemCVID}&dob=${dateOfBirth}&s=${sex}&locale=${locale}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
