import * as React from "react";
import Banner from "./components/Banner";
import image1 from "../../assets/images/personal_qr_code.png";
import image3 from "../../assets/images/vaccination.png";
import image2 from "../../assets/images/qr_code_info.png";
import image4 from "../../assets/images/verification.png";
import { Container, Grid, Box, Typography } from "@material-ui/core";
import InfoCard from "./components/InfoCard";
import grey from "@material-ui/core/colors/grey";
import { useTranslation } from "react-i18next";
import androidDownload from "../../assets/images/android_download.png";
import iosDownload from "../../assets/images/ios_download.png";
import Footer from "./components/Footer";

const LaoDP = () => {
  const { t } = useTranslation();
  return (
    <>
      <Banner />
      <Box component={Container} mt={3} width="100%">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InfoCard
              title={t("qrCode")}
              description={t("qrCodeBrife")}
              imgSrc={image1}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoCard
              title={t("covid19InfoCard")}
              description={t("covid19InfoCardBrife")}
              imgSrc={image2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoCard
              title={t("covid19VaccineSystem")}
              description={t("covid19VaccineSystemBrife")}
              imgSrc={image3}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoCard
              title={t("verification")}
              description={t("verificationBrife")}
              imgSrc={image4}
            />
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};
export default LaoDP;
