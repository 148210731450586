import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";

const LoadingButton = ({
  children,
  loading,
  CircularProgressProps,
  spacing,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Button {...rest}>
      <Grid container alignItems="center" justify="center">
        {loading && (
          <>
            <CircularProgress {...CircularProgressProps} />
            <Box mr={spacing} />
          </>
        )}
        {children}
      </Grid>
    </Button>
  );
};

export default LoadingButton;
