import { takeLatest, put } from "redux-saga/effects";
import { GET_ATTRIBUTES } from "../reducers/metadata/attributes/types";
import { getVillageHierarchySucceed } from "../reducers/metadata/villageHierarchy/actions";
import { createHierarchy, recursiveTranslate } from "../../utils";
import rootProvincesRequest from "../../config/provinceOptions.json";
import rootDistrictsRequest from "../../config/districtOptions.json";
import rootVillagesRequest from "../../config/villageOptions.json";
import rootProvinceOrgUnits from "../../config/provinceOrgUnits.json";

function* getVillageHierarchyFlow({ locale }) {
  const languageRecursiveTranslate = recursiveTranslate(locale);
  const provincesRequest = languageRecursiveTranslate(rootProvincesRequest),
    districtsRequest = languageRecursiveTranslate(rootDistrictsRequest),
    villagesRequest = languageRecursiveTranslate(rootVillagesRequest),
    provinceOrgUnits = languageRecursiveTranslate(rootProvinceOrgUnits);
  const hierarchy = createHierarchy({
    provincesRequest,
    districtsRequest,
    villagesRequest,
    provinceOrgUnits,
  });

  yield put(
    getVillageHierarchySucceed({
      hierarchy,
      districts: districtsRequest,
      provinces: provincesRequest,
      villages: villagesRequest,
    })
  );
}

function* getVillageHierarchy() {
  yield takeLatest(GET_ATTRIBUTES, getVillageHierarchyFlow);
}

export default getVillageHierarchy;
