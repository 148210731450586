import {
  SET_RESULT_LOADING,
  SET_RESULT_DATA,
  SET_RESULT_ERROR,
  OPEN_RESULT_MODAL,
} from "./types";
import { combineReducers } from "redux";

const isLoading = (state = false, action) => {
  switch (action.type) {
    case SET_RESULT_LOADING:
      return action.isLoading;
    default:
      return state;
  }
};

const data = (state = null, action) => {
  switch (action.type) {
    case SET_RESULT_DATA:
      return action.data;
    default:
      return state;
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case SET_RESULT_ERROR:
      return action.error;
    default:
      return state;
  }
};

const isOpenResultModal = (state = false, action) => {
  switch (action.type) {
    case OPEN_RESULT_MODAL:
      return action.isOpen;
    default:
      return state;
  }
};

export default combineReducers({
  isLoading,
  data,
  error,
  isOpenResultModal,
});
