import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const NewSimpleCaptcha = ({
  placeholder,
  value,
  onChange,
  reloadText,
  error,
  captchaValue,
  setCaptchaValue,
}) => {
  const generateCaptchaValue = () =>
    Array(4)
      .fill()
      .map(() => randomIntFromInterval(0, 9))
      .join("");
  useEffect(() => {
    setCaptchaValue(generateCaptchaValue());
  }, []);

  useEffect(() => {
    if (error) {
      setCaptchaValue(generateCaptchaValue());
    }
  }, [error]);
  return (
    <Box
      // component={FormControl}
      display="inline-flex"
      flexDirection="column"
      p={3}
      border={1}
      borderRadius="borderRadius"
      borderColor={error ? "error.main" : "grey.400"}
      width="auto"
      textAlign="center"
      // error={error}
    >
      <Box
        textAlign="center"
        style={{
          userSelect: "none",
          msUserSelect: "none",
          pointerEvents: "none",
        }}
        mb={2}
      >
        <Typography variant="h4">{captchaValue}</Typography>
      </Box>
      <Box textAlign="center">
        <Box
          startIcon={<RefreshIcon />}
          component={Button}
          onClick={() => setCaptchaValue(generateCaptchaValue())}
          size="small"
          variant="outlined"
          width={1}
          whiteSpace="nowrap"
        >
          {reloadText}
        </Box>
      </Box>
      <Box mt={2}>
        <TextField
          error={error}
          placeholder={placeholder}
          id="user_captcha_input"
          name="user_captcha_input"
          type="text"
          onChange={(e) => onChange(e)}
          value={value}
          variant="outlined"
          size="small"
          autoComplete="off"
        />
      </Box>
    </Box>
  );
};

export default NewSimpleCaptcha;
