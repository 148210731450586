import React, { useEffect, useMemo, useState } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import Layout from "./common/Layout";
import AppRoute from "./common/AppRoute";
import {
  createMuiTheme,
  CssBaseline,
  MuiThemeProvider,
} from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";
import "moment/locale/lo";
import { useTranslation } from "react-i18next";
import phetsarath from "./fonts/phetsarath";
import { getAttributes } from "./redux/reducers/metadata/attributes/actions";
import { useDispatch, useSelector } from "react-redux";
import withSkeletonLoading from "./hocs/withSkeletonLoading";
import { getDataElements } from "./redux/reducers/metadata/dataElements/actions";
import { getVillageHierarchy } from "./redux/reducers/metadata/villageHierarchy/actions";

const LoadingAppRoute = withSkeletonLoading()(AppRoute);

const createTheme = ({ locale }) => {
  const defaultTheme = createMuiTheme();
  if (locale === "lo") {
    return createMuiTheme({
      typography: {
        fontFamily: `"Phetsarath", ` + defaultTheme.typography.fontFamily,
      },
      palette: {
        primary: {
          main: blue[500],
        },
      },
      overrides: {
        MuiCssBaseline: {
          "@global": {
            "@font-face": [phetsarath],
          },
        },
      },
    });
  } else
    return createMuiTheme({
      palette: {
        primary: {
          main: blue[500],
        },
      },
    });
};

const App = () => {
  const [locale, setLocale] = useState("en");
  const [theme, setTheme] = useState(createTheme({ locale: "en" }));
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const attributes = useSelector((state) => state.metadata.attributes);
  const dataElements = useSelector((state) => state.metadata.dataElements);
  const hierarchy = useSelector(
    (state) => state.metadata.villageHierarchy.hierarchy
  );
  useEffect(() => {
    setLocale(i18n.language);
    dispatch(getAttributes(i18n.language));
    dispatch(getDataElements(i18n.language));
    setTheme(createTheme({ locale: i18n.language }));
    dispatch(getVillageHierarchy(i18n.language));
  }, [i18n.language]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <MuiPickersUtilsProvider
        libInstance={moment}
        locale={locale}
        utils={MomentUtils}
      >
        <LoadingAppRoute
          loading={!attributes}
          loaded={!!attributes && !!dataElements && !!hierarchy}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default App;
