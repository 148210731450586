import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  InputLabel,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DOSE_TYPES } from "../../common/TypedInput/DoseSelectRadioGroup";

const VaccinationSlotButtonSelect = ({
  rawData,
  value = null,
  onChange,
  label,
  doseNumber,
  ...props
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const options = useMemo(() => {
    return _(rawData.slots)
      .toPairs()
      .map(
        ([
          dataElementId,
          { label, remaining1Slots, remaining2Slots, remainingBoosterSlots },
        ]) => {
          const getRemainingSlots = () => {
            switch (doseNumber) {
              case DOSE_TYPES.FIRST:
                return remaining1Slots;
              case DOSE_TYPES.SECOND:
                return remaining2Slots;
              case DOSE_TYPES.BOOSTER:
                return remainingBoosterSlots;
              default:
                return 0;
            }
          };
          return {
            label,
            remainingSlots: getRemainingSlots(),
          };
        }
      )
      .sort(
        ({ label: aLabel }, { label: bLabel }) =>
          moment(aLabel, "HH.mm") - moment(bLabel, "HH.mm")
      )
      .value();
  }, [rawData]);

  const isInvalidValue = (value) =>
    options.every(
      ({ label, remainingSlots }) => label !== value || !remainingSlots
    );

  useEffect(() => {
    if (options.every(({ remainingSlots }) => remainingSlots === "0")) {
      setOpen(true);
    }
    if (value !== null && isInvalidValue(value)) {
      onChange(null);
    }
  }, [options]);
  return (
    <Box>
      <Box pb={2}>
        <InputLabel error={props.error} required={props.required}>
          {label}
        </InputLabel>
      </Box>
      <Grid container spacing={1}>
        {!options.length ? (
          <Grid item>
            <Typography>{t("noSlot")}</Typography>
          </Grid>
        ) : (
          options.map(({ label, remainingSlots }) => {
            return (
              <Grid item xs={6} sm={4} md={3}>
                <Button
                  color="primary"
                  fullWidth
                  disabled={remainingSlots <= 0}
                  variant={label === value ? "contained" : "outlined"}
                  key={label}
                  onClick={() => onChange(label)}
                  {...props}
                >
                  {`${label} (${remainingSlots})`}
                </Button>
              </Grid>
            );
          })
        )}
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <DialogContentText>{t("noSlotMessage")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{t("ok")}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default VaccinationSlotButtonSelect;
