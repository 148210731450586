import React, { useMemo, useState, useEffect, useRef } from "react";
import TypedInput from "../../common/TypedInput";
import withFormController from "../../hocs/withFormController";
import withDhis2Metadata from "../../hocs/withDhis2Metadata";
import { compose } from "redux";
import { useTranslation } from "react-i18next";
import { Button, FormHelperText, Grid, Box } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { randomIntFromInterval, getOuDisplayName, recursiveTranslate } from "../../utils";
import useQuery from "../../hooks/useQuery";
import { DHIS2_TYPES } from "../../common/TypedInput/utils";
import { find } from "lodash";

import CVIDInput from "../CVR/CVIDInput";
import NewSimpleCaptcha from "../CVR/NewSimpleCaptcha";
import { getTrackedEntityAttributes } from "../../services/api";
import { PHONE_PREFIX } from "../../constants/global";
import { getLatestAttributes } from "../../redux/reducers/metadata/attributes/actions";
import passport_guide from "../../asset/images/passport-guide.png";
import moment from "moment";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

const SearchTEI = ({ searchSubmit }) => {
  const { control, handleSubmit, setValue } = useForm();
  const { i18n, t } = useTranslation();
  const attributes = useSelector((state) => state.metadata.attributes);

  useEffect(async () => {
    // setValue("ciCR6BBvIT4", "02093939393");
    // setValue("corXnplgfQ7", "H0167-U-11223");
    // setValue("tQeFLjYbqzv", "2000-01-20");
    // setValue("ciCR6BBvIT4", "02033333223");
    // setValue("corXnplgfQ7", "H0167-M-99887");
    // setValue("tQeFLjYbqzv", "2000-01-20");
    // setValue("ciCR6BBvIT4", "0301233455");
    // setValue("corXnplgfQ7", "H0004-U-09987");
    // setValue("tQeFLjYbqzv", "1993-08-25");
    // setValue("ciCR6BBvIT4", "0201231234");
    // setValue("corXnplgfQ7", "H0002-A-12344");
    // setValue("tQeFLjYbqzv", "1994-05-18");
    // // //
    // setValue("ciCR6BBvIT4", "02059443307");
    // setValue("corXnplgfQ7", "H0169-Y-L8812");
    // setValue("tQeFLjYbqzv", "2000-01-20");
    //
    // setValue("ciCR6BBvIT4", "02055907074");
    // setValue("corXnplgfQ7", "H0004-A-01289");
    // setValue("tQeFLjYbqzv", "2000-01-20");
  }, []);

  const AttributeDhis2FormInput = useMemo(
    () =>
      compose(
        withDhis2Metadata(
          attributes,
          (metadata) => (id) => {
            return metadata.programTrackedEntityAttributes.find(
              ({ trackedEntityAttribute: { id: attrId } }) => attrId === id
            );
          },
          (item) => item.trackedEntityAttribute,
          {
            pickedProps: ["id", ["label", ["formName", "name"]], "optionSet", "valueType"]
          }
        ),
        withFormController({ control })
      )(TypedInput),
    [attributes]
  );

  const params = useQuery();
  return (
    <form onSubmit={handleSubmit(searchSubmit)}>
      <Box pt={3}>
        <Grid container spacing={3}>
          <Grid xs={12} md={6} item container>
            <AttributeDhis2FormInput
              ParentComponent={Grid}
              ParentComponentProps={{
                item: true,
                xs: 12,
                alignItems: "flex-end",
                container: true
              }}
              id="ciCR6BBvIT4"
              // valueType={DHIS2_TYPES.NUMBER}
              rules={{
                required: t("thisFieldIsRequired"),
                maxLength: {
                  value: 11,
                  message: t("phoneNumberHasMaximum11Digits")
                },
                pattern: {
                  value: PHONE_PREFIX,
                  message: t("phoneNumberHasToStartWithLaosAndVietnamese")
                }
              }}
              defaultValue={params.get("phoneNumber")}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <AttributeDhis2FormInput
              id="corXnplgfQ7"
              valueType={DHIS2_TYPES.OTHER}
              Component={CVIDInput}
              variant="outlined"
              size="small"
              autoFocus
              lengthonValid={4}
              ParentComponent={Box}
              ParentComponentProps={{
                component: Grid,
                item: true,
                width: 1,
                pt: 0,
                pb: 0
              }}
              rules={{
                required: t("thisFieldIsRequired"),
                pattern: {
                  value: /^[A-Z]\d{4}-[A-Z]-(\d|[A-Z])\d{4}$/,
                  message: t("wrongCVIDFormat")
                }
              }}
              // defaultValue={params.get("systemCVID")}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <AttributeDhis2FormInput
              ParentComponent={Grid}
              ParentComponentProps={{ item: true }}
              variant="dialog"
              inputVariant="outlined"
              id="tQeFLjYbqzv"
              // valueType={DHIS2_TYPES.DATE}
              // defaultValue={null}
              disableFuture
              // autoOk
              format="YYYY-MM-DD"
              rules={{
                required: t("thisFieldIsRequired")
              }}
              views={["year", "month", "date"]}
              openTo="year"
              defaultValue={params.get("dateOfBirth") || null}
            />
          </Grid>
        </Grid>
      </Box>

      <Box pt={5} textAlign="center">
        <Button color="primary" variant="contained" type="submit">
          <Grid container alignItems="center" justify="center">
            {t("search")}
          </Grid>
        </Button>
      </Box>
    </form>
  );
};

const RequestForm = (props) => {
  const { onSubmit, searchSubmit, setLoading } = props;
  const attributes = useSelector((state) => state.metadata.attributes);

  const dataElements = useSelector((state) => state.metadata.dataElements);
  const { control, handleSubmit, setValue } = useForm();
  const [options, setOptions] = useState(null);
  const [emailRequired, setEmailRequired] = useState(false);
  const isOpenInternationalCVR = useSelector((state) => state.cvr.openInternationalCVR);
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [captchaValue, setCaptchaValue] = useState("");
  const [rootCaptchaValue, setRootCaptchaValue] = useState("");
  const [captchaMessage, setCaptchaMessage] = useState("");

  useEffect(() => {
    async function mergeAttribute() {
      setLoading(true);
      try {
        let pickupOptions = await getTrackedEntityAttributes({
          id: "mIvcUdONwNH"
        });
        if (
          !pickupOptions &&
          !pickupOptions.trackedEntityAttributes &&
          pickupOptions.trackedEntityAttributes.length === 0
        ) {
          return null;
        }

        setOptions({ mIvcUdONwNH: pickupOptions.trackedEntityAttributes[0] });
        if (attributes && pickupOptions) {
          dispatch(getLatestAttributes(i18n.language, pickupOptions));
          setLoading(false);
        }
      } catch (error) {
        console.log("request form error", error);
      }
    }
    mergeAttribute();
  }, [i18n.language]);

  const AttributeDhis2FormInput = useMemo(
    () =>
      compose(
        withDhis2Metadata(
          attributes,
          (metadata) => (id) => {
            // console.log(id);
            return metadata.programTrackedEntityAttributes.find(
              ({ trackedEntityAttribute: { id: attrId } }) => attrId === id
            );
          },
          (item) => item.trackedEntityAttribute,
          {
            pickedProps: ["id", ["label", ["formName", "name"]], "optionSet", "valueType"]
          }
        ),
        withFormController({ control })
      )(TypedInput),
    [attributes]
  );

  const DataElementFormInput = useMemo(
    () =>
      compose(
        withDhis2Metadata(
          dataElements,
          (metadata) => (id) => {
            return metadata.programStageDataElements.find(({ dataElement: { id: attrId } }) => {
              return attrId === id;
            });
          },
          (item) => item.dataElement,
          {
            pickedProps: ["id", ["label", ["formName", "name"]], "valueType", "optionSet"]
          }
        ),
        withFormController({ control })
      )(TypedInput),
    [dataElements]
  );

  const validateCaptcha = (captchaValue) => captchaValue === rootCaptchaValue;
  const generateCaptchaValue = () =>
    Array(4)
      .fill()
      .map(() => randomIntFromInterval(0, 9))
      .join("");

  const childRef = useRef();
  const internalSubmit = (values) => {
    if (!validateCaptcha(captchaValue)) {
      return setCaptchaMessage(t("wrongValue"));
    }

    onSubmit(values);
    ["uEjTwR7UtaN", "ivMyUVXyofq", "ciCR6BBvIT4", "corXnplgfQ7", "pjpnF7u5PQj", "mIvcUdONwNH"].map((id) =>
      setValue(id, "")
    );
    setValue("tQeFLjYbqzv", null);
    setValue("dETjvpUuvjV", null);

    if (childRef.current) {
      childRef.current.onRemove();
    }

    setCaptchaValue("");
    setRootCaptchaValue(generateCaptchaValue());
  };
  const FormController = useMemo(() => withFormController({ control })(), []);
  const params = useQuery();

  return (
    <>
      <SearchTEI searchSubmit={searchSubmit} />
      {isOpenInternationalCVR && (
        <form onSubmit={handleSubmit(internalSubmit)}>
          <Box pt={3}>
            {/*Hidden values*/}
            <FormController
              id="pickupLocationName"
              defaultValue={"teiUid"}
              rules={{
                required: t("thisFieldIsRequired")
              }}
              dependentFields="mIvcUdONwNH"
              onDependentChangedProps={(value) => {
                let option = null;
                if (options && options["mIvcUdONwNH"]) {
                  option = find(options["mIvcUdONwNH"].optionSet.options, {
                    code: value
                  });
                }
                const translatedOption = recursiveTranslate(i18n.language)(option);
                setValue("pickupLocationName", translatedOption ? translatedOption.name : "");
                if (value === "email") {
                  setEmailRequired(true);
                } else {
                  setEmailRequired(false);
                }
              }}
              onDependentChangedRenderer={(value) => {
                return false;
              }}
            />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <AttributeDhis2FormInput
                  ParentComponent={Grid}
                  ParentComponentProps={{ item: true }}
                  // valueType={DHIS2_TYPES.TEXT}
                  id="uEjTwR7UtaN"
                  rules={{
                    required: t("thisFieldIsRequired")
                  }}
                  defaultValue={params.get("firstName")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AttributeDhis2FormInput
                  ParentComponent={Grid}
                  ParentComponentProps={{ item: true }}
                  // valueType={DHIS2_TYPES.TEXT}
                  id="ivMyUVXyofq"
                  rules={{
                    required: t("thisFieldIsRequired")
                  }}
                  defaultValue={params.get("lastName")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AttributeDhis2FormInput
                  ParentComponent={Grid}
                  ParentComponentProps={{ item: true }}
                  // valueType={DHIS2_TYPES.TEXT}
                  id="pjpnF7u5PQj"
                  rules={{
                    required: t("thisFieldIsRequired")
                  }}
                  defaultValue={params.get("passportNumber")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AttributeDhis2FormInput
                  ParentComponent={Grid}
                  ParentComponentProps={{ item: true }}
                  // valueType={DHIS2_TYPES.TEXT}
                  id="I0GHGtoEoUq"
                  views={["year", "month", "date"]}
                  openTo="year"
                  rules={{
                    required: t("thisFieldIsRequired")
                  }}
                  format="DD/MM/YYYY"
                  defaultValue={params.get("passportDateOfIssuance")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <AttributeDhis2FormInput
                  ParentComponent={Grid}
                  ParentComponentProps={{ item: true }}
                  // valueType={DHIS2_TYPES.TEXT}
                  id="mIvcUdONwNH"
                  rules={{
                    required: t("thisFieldIsRequired")
                  }}
                  defaultValue={params.get("pickupLocation")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AttributeDhis2FormInput
                  ParentComponent={Grid}
                  ParentComponentProps={{ item: true }}
                  // valueType={DHIS2_TYPES.TEXT}
                  id="jFZFXOCI9he"
                  rules={{
                    required: emailRequired && t("thisFieldIsRequired"),
                    pattern: {
                      value: emailRegex,
                      message: t("wrongEmailFormat")
                    }
                  }}
                  dependentFields="mIvcUdONwNH"
                  onDependentChangedProps={(value) => {
                    // console.log("jFZFXOCI9he", value);
                  }}
                  defaultValue={params.get("email")}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "center" }}>
                <img src={passport_guide} style={{ width: "100%" }} />
              </Grid>
              <Grid item xs={12} md={12}>
                <DataElementFormInput
                  ref={childRef}
                  ParentComponent={Grid}
                  ParentComponentProps={{ item: true }}
                  id="dETjvpUuvjV"
                  valueType={DHIS2_TYPES.FILE_RESOURCE}
                  color="primary"
                  rules={{
                    required: t("thisFieldIsRequired")
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <DataElementFormInput
                  ref={childRef}
                  ParentComponent={Grid}
                  ParentComponentProps={{ item: true }}
                  id="Qle5f5LHdm7"
                  valueType={DHIS2_TYPES.FILE_RESOURCE}
                  color="primary"
                  rules={{
                    required: t("thisFieldIsRequired")
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box pt={3} textAlign="center">
            <NewSimpleCaptcha
              value={captchaValue}
              onChange={(e) => {
                setCaptchaValue(e.target.value);
                setCaptchaMessage("");
              }}
              placeholder={t("captchaText")}
              reloadText={t("reloadCaptcha")}
              error={!!captchaMessage}
              captchaValue={rootCaptchaValue}
              setCaptchaValue={setRootCaptchaValue}
            />
            {captchaMessage && (
              <FormHelperText
                style={{
                  textAlign: "center"
                }}
                error
              >
                {t("wrongValue")}
              </FormHelperText>
            )}
          </Box>

          <Box pt={5} textAlign="center">
            <Button color="primary" variant="contained" type="submit">
              <Grid container alignItems="center" justify="center">
                {t("request")}
              </Grid>
            </Button>
          </Box>
        </form>
      )}
    </>
  );
};

export default RequestForm;
