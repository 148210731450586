import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getVaccinationSitesApi } from "../../services/api";
import { recursiveTranslate } from "../../utils";
import withSkeletonLoading from "../../hocs/withSkeletonLoading";

import VaccinationSiteSelect from "./VaccinationSiteSelect";
import InputSkeleton from "../../skeletons/Input.skeleton";
import { compose } from "redux";
import {
  getOrgUnits,
  getOrgUnitsSucceed,
} from "../../redux/reducers/metadata/orgUnits/actions";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";

const VaccinationSiteSelectContainer = ({ id, control }) => {
  const dispatch = useDispatch();
  const VaccinationSiteSelectInput = useMemo(
    () => compose(withSkeletonLoading(InputSkeleton))(VaccinationSiteSelect),
    []
  );

  const { t, i18n } = useTranslation();
  const [translatedData, setTranslatedData] = useState(null);
  const orgUnits = useSelector((state) => state.metadata.orgUnits);
  // const [orgUnits, setRawData] = useState(null);

  const getVaccinationSites = async () => {
    dispatch(getOrgUnits());
    const rawData = await getVaccinationSitesApi();
    dispatch(getOrgUnitsSucceed(rawData));
    // setRawData(rawData);
    setTranslatedData(recursiveTranslate(i18n.language)(rawData));
  };

  useEffect(() => {
    if (orgUnits) {
      setTranslatedData(recursiveTranslate(i18n.language)(orgUnits));
    }
  }, [i18n.language, orgUnits]);

  useEffect(() => {
    getVaccinationSites();
  }, []);

  return (
    <Grid item>
      <VaccinationSiteSelectInput
        // Loading Props
        loading={!translatedData}
        loaded={!!translatedData}
        // child props
        translatedOUsData={translatedData}
        control={control}
        id={id}
      />
    </Grid>
  );
};

export default VaccinationSiteSelectContainer;
