import React from "react";
import {
  TextField,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { DHIS2_TYPES } from "./utils";
import { DatePicker } from "@material-ui/pickers";

import CascadeSelector from "../CascadeSelector";
import Dhis2Select from "./Dhis2Select";
import DragDropFile from "./DragDropFile";

const TypedInput = ({
  // DHIS2 data
  id,
  valueType,
  optionSet,
  // MUI data
  getOptionValue,
  getOptionLabel,
  options,
  Component,
  variant = "outlined",
  size = "small",
  fullWidth = true,
  label,
  value = null, // enabled controlled component for MUI
  onChange,
  autoComplete = "off",
  ...props
}) => {
  const { t } = useTranslation();
  if (optionSet)
    return (
      <Dhis2Select
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        id={id}
        variant={variant}
        size={size}
        fullWidth={fullWidth}
        label={label}
        options={optionSet.options}
        value={value}
        onChange={onChange}
        {...props}
      />
    );
  switch (valueType) {
    case DHIS2_TYPES["TEXT"]:
    case DHIS2_TYPES["PERCENTAGE"]:
    case DHIS2_TYPES["EMAIL"]:
    case DHIS2_TYPES.NUMBER:
      return (
        <TextField
          id={id}
          label={label}
          variant={variant}
          fullWidth={fullWidth}
          size={size}
          value={value}
          onChange={onChange}
          autoComplete={autoComplete}
          {...props}
        />
      );
    case DHIS2_TYPES["INTEGER_POSITIVE"]:
    case DHIS2_TYPES["INTEGER_NEGATIVE"]:
    case DHIS2_TYPES["INTEGER_ZERO_OR_POSITIVE"]:
    case DHIS2_TYPES["PHONE_NUMBER"]:
    case DHIS2_TYPES["INTEGER"]:
      return (
        <TextField
          id={id}
          label={label}
          variant={variant}
          fullWidth={fullWidth}
          size={size}
          value={value}
          autoComplete={autoComplete}
          onChange={(event) => {
            if (/^(\d+)?$/.test(event.target.value)) {
              onChange(event);
            }
          }}
          {...props}
        />
      );
    case DHIS2_TYPES["LONG_TEXT"]:
      return (
        <TextField
          // ref={ref}
          multiline
          id={id}
          label={label}
          variant={variant}
          fullWidth={fullWidth}
          size={size}
          value={value}
          onChange={onChange}
          autoComplete={autoComplete}
          {...props}
          // value={value || ""}
          // onChange={(event) => {
          //   change(event.target.value);
          // }}
        />
      );
    case DHIS2_TYPES["FILE_RESOURCE"]:
      return (
        <DragDropFile
          ref={props.ref}
          id={id}
          label={label}
          variant={variant}
          fullWidth={fullWidth}
          size={size}
          value={value}
          onChange={onChange}
          autoComplete={autoComplete}
          {...props}

          // value={value || ""}
          // onChange={(event) => {
          //   change(event.target.value);
          // }}
        />
      );
    case DHIS2_TYPES["DATE"]:
    case DHIS2_TYPES.AGE:
      const format = props.format || "YYYY-MM-DD";
      return (
        <DatePicker
          // ref={ref}
          // variant="inline"
          label={label}
          id={id}
          inputVariant={props.inputVariant || variant}
          variant={variant}
          // variant={"dialog"}
          fullWidth={fullWidth}
          size={size}
          clearable={props.clearable || true}
          autoOk={props.autoOk || true}
          // autoOk={false}
          okLabel={t("ok")}
          cancelLabel={t("cancel")}
          clearLabel={t("clear")}
          value={value ? moment(value, format) : value}
          onChange={(value) => onChange(value ? value.format(format) : value)}
          {...props}
        />
      );
    // case "DATETIME":
    //   return <div>hello</div>;
    // case "TIME":
    //   return <div>hello</div>;
    // case "BOOLEAN":
    //   return (
    //     <Radio.Group
    //       value={value}
    //       onChange={(event) => {
    //         change(event.target.value);
    //       }}
    //     >
    //       <Radio value="true" style={{ fontSize: "13.5px" }}>
    //         Yes
    //       </Radio>
    //       <Radio value="false" style={{ fontSize: "13.5px" }}>
    //         No
    //       </Radio>
    //     </Radio.Group>
    //   );
    case DHIS2_TYPES.VILLAGE_SELECTOR:
      return <CascadeSelector {...props} />;
    case "TRUE_ONLY":
      return (
        <FormControl required={props.required} error={props.error}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  // ref={ref}
                  // inputProps={{ "aria-label": "secondary checkbox" }}
                  // checked={antoine}
                  // onChange={handleChange}
                  id={id}
                  variant={variant}
                  color={props.color || "primary"}
                  size={size}
                  fullWidth={fullWidth}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                  checked={Boolean(value)}
                  {...props}
                />
              }
              label={label}
            />
          </FormGroup>
          {!!props.error && (
            <FormHelperText error>{props.helperText}</FormHelperText>
          )}
        </FormControl>
      );
    case DHIS2_TYPES.YES_NO:
      const innerValue = [true, false].includes(value)
        ? value.toString()
        : null;
      return (
        <FormControl component="fieldset">
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup
            name={id}
            value={innerValue}
            onChange={(e) => {
              const value = e.target.value;
              const outsideValue =
                value === "true" ? true : value === "false" ? false : null;
              onChange(outsideValue);
            }}
            {...props}
          >
            <FormControlLabel
              value="true"
              control={
                <Radio variant={variant} color={props.color || "primary"} />
              }
              label={props.yesLabel || t("yes")}
            />
            <FormControlLabel
              value="false"
              control={
                <Radio variant={variant} color={props.color || "primary"} />
              }
              label={props.noLabel || t("no")}
            />
          </RadioGroup>
          {!!props.error && (
            <FormHelperText error>{props.helperText}</FormHelperText>
          )}
        </FormControl>
      );
    // case "AGE":
    //   return (
    //     <DatePicker
    //       locale={customLocale}
    //       value={value ? moment(value) : null}
    //       onChange={(momentObject) => {
    //         change(momentObject.format("YYYY-MM-DD"));
    //       }}
    //     />
    //   );
    // case "CALENDAR":
    //   // return <Calendar value={value ? value : disabledDate[0]} fullscreen={false} disabledDate={disabledDate} />;
    //   return (
    //     <Calendar
    //       onChange={(date) => {
    //         change(moment(date).format("YYYY-MM-DD"));
    //       }}
    //       value={value ? new Date(value) : ""}
    //       formatShortWeekday={(currentLocale, date) => {
    //         const weekDays = [
    //           "ອາທິດ",
    //           "ຈັນ",
    //           "ອັງຄານ",
    //           "ພຸດ",
    //           "ພະຫັດ",
    //           "ສຸກ",
    //           "ເສົາ",
    //         ];
    //         return weekDays[date.getDay()];
    //       }}
    //       formatMonth={(currentLocale, date) => {
    //         const months = [
    //           "ມັງກອນ",
    //           "ກຸມພາ",
    //           "ມີນາ",
    //           "ເມສາ",
    //           "ພຶດສະພາ",
    //           "ມິຖຸນາ",
    //           "ກໍລະກົດ",
    //           "ສິງຫາ",
    //           "ກັນຍາ",
    //           "ຕຸລາ",
    //           "ພະຈິກ",
    //           "ທັນວາ",
    //         ];
    //         return months[date.getMonth()];
    //       }}
    //       formatMonthYear={(currentLocale, date) => {
    //         const months = [
    //           "ມັງກອນ",
    //           "ກຸມພາ",
    //           "ມີນາ",
    //           "ເມສາ",
    //           "ພຶດສະພາ",
    //           "ມິຖຸນາ",
    //           "ກໍລະກົດ",
    //           "ສິງຫາ",
    //           "ກັນຍາ",
    //           "ຕຸລາ",
    //           "ພະຈິກ",
    //           "ທັນວາ",
    //         ];
    //         return months[date.getMonth()] + " " + date.getFullYear();
    //       }}
    //       locale={currentLocale}
    //       tileDisabled={disabledDate}
    //     />
    //   );
    case DHIS2_TYPES["OTHER"]:
      return (
        <Component
          id={id}
          label={label}
          optionSet={optionSet}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          options={options}
          value={value}
          onChange={onChange}
          {...props}
        />
      );
    default:
      return <span>UNSUPPORTED VALUE TYPE {id}</span>;
  }
};

export default TypedInput;
