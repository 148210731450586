import {
  GET_ATTRIBUTES,
  GET_LATEST_ATTRIBUTES,
  GET_ATTRIBUTES_SUCCEED,
} from "./types";

export const getAttributes = (locale) => ({
  type: GET_ATTRIBUTES,
  locale,
});

export const getLatestAttributes = (locale, newAttrs) => ({
  type: GET_LATEST_ATTRIBUTES,
  locale,
  newAttrs,
});

export const getAttributesSucceed = (attributes) => ({
  type: GET_ATTRIBUTES_SUCCEED,
  attributes,
});
