import { GET_VILLAGE_HIERARCHY_SUCCEED } from "./types";
import { combineReducers } from "redux";

const villages = (state = null, action) => {
  switch (action.type) {
    case GET_VILLAGE_HIERARCHY_SUCCEED: {
      return action.villages;
    }
    default:
      return state;
  }
};

const provinces = (state = null, action) => {
  switch (action.type) {
    case GET_VILLAGE_HIERARCHY_SUCCEED: {
      return action.provinces;
    }
    default:
      return state;
  }
};

const districts = (state = null, action) => {
  switch (action.type) {
    case GET_VILLAGE_HIERARCHY_SUCCEED: {
      return action.districts;
    }
    default:
      return state;
  }
};

const hierarchy = (state = null, action) => {
  switch (action.type) {
    case GET_VILLAGE_HIERARCHY_SUCCEED: {
      return action.hierarchy;
    }
    default:
      return state;
  }
};

export default combineReducers({
  villages,
  provinces,
  districts,
  hierarchy,
});
