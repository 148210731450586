import { GET_ATTRIBUTES_SUCCEED } from "./types";

const attributes = (state = null, action) => {
  switch (action.type) {
    case GET_ATTRIBUTES_SUCCEED: {
      return action.attributes;
    }
    default:
      return state;
  }
};

export default attributes;
