import {
  OPEN_RESULT_MODAL,
  SET_RESULT_LOADING,
  SET_RESULT_ERROR,
  SET_RESULT_DATA,
} from "./types";
export
const openResultModal = (isOpen) => {
  return {
    type: OPEN_RESULT_MODAL,
    isOpen,
  };
};

export const setResultLoading = (isLoading) => ({
  type: SET_RESULT_LOADING,
  isLoading,
});

export const setResultData = (data) => ({
  type: SET_RESULT_DATA,
  data,
});

export const setResultError = (error) => ({
  type: SET_RESULT_ERROR,
  error,
});
