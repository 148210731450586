import _ from "lodash";

const withDhis2Metadata =
  (data, findFunc, getterFunc, { pickedProps = [], locale } = {}) =>
  (Component) => {
    const Dhis2Component = ({ id, ...props }) => {
      let rootItem = findFunc(data)(id);
      if (!rootItem) return id || null;

      // Main dataz
      rootItem = getterFunc(rootItem);
      const translatedItem = rootItem;
      const extendedProps = pickedProps.reduce((result, prop) => {
        let [des, source] = Array.isArray(prop) ? prop : [prop];
        source = Array.isArray(source) ? source : [source];
        result[des] = source.reduce((result, s) => {
          if (!result) {
            return translatedItem[s || des];
          }
          return result;
        }, null);
        return result;
      }, {});

      let rootProps = translatedItem;
      if (pickedProps.length) rootProps = _.pick(translatedItem, pickedProps);
      return <Component id={id} {...rootProps} {...extendedProps} {...props} />;
    };
    return Dhis2Component;
  };

export default withDhis2Metadata;
