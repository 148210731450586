import React, { useMemo } from "react";
import TypedInput from "../../common/TypedInput";
import { getOuDisplayName } from "../../utils";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import withFormController from "../../hocs/withFormController";
import get from "lodash/get";

const alphabetSort = ({ name: a }, { name: b }) =>
  get(a.split(" "), 0) - get(b.split(" "), 0) ||
  get(a.split(" "), [1, 0], "").localeCompare(get(b.split(" "), [1, 0], ""));

const VaccinationSiteSelect = ({ translatedOUsData, control, id }) => {
  const { t } = useTranslation();

  const FormControl = useMemo(
    () => withFormController({ control })(TypedInput),
    []
  );

  return (
    <>
      <Grid container spacing={3} direction="column">
        <Grid item>
          <FormControl
            id="province"
            label={t("selectProvince")}
            rules={{
              required: t("thisFieldIsRequired"),
            }}
            optionSet={{
              options:
                translatedOUsData.provinces.organisationUnits.sort(
                  alphabetSort
                ),
            }}
            getOptionValue={(option) => option.id}
            getOptionLabel={getOuDisplayName}
          />
        </Grid>
        <FormControl
          // Form Control Props
          ParentComponent={Grid}
          ParentComponentProps={{
            item: true,
          }}
          id={id}
          label={t("selectVaccinationSite")}
          rules={{
            required: t("thisFieldIsRequired"),
          }}
          dependentFields="province"
          onDependentChangedRenderer={(province) => !!province}
          onDependentChangedProps={(selectedProvince) => ({
            optionSet: {
              options: translatedOUsData.organisationUnits.organisationUnits
                .filter(({ province }) => province === selectedProvince)
                .sort(alphabetSort),
            },
          })}
          optionSet={{
            options: [],
          }}
          getOptionValue={(option) => option.id}
          getOptionLabel={getOuDisplayName}
        />
      </Grid>
    </>
  );
};

export default VaccinationSiteSelect;
