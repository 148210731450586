import React, { useEffect } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormHelperText } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

const Dhis2Select = ({
  variant,
  size,
  fullWidth,
  label,
  id,
  options,
  getOptionValue = (option) => option.code,
  getOptionLabel = (option) => option.name,
  ...props
}) => {
  useEffect(() => {
    if (options.every((option) => getOptionValue(option) !== props.value)) {
      if (props.value !== null) props.onChange(null);
    }
  }, [options]);
  return (
    <FormControl
      error={props.error}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
    >
      <InputLabel
        error={props.error}
        required={props.required}
        variant={variant}
      >
        {label}
      </InputLabel>
      <Select label={label} variant={variant} id={id} {...props}>
        {options.map((option) => {
          const value = getOptionValue ? getOptionValue(option) : option.code;
          const label = getOptionLabel ? getOptionLabel(option) : option.name;
          return (
            <MenuItem id={value} value={value} key={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
      {props.error && <FormHelperText error>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Dhis2Select;
