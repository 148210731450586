import React, { useState } from "react";
import { AppBar, Drawer, Grid, IconButton, List, ListItem, Toolbar, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useTranslation } from "react-i18next";
import Logo from "./Logo";
import { Link } from "react-router-dom";

import Divider from "@material-ui/core/Divider";
import LanguageSelect from "../LanguageSelect";

const MobileMenu = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpen(open);
  };
  return (
    <AppBar elevation={0} position="sticky">
      <Toolbar>
        <Grid wrap="nowrap" container justify="flex-start" alignItems="center">
          <Grid item>
            <React.Fragment>
              <IconButton onClick={toggleDrawer(true)} edge="start" color="inherit" aria-label="menu">
                <MenuIcon />
              </IconButton>
              <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
                <List>
                  <ListItem onClick={toggleDrawer(false)} component={Link} to="/cvr" color="inherit">
                    {t("menuRegister")}
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListItem onClick={toggleDrawer(false)} component={Link} to="/guide" color="inherit">
                    {t("menuVaccinationGuide")}
                  </ListItem>
                </List>
                <Divider />
                {/* <List>
                  <ListItem
                    onClick={toggleDrawer(false)}
                    component={Link}
                    to="/status"
                    color="inherit"
                  >
                    {t("menuCheckStatus")}
                  </ListItem>
                </List>
                <Divider /> */}
                <List>
                  <ListItem onClick={toggleDrawer(false)} component={Link} to="/generate-certificate" color="inherit">
                    {t("generateCertificate")}
                  </ListItem>
                </List>
                <List>
                  <ListItem onClick={toggleDrawer(false)} component={Link} to="/verify-certificate" color="inherit">
                    {t("verify")}
                  </ListItem>
                </List>
                <List>
                  <ListItem
                    onClick={toggleDrawer(false)}
                    component={Link}
                    to="/international-certificate"
                    color="inherit"
                  >
                    {t("internationalCertificate")}
                  </ListItem>
                </List>
                <List>
                  <ListItem onClick={toggleDrawer(false)} component={Link} to="/lao-dp" color="inherit">
                    {t("laoDP")}
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListItem color="inherit">
                    <LanguageSelect closeDrawer={toggleDrawer(false)} />
                  </ListItem>
                </List>
              </Drawer>
            </React.Fragment>
          </Grid>
          <Grid item>
            <Logo />
          </Grid>
          <Grid item>
            <Typography variant="body2">{t("title")}</Typography>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export default MobileMenu;
