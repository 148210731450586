import React, { memo, useCallback, useEffect, useState } from "react";
import SingleInput from "./SingleInput";
import {
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
} from "@material-ui/core";

const TYPES = {
  INPUT: "INPUT",
  DIVIDER: "DIVIDER",
};

const inputConfigs = [
  {
    type: TYPES.INPUT,
    TextFieldProps: {
      inputProps: {
        inputMode: "numeric",
      },
      InputProps: {
        startAdornment: <InputAdornment position="start">H</InputAdornment>,
      },
      style: {
        width: 100,
      },
    },
    pattern: /^\d{4}$/,
    maxLength: 4,
  },
  { type: TYPES.DIVIDER, value: "-", pattern: /^-$/, maxLength: 1 },
  {
    type: TYPES.INPUT,
    maxLength: 1,
    TextFieldProps: {
      style: {
        width: 40,
      },
    },
    pattern: /^[A-Z]$/,
  },
  { type: TYPES.DIVIDER, value: "-", pattern: /^-$/, maxLength: 1 },
  {
    type: TYPES.INPUT,
    TextFieldProps: {
      style: {
        width: 100,
      },
    },
    pattern: /^(\d|[A-Z])\d{4}$/,
  },
];

export function OTPInputComponent({
  disabled,
  onChange: onChangeOTP,
  inputClassName,
  inputStyle,
  label,
  variant,
  size,
  fullWidth,
  required,
  error,
  helperText,
  value: initialValue,
  ...props
}) {
  const length = inputConfigs.length;
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(
    Array(inputConfigs.length)
      .fill()
      .map((_, index) => {
        const { value } = inputConfigs[index];
        return value || "";
      })
  );

  // Helper to return OTP from inputs

  const handleOtpChange = useCallback(
    (otp) => {
      otp = otp.map((e) => {
        if (!e) e = "-";
        return e;
      });
      const otpValue = otp.join("");
      onChangeOTP("H" + otpValue);
      // updateOTPValues("H" + otpValue);
    },
    [onChangeOTP]
  );

  // Helper to return value with the right type: 'text' or 'number'
  /*const getRightValue = useCallback(
    (str) => {
      let changedValue = str;
      if (!isNumberInput) {
        return changedValue;
      }
      return !changedValue || /\d/.test(changedValue) ? changedValue : "";
    },
    [isNumberInput]
  );*/

  const getRightValue = useCallback((str, index) => {
    return !str || inputConfigs[index].pattern.test(str) ? str : "";
  }, []);

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str || "";
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    const prevInputIndexes = inputConfigs
      .map((config, index) => {
        return { ...config, index };
      })
      .filter(({ type }, index) => {
        if (type === TYPES.DIVIDER) return false;
        if (index >= activeInput) return false;
        return true;
      });
    if (prevInputIndexes.length) {
      focusInput(prevInputIndexes[prevInputIndexes.length - 1].index);
    }
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    const nextInputIndex = inputConfigs.findIndex(({ type }, index) => {
      if (type === TYPES.DIVIDER) return false;
      if (index <= activeInput) return false;
      return true;
    });
    if (nextInputIndex >= 0) {
      focusInput(nextInputIndex);
    }
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e) => {
      const val = e.currentTarget.value.toUpperCase();
      changeCodeAtFocus(val);
      const config = inputConfigs[activeInput];
      if (val.length >= config.maxLength) {
        focusNextInput();
      }
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  );

  // Hanlde onBlur input
  const onBlur = useCallback((e) => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e) => {
      switch (e.key) {
        case "Backspace": {
          if (!otpValues[activeInput]) {
            e.preventDefault();
            focusPrevInput();
          }
          break;
        }
        /*case "ArrowLeft": {
            e.preventDefault();
            focusPrevInput();
            break;
          }
          case "ArrowRight": {
            e.preventDefault();
            const value = otpValues[activeInput];
            if (!value) {
              focusNextInput();
            }
            break;
          }*/
        case " ": {
          e.preventDefault();
          break;
        }
        default:
          break;
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    (e) => {
      e.preventDefault();
      const pastedData = e.clipboardData.getData("text/plain");
      updateOTPValues(pastedData);
    },
    [activeInput, getRightValue, length, otpValues]
  );

  const updateOTPValues = (value = "") => {
    if (value && activeInput === 0 && value[0] === "H") {
      value = value.slice(1);
    }
    if (value) {
      const updatedOTPValues = [];
      let newActiveInput = activeInput;
      inputConfigs.map((config, index) => {
        if (index < activeInput) return;
        const startIndex = inputConfigs
          .slice(activeInput, index)
          .reduce((result, config) => (result += config.maxLength), 0);
        const changedValue = getRightValue(
          value.slice(
            startIndex,
            config.maxLength ? startIndex + config.maxLength : undefined
          ),
          index
        );
        if (changedValue) {
          updatedOTPValues[index] = changedValue;
          newActiveInput = index;
        }
      });
      setOTPValues(updatedOTPValues);
      setActiveInput(newActiveInput);
      handleOtpChange(updatedOTPValues);
    }
  };

  useEffect(() => {
    updateOTPValues(initialValue);
  }, []);

  useEffect(() => {
    // For reset value
    if (!initialValue) {
      const updatedOTPValues = Array(inputConfigs.length)
        .fill()
        .map((_, index) => {
          const { value } = inputConfigs[index];
          return value || "";
        });
      setOTPValues(updatedOTPValues);
      // handleOtpChange(updatedOTPValues);
    }
  }, [initialValue]);

  return (
    <FormControl
      error={error}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      required={required}
      {...props}
    >
      <FormLabel required={required} error={error}>
        {label}
      </FormLabel>
      <Box mb={1} />
      <FormGroup>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          {inputConfigs.map(({ type, TextFieldProps }, index) => {
            switch (type) {
              case TYPES.DIVIDER:
                return <Grid item>-</Grid>;
              case TYPES.INPUT:
                return (
                  <Grid item>
                    <SingleInput
                      key={`SingleInput-${index}`}
                      focus={activeInput === index}
                      value={otpValues && otpValues[index]}
                      onFocus={handleOnFocus(index)}
                      onChange={handleOnChange}
                      onKeyDown={handleOnKeyDown}
                      onBlur={onBlur}
                      onPaste={handleOnPaste}
                      style={inputStyle}
                      className={inputClassName}
                      disabled={disabled}
                      error={error}
                      {...TextFieldProps}
                    />
                  </Grid>
                );
              default:
                return null;
            }
          })}
        </Grid>
      </FormGroup>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const OTPInput = memo(OTPInputComponent);
export default OTPInput;
