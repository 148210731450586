import { GET_VILLAGE_HIERARCHY, GET_VILLAGE_HIERARCHY_SUCCEED } from "./types";

export const getVillageHierarchy = (locale) => ({
  type: GET_VILLAGE_HIERARCHY,
  locale,
});

export const getVillageHierarchySucceed = ({
  villages,
  provinces,
  districts,
  hierarchy,
}) => ({
  type: GET_VILLAGE_HIERARCHY_SUCCEED,
  villages,
  provinces,
  districts,
  hierarchy,
});
