import React, { useEffect, useRef, useState } from "react";
import VerifyInternalDoseDialog from "./VerifyInternalDoseDialog";
import {
  checkStatusForBoosterDose,
  checkStatusForSecondDose,
} from "../../services/api";
import { toServerMobileValue } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import {
  openInternal2ndSchedulingModal,
  openInternalFirstDoseVerifyModal,
  setVerifyInternal1stDialogLoading,
  setVerifyInternal1stDoseData,
  setVerifyInternal1stError,
} from "../../redux/reducers/cvr/internalFirstDose/actions";
import { useTranslation } from "react-i18next";
import { setResultError } from "../../redux/reducers/cvr/result/actions";
import { DOSE_TYPES } from "../../common/TypedInput/DoseSelectRadioGroup";
import {
  setIsInternalPreSVR,
  setRegistrationDose,
} from "../../redux/reducers/cvr/actions";
import { useForm } from "react-hook-form";

const VerifyInternalDoseDialogContainer = () => {
  const form = useForm({
    mode: "onChange",
  });
  const submitInProcessing = useRef(false);
  const { i18n, t } = useTranslation();
  const open = useSelector(
    (state) => state.cvr.internalFirstDose.isOpenInternal1stDoseVerifyModal
  );
  const doseNumber = useSelector((state) => state.cvr.doseNumber);
  const error = useSelector((state) => state.cvr.internalFirstDose.error);
  const isLoading = useSelector(
    (state) => state.cvr.internalFirstDose.isLoading
  );
  const dispatch = useDispatch();
  // const [showBoosterDoseButton, setShowBoosterDoseButton] = useState(false);
  // const [switchLoading, setSwitchLoading] = useState(false);
  // const [resultMessage, setResultMessage] = useState("");
  const [showProceedButton, setShowProceedButton] = useState(false);

  const onCancel = () => {
    dispatch(setVerifyInternal1stError(null));
    // dispatch(setRegistrationDose(null));
    dispatch(openInternalFirstDoseVerifyModal(false));
    dispatch(setIsInternalPreSVR(null));
  };

  const onSuccess = (res, values) => {
    if (res.status) {
      dispatch(setVerifyInternal1stDoseData(values));
      dispatch(openInternalFirstDoseVerifyModal(false));
      dispatch(openInternal2ndSchedulingModal(true));
    } else {
      dispatch(openInternalFirstDoseVerifyModal(false));
      /*dispatch(setVerifyInternal1stError(res.message));*/
      dispatch(setResultError(res.message));
    }
  };

  const onNotFound = () => {
    setShowProceedButton(true);
    dispatch(setVerifyInternal1stError(t("registerAgain")));
  };

  const onError = (res) => {
    try {
      res.json().then((res) => {
        dispatch(openInternalFirstDoseVerifyModal(false));
        dispatch(setResultError(res.message));
      });
    } catch (e) {
      dispatch(openInternalFirstDoseVerifyModal(false));
      dispatch(setResultError(t("unknownServerError")));
    }
  };

  const onSubmit = (values) => {
    submitInProcessing.current = false;
    // const getEndPoint = () => {
    //   const mapping = {
    //     [DOSE_TYPES.SECOND]: checkStatusForSecondDose,
    //     [DOSE_TYPES.BOOSTER]: checkStatusForBoosterDose,
    //   };
    //   return mapping[doseNumber];
    // };
    const {
      tQeFLjYbqzv: dob,
      corXnplgfQ7: CVID,
      ciCR6BBvIT4: phoneNumber,
    } = values;
    dispatch(setVerifyInternal1stError(null));
    dispatch(setVerifyInternal1stDialogLoading(true));
    const payload = {
      CVID,
      phoneNumber: phoneNumber ? toServerMobileValue(phoneNumber) : "",
      locale: i18n.language,
      dob: dob || "",
    };
    checkStatusForSecondDose({
      CVID,
      phoneNumber: phoneNumber ? toServerMobileValue(phoneNumber) : "",
      locale: i18n.language,
      dob: dob || "",
    })
      .then((res) => onSuccess(res, values))
      .catch((res) => {
        switch (res.status) {
          // 406: 1 dose type:Eligible for booster dose, not eligible for second dose
          // 413: vaccinated
          case 406:
          case 413: {
            dispatch(setRegistrationDose(DOSE_TYPES.BOOSTER));
            checkStatusForBoosterDose(payload)
              .then((res) => onSuccess(res, values))
              .catch((res) => {
                switch (res.status) {
                  case 404: {
                    onNotFound();
                    break;
                  }
                  default: {
                    onError(res);
                  }
                }
              });
            break;
          }
          // Not found
          case 404: {
            onNotFound();
            break;
          }
          default: {
            onError(res);
          }
        }
      })
      .finally(() => {
        dispatch(setVerifyInternal1stDialogLoading(false));
      });
  };

  useEffect(() => {
    if (doseNumber === DOSE_TYPES.BOOSTER && submitInProcessing.current) {
      form.handleSubmit(onSubmit)();
    }
  }, [doseNumber]);

  return (
    <VerifyInternalDoseDialog
      open={open}
      onCancel={onCancel}
      onSubmit={onSubmit}
      errorMessage={error}
      isLoading={isLoading}
      // showBoosterDoseButton={showBoosterDoseButton}
      // switchLoading={switchLoading}
      // resultMessage={resultMessage}
      // onBoosterDoseClick={() => {
      //   dispatch(setRegistrationDose(DOSE_TYPES.BOOSTER));
      //   setShowBoosterDoseButton(false);
      //   dispatch(setVerifyInternal1stError(null));
      //   submitInProcessing.current = true;
      //   // Then submit -> see useEffect
      // }}
      showProceedButton={showProceedButton}
      onProceedButtonClick={() => {
        dispatch(setIsInternalPreSVR(false));
        dispatch(openInternalFirstDoseVerifyModal(false));
        dispatch(setVerifyInternal1stError(null));
        setShowProceedButton(false);
      }}
      form={form}
    />
  );
};

export default VerifyInternalDoseDialogContainer;
