import { GET_DATA_ELEMENTS_SUCCEED } from "./types";

const attributes = (state = null, action) => {
  switch (action.type) {
    case GET_DATA_ELEMENTS_SUCCEED: {
      return action.dataElements;
    }
    default:
      return state;
  }
};

export default attributes;
