import {
  Box,
  Chip,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";

import { useTranslation } from "react-i18next";
import moment from "moment";
const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor: green[500]
  },
  noBordered: {
    display: "inline-block",
    border: "none",
    paddingRight: "0 !important",
    paddingLeft: "0 !important",
    "& strong": {
      display: "block",
      whiteSpace: "noWrap"
    }
  },
  equaledTableCell: {
    width: "50%"
  }
});
const StyledTableCell = (props) => {
  const { noBordered } = useStyles();
  return (
    <TableCell
      classes={{
        root: noBordered
      }}
      {...props}
    />
  );
};

const EqualledTableCell = (props) => {
  const { equaledTableCell } = useStyles();
  return (
    <TableCell
      classes={{
        root: equaledTableCell
      }}
      {...props}
    />
  );
};

const LocalInformation = ({ fullName, dateOfBirth, p, dopi, s, ci, ma, dn, dt, commonName }) => {
  const { t } = useTranslation();
  return [
    <Box mb={3}>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <StyledTableCell align="right">
                <strong>{t("name")} :&nbsp;</strong>
              </StyledTableCell>
              <StyledTableCell align="left">{fullName}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell align="right">
                <strong>{t("dateOfBirth")} :&nbsp;</strong>
              </StyledTableCell>
              <StyledTableCell align="left">{moment(dateOfBirth, "YYYY-MM-DD").format("DD/MM/YYYY")}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell align="right">
                <strong>{t("uniqueCertificateIdentifier")} :&nbsp;</strong>
              </StyledTableCell>
              <StyledTableCell align="left">{ci}</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>,
    <Box px={3}>
      <Typography>
        <strong>{t("vaccinationCertificate")}</strong>
      </Typography>
      <Box mt={2} />
      <TableContainer
        borderRadius="borderRadius"
        component={Box}
        border={1}
        borderColor={grey[300]}
        maxWidth={900}
        mx="auto"
      >
        <Table size="small">
          <TableBody>
            <TableRow>
              <EqualledTableCell align="right">
                <strong>{t("vaccineAgainst")}</strong>
              </EqualledTableCell>
              <EqualledTableCell align="left">COVID19</EqualledTableCell>
            </TableRow>
            <TableRow>
              <EqualledTableCell align="right">
                <strong>{t("vaccineName")}</strong>
              </EqualledTableCell>
              <EqualledTableCell align="left">{ma}</EqualledTableCell>
            </TableRow>
            <TableRow>
              <EqualledTableCell align="right">
                <strong>{t("vaccineDoseNumber")}</strong>
              </EqualledTableCell>
              <EqualledTableCell align="left">{dn}</EqualledTableCell>
            </TableRow>
            <TableRow>
              <EqualledTableCell align="right">
                <strong>{t("dateOfVaccination")}</strong>
              </EqualledTableCell>
              <EqualledTableCell align="left">{moment(dt, "YYYY-MM-DD").format("DD/MM/YYYY")}</EqualledTableCell>
            </TableRow>
            <TableRow>
              <EqualledTableCell align="right">
                <strong>{t("vaccinatedAt")}</strong>
              </EqualledTableCell>
              <EqualledTableCell align="left">Laos</EqualledTableCell>
            </TableRow>
            <TableRow>
              <EqualledTableCell align="right">
                <strong>{t("certificateIssuer")}</strong>
              </EqualledTableCell>
              <EqualledTableCell>{commonName}</EqualledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ];
};

export default LocalInformation;
