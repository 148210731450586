import React from "react";
import { Box, Container, Divider, Grid, Typography } from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";
import grey from "@material-ui/core/colors/grey";
import logo from "../../assets/images/LOGO_HISPVN_Vertical-300x177.png";

const TermOfUsePrivacyPolicy = () => {
  return (
    <>
      <Box py={4} bgcolor={blue[800]} color="white">
        <Container maxWidth="md" component="div">
          <Typography component="h1" variant="h4">
            <strong>TERMS OF USE & PRIVACY POLICY</strong>
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="md" component={"div"}>
        <Box p={4} pt={4} pb={10} bgcolor={grey[100]}>
          <Typography component="h2" variant="subtitle1">
            <strong>Term of use and privacy policy</strong>
          </Typography>
          <Typography component="p" variant="subtitle2">
            Lao Covid – Digital Proof (DP) is a free mobile app which users can download on their android or iOS device.
            Lao Covid-DP is developed in coordination with the Department of Planning and Cooperation (DPC), Ministry of
            Health, Lao PDR and WHO Lao Team members. Lao Covid – DP app is developed by HISP Vietnam Company Limited.
            All the covid vaccination data entered by authorised health staff and data is managed and maintained by
            Department of Planning and Cooperation (DPC), Ministry of Health, Lao PDR.
          </Typography>
          <br />
          <Typography component="h2" variant="subtitle1">
            <strong>Purpose of Laos DP:</strong>
          </Typography>
          <Typography component="p" variant="subtitle2">
            The aim of Lao Covid-DP is to allow users to download their covid digital proof into their mobile device,
            which can be presented to show their status of Covid vaccination and to verify covid vaccination digital
            proof. Lao Covid-DP also allows users to book appointments using (https://vaccinatelaos.la/) for their next
            covid vaccination in designated covid vaccination centres. The covid vaccination appointment booking system
            is a website which is embedded into Lao Covid-DP mobile application for easy access for users. Data entered
            in the booking system is not stored in Lao Covid-DP mobile application.
          </Typography>
          <br />
          <Typography component="h2" variant="subtitle1">
            <strong>Privacy Policy</strong>
          </Typography>
          <Typography component="p" variant="subtitle2">
            HISP Vietnam built the Laos Covid DP app as a Free app. This SERVICE is provided by HISP Vietnam at no cost
            and is intended for use as is.
          </Typography>
          <Typography component="p" variant="subtitle2">
            This page is used to inform visitors regarding our policies with the collection, use, and disclosure of
            Personal Information if anyone decided to use our Service.
          </Typography>
          <Typography component="p" variant="subtitle2">
            Lao Covid-DP apps does not store any personal information data on the server. All the data is stored in
            users mobile device which is used to provide status of users covid vaccination. Lao covid-DP allows users to
            access their covid vaccination information which is entered and verified by authorised health staff of the
            Ministry of Health, Lao PDR. All the data is managed and maintained by the Department of Planning and
            Cooperation (DPC), Ministry of Health, Lao PDR. Application developer agency will and is not allowed to
            collect and use user information for any purpose. And will not disclose any information that may harm or do
            any illegal behavior about user privacy. Lao Covid-DP mobile application uses camera for two purposes:
            <ol>
              <li>
                To store photo identification in their mobile devices which is used for presenting covid vaccination
                status. All your information will be store in your devices only.
              </li>
              <li>To scan QR code to verify Covid vaccination digital proof. </li>
            </ol>
          </Typography>
          <Typography component="p" variant="subtitle2">
            The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are
            accessible at Laos Covid DP unless otherwise defined in this Privacy Policy.
          </Typography>
          <br />
          <Typography component="h2" variant="subtitle1">
            <strong>Information Collection and Use</strong>
          </Typography>
          <Typography component="p" variant="subtitle2">
            The app does use third-party services that may collect information used to identify you.
          </Typography>
          <Typography component="p" variant="subtitle2">
            Link to the privacy policy of third-party service providers used by the app
            <ul>
              <li>
                <a href="https://policies.google.com/privacy" target="_blank">
                  Google Play Services
                </a>
              </li>
            </ul>
          </Typography>
          <br />
          <Typography component="h2" variant="subtitle1">
            <strong>Cookies</strong>
          </Typography>
          <Typography component="p" variant="subtitle2">
            Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These
            are sent to your browser from the websites that you visit and are stored on your device’s internal memory.
          </Typography>
          <Typography component="p" variant="subtitle2">
            This Service does not use these “cookies” explicitly. However, the app may use third-party code and
            libraries that use “cookies” to collect information and improve their services. You have the option to
            either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to
            refuse our cookies, you may not be able to use some portions of this Service.
          </Typography>
          <Typography component="h2" variant="subtitle1">
            <strong>Service Providers</strong>
          </Typography>
          <Typography component="p" variant="subtitle2">
            We may employ third-party companies and individuals due to the following reasons:
            <ul>
              <li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li>
              <li>To perform Service-related services; or</li>
              <li>To assist us in analyzing how our Service is used.</li>
            </ul>
          </Typography>
          <Typography component="p" variant="subtitle2">
            We want to inform users of this Service that these third parties have access to their Personal Information.
            The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to
            disclose or use the information for any other purpose.
          </Typography>
          <br />
          <Typography component="h2" variant="subtitle1">
            <strong>Security</strong>
          </Typography>
          <Typography component="p" variant="subtitle2">
            We value your trust in providing us your Personal Information, thus we are striving to use commercially
            acceptable means of protecting it. But remember that no method of transmission over the internet, or method
            of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
          </Typography>
          <br />
          <Typography component="h2" variant="subtitle1">
            <strong>Links to Other Sites</strong>
          </Typography>
          <Typography component="p" variant="subtitle2">
            This Service may contain links to other sites. If you click on a third-party link, you will be directed to
            that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to
            review the Privacy Policy of these websites. We have no control over and assume no responsibility for the
            content, privacy policies, or practices of any third-party sites or services.
          </Typography>
          <br />
          <Typography component="h2" variant="subtitle1">
            <strong>Children’s Privacy</strong>
          </Typography>
          <Typography component="p" variant="subtitle2">
            These Services do not address anyone under the age of 13. We do not knowingly collect personally
            identifiable information from children under 13 years of age. In the case we discover that a child under 13
            has provided us with personal information, we immediately delete this from our servers. If you are a parent
            or guardian and you are aware that your child has provided us with personal information, please contact us
            so that we will be able to do the necessary actions.
          </Typography>
          <br />
          <Typography component="h2" variant="subtitle1">
            <strong>Changes to This Privacy Policy</strong>
          </Typography>
          <Typography component="p" variant="subtitle2">
            We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically
            for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.
          </Typography>
          <Typography component="p" variant="subtitle2">
            This policy is effective as of 2021-11-25
          </Typography>
          <br />
          <Typography component="h2" variant="subtitle1">
            <strong>Laos DP application development Team.</strong>
          </Typography>
          <Typography component="p" variant="subtitle2">
            Website:{" "}
            <a href="https://vaccinatelaos.la/" target="_blank">
              https://vaccinatelaos.la/
            </a>
          </Typography>
          <br />
          <Typography component="h2" variant="subtitle1">
            <strong>Contact email of DPC HID team</strong>
          </Typography>
          <Typography component="p" variant="subtitle2">
            <a href="mailto:laohmis@gmail.com">laohmis@gmail.com</a>
          </Typography>
          <br />
          <Typography component="h2" variant="subtitle1">
            <strong>Contact Us</strong>
          </Typography>
          <Typography component="p" variant="subtitle2">
            If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at{" "}
            <a href="mailto:contact@hisp.vn">contact@hisp.vn</a>.
          </Typography>
        </Box>
      </Container>
      <Divider variant="fullWidth" component="div" />
      <Box py={3}>
        <Container component="div" maxWidth="md">
          <Grid alignItems="center" container justify="space-between">
            <Grid xs={12} md={4} item>
              <img src={logo} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography component="h2" variant="subtitle1">
                HISP Vietnam
              </Typography>
              <Typography component="h2" variant="subtitle2">
                Address: No 41-TM, Block C, Florita Residence, Road D4, Tan Hung Ward, District 7, Ho Chi Minh City
              </Typography>
              <Typography component="h2" variant="subtitle2">
                <div>
                  Tel: <a href="tel:+842837732888">+84-28-3773 2 888</a>
                </div>
                <div>
                  Email: <a href="mailto:contact@hisp.vn">contact@hisp.vn</a>
                </div>
                <div>
                  Hotline: <a href="tel:+84916062680">+84 916 062 680</a>
                </div>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default TermOfUsePrivacyPolicy;
