import React, { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const withFormController =
  ({ control }) =>
  (Component) => {
    const Dhis2FormController = ({
      id, // for control field
      dependentFields = null,
      onDependentChangedRenderer = null,
      onDependentChangedProps = null,
      onDependentChangedControllerProps = null,
      children,
      rules = {},
      shouldUnregister = true,
      ParentComponent = React.Fragment,
      ParentComponentProps = {},
      defaultValue,
      ...props
    }) => {
      const dependentValues = useWatch({
        control,
        name: dependentFields, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
        // defaultValue: 'default' // default value before the render
      });
      const [childProps, setChildProps] = useState({});
      const [controllerProps, setControllerProps] = useState({});
      const [isRenderer, setIsRenderer] = useState(true);
      useEffect(
        () => {
          if (dependentFields) {
            if (onDependentChangedProps) {
              const newChildProps =
                onDependentChangedProps(dependentValues) || childProps;
              setChildProps(newChildProps);
            }
            if (onDependentChangedRenderer) {
              const isRender = onDependentChangedRenderer(dependentValues);
              setIsRenderer(isRender);
            }

            if (onDependentChangedControllerProps) {
              const newControllerProps =
                onDependentChangedControllerProps(dependentValues) ||
                controllerProps;
              setControllerProps(newControllerProps);
            }
          }
        },
        Array.isArray(dependentValues) ? dependentValues : [dependentValues]
      );
      if (!isRenderer) return null;
      if (!id) {
        return React.Children.only(React.cloneElement(children, childProps));
      }
      return (
        <Controller
          name={id}
          control={control}
          rules={rules}
          shouldUnregister={shouldUnregister}
          defaultValue={defaultValue}
          render={({ field: { ref, ...field }, formState }) => {
            return (
              <ParentComponent {...ParentComponentProps}>
                {Component ? (
                  <Component
                    id={id}
                    inputRef={ref}
                    {...field}
                    required={rules.required}
                    inputProps={{
                      required: false,
                      ...(props.inputProps || {}),
                    }}
                    error={!!formState.errors[id]}
                    helperText={
                      <ErrorMessage errors={formState.errors} name={id} />
                    }
                    {...props}
                    {...childProps}
                    data-scroll-id={id}
                  />
                ) : null}
              </ParentComponent>
            );
          }}
          {...controllerProps}
        />
      );
    };
    return Dhis2FormController;
  };

export default withFormController;
