import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  Typography,
} from "@material-ui/core";
import orange from "@material-ui/core/colors/orange";
import CircularProgress from "@material-ui/core/CircularProgress";
import withDhis2Metadata from "../../hocs/withDhis2Metadata";
import TypedInput from "../../common/TypedInput";
import { DHIS2_TYPES } from "../../common/TypedInput/utils";
import { bindCeateOptions, generateUid } from "../../utils";
import { compose } from "redux";
import { useForm } from "react-hook-form";
import withFormController from "../../hocs/withFormController";
import moment from "moment";
import { useTranslation } from "react-i18next";
import VaccinationSiteSelectContainer from "./VaccinationSiteSelect.container";
import VaccinationSlotButtonSelectContainer from "./VaccinationSlotButtonSelect.container";
import CVIDInput from "./CVIDInput";
import ScheduledDateSelectContainer from "./ScheduledDateSelect.container";
import NewSimpleCaptcha from "./NewSimpleCaptcha";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsInternalPreSVR,
  setRegistrationDose,
} from "../../redux/reducers/cvr/actions";
import NationalityField from "./NationalityField";
import DoseSelectRadioGroup, {
  DOSE_TYPES,
} from "../../common/TypedInput/DoseSelectRadioGroup";
import { openInternalFirstDoseVerifyModal } from "../../redux/reducers/cvr/internalFirstDose/actions";
import { PHONE_PREFIX } from "../../constants/global";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

//TODO: translation

// const defaultValues = {
//   tQeFLjYbqzv: null,
//   oZiX5uHkacg: null,
//   ScsGETliG8f: null,
//   BaiVwt8jVfg: null,
//   BfNZcj99yz4: null,
//   CBAs12YL4g7: null,
//   DmuazFb368B: null,
//   EHI7zIc1hnf: null,
//   ENRjVGxVL6l: null,
//   Elf0MHK59bj: null,
//   Ewi7FUfcHAD: null,
//   Kk3Z0jSAkQQ: null,
//   LOU9t0aR0z7: null,
//   LY2bDXpNvS7: null,
//   M8AtepTiBVi: null,
//   NqKnaoG9qg4: null,
//   PjOMM53YTsR: null,
//   TT1h0vGu5da: null,
//   UDPhgoVbjpM: null,
//   VcfifxxG4im: null,
//   Z1rLc1rVHK8: null,
//   bCtWZGjSWM8: null,
//   cb3FwcKjPz5: null,
//   ciCR6BBvIT4: null,
//   dpyQUtizp7s: null,
//   enrUid: null,
//   eventUid: null,
//   jFZFXOCI9he: null,
//   oWdg1zxTWrE: null,
//   rn2HaLfSHk6: null,
//   sB1IHYu2xQT: null,
//   teiUid: null,
//   uG58XyIlYFr: null,
//   vaccinationSite: null,
//   xsErJKvstJ7: null,
//   yGn30kRQbys: null,
// };

const CVRForm = ({ onSubmit, loading }) => {
  const attributes = useSelector((state) => state.metadata.attributes);
  const dataElements = useSelector((state) => state.metadata.dataElements);
  const hierarchy = useSelector(
    (state) => state.metadata.villageHierarchy.hierarchy
  );
  const { t } = useTranslation();
  const { control, handleSubmit, setValue, getValues } = useForm({
    mode: "onChange",
    // defaultValues: {
    //   tQeFLjYbqzv: null,
    //   oZiX5uHkacg: null,
    //   ScsGETliG8f: null,
    //   QVAquUnX3GC: false,
    // },
  });
  // const [attributes, setAttributes] = useState(attributes);
  const [captchaValue, setCaptchaValue] = useState("");
  const [rootCaptchaValue, setRootCaptchaValue] = useState("");
  const [captchaMessage, setCaptchaMessage] = useState("");
  const createOptions = bindCeateOptions(hierarchy);
  const [isOutsideCountry, setIsOutsideCountry] = useState(null);

  // useEffect(() => {
  //   setAttributes(recursiveTranslate("lo")(rootAttributes));
  // }, []);

  // const resetForm = () => {
  //   // reset(defaultValues);
  //   setValue("enrUid", generateUid());
  //   setValue("eventUid", generateUid());
  //   setValue("teiUid", generateUid());
  //   setCaptchaValue(false);
  // };

  // useEffect(() => {
  //   if (openResult) {
  //     resetForm();
  //   }
  // }, [openResult]);

  const { enrUid, eventUid, teiUid, KCBX3fcCYdu, bJshc2zcNOf } = useMemo(() => {
    return {
      enrUid: generateUid(),
      eventUid: generateUid(),
      teiUid: generateUid(),
      KCBX3fcCYdu: true,
      bJshc2zcNOf: "pending",
    };
  });

  const AttributeDhis2FormInput = useMemo(
    () =>
      compose(
        withDhis2Metadata(
          attributes,
          (metadata) => (id) => {
            return metadata.programTrackedEntityAttributes.find(
              ({ trackedEntityAttribute: { id: attrId } }) => {
                return attrId === id;
              }
            );
          },
          (item) => item.trackedEntityAttribute,
          {
            pickedProps: [
              "id",
              ["label", ["formName", "name"]],
              "optionSet",
              "valueType",
            ],
          }
        ),
        withFormController({ control })
      )(TypedInput),
    [attributes]
  );

  const NationalityDhis2FormInput = useMemo(
    () =>
      compose(
        withDhis2Metadata(
          attributes,
          (metadata) => (id) => {
            return metadata.programTrackedEntityAttributes.find(
              ({ trackedEntityAttribute: { id: attrId } }) => {
                return attrId === id;
              }
            );
          },
          (item) => item.trackedEntityAttribute,
          {
            pickedProps: [
              "id",
              ["label", ["formName", "name"]],
              "optionSet",
              "valueType",
            ],
          }
        ),
        withFormController({ control })
      )(NationalityField),
    [attributes]
  );

  const FormController = useMemo(() => withFormController({ control })(), []);

  const FormInput = useMemo(
    () => withFormController({ control })(TypedInput),
    []
  );

  const DataElementFormInput = useMemo(
    () =>
      compose(
        withDhis2Metadata(
          dataElements,
          (metadata) => (id) => {
            return metadata.programStageDataElements.find(
              ({ dataElement: { id: attrId } }) => {
                return attrId === id;
              }
            );
          },
          (item) => item.dataElement,
          {
            pickedProps: [
              "id",
              ["label", ["formName", "name"]],
              "valueType",
              "optionSet",
            ],
          }
        ),
        withFormController({ control })
      )(TypedInput),
    [dataElements]
  );

  const onError = (errors) => {
    let firstErrorElement = document.querySelector(
      `[data-scroll-id=${Object.keys(errors)[0]}]`
    );
    if (firstErrorElement)
      firstErrorElement.scrollIntoView({ behavior: `smooth`, block: "center" });
  };

  const validateCaptcha = (captchaValue) => captchaValue === rootCaptchaValue;

  const internalSubmit = (values) => {
    if (!validateCaptcha(captchaValue)) {
      return setCaptchaMessage(t("wrongValue"));
    }
    onSubmit(values);
  };

  const dispatch = useDispatch();

  const doseNumber = useSelector((state) => state.cvr.doseNumber);

  useEffect(() => {
    setValue("vaccinationSite", null);
    setValue("ScsGETliG8f", null);
    setValue("uG58XyIlYFr", "");
  }, [doseNumber]);

  const isInternalPreCVR = useSelector((state) => state.cvr.isInternalPreCVR);

  return (
    <form onSubmit={handleSubmit(internalSubmit, onError)}>
      <Box p={3}>
        <Box
          py={2}
          px={2}
          border={1}
          borderRadius="borderRadius"
          borderColor="grey.400"
        >
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <DoseSelectRadioGroup
                value={doseNumber}
                onChange={(e) => {
                  const doseNumber = e.target.value;
                  dispatch(setRegistrationDose(doseNumber));
                  setIsOutsideCountry(null);
                  switch (doseNumber) {
                    case DOSE_TYPES.FIRST:
                      dispatch(setIsInternalPreSVR(true));
                      break;
                    case DOSE_TYPES.BOOSTER:
                    case DOSE_TYPES.SECOND:
                      dispatch(setIsInternalPreSVR(null));
                      break;
                    default:
                  }
                }}
                label={t("doseSelectLabel")}
              />
            </Grid>
            {[DOSE_TYPES.SECOND, DOSE_TYPES.BOOSTER].includes(doseNumber) && (
              <Grid item>
                <TypedInput
                  label={t("isOutsideCountry")}
                  valueType={DHIS2_TYPES.YES_NO}
                  value={isOutsideCountry === null ? null : isOutsideCountry}
                  onChange={(isOutside) => {
                    dispatch(setIsInternalPreSVR(!isOutside));
                    setIsOutsideCountry(isOutside);
                    if (!isOutside) {
                      dispatch(openInternalFirstDoseVerifyModal(true));
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <Divider />
      {(doseNumber === DOSE_TYPES.FIRST || isInternalPreCVR === false) && (
        <Box p={3}>
          {/*Hidden values*/}
          <FormController
            id="teiUid"
            defaultValue={teiUid}
            rules={{
              required: t("thisFieldIsRequired"),
            }}
          />
          <FormController
            id="enrUid"
            defaultValue={enrUid}
            rules={{
              required: t("thisFieldIsRequired"),
            }}
          />
          <FormController
            id="eventUid"
            defaultValue={eventUid}
            rules={{
              required: t("thisFieldIsRequired"),
            }}
          />
          <FormController
            id="KCBX3fcCYdu"
            rules={{
              required: t("thisFieldIsRequired"),
            }}
            defaultValue={KCBX3fcCYdu}
          />
          <FormController
            id="bJshc2zcNOf"
            rules={{
              required: t("thisFieldIsRequired"),
            }}
            defaultValue={bJshc2zcNOf}
          />
          {/*Name & Occupation*/}
          <Box>
            <Box pb={2}>
              <Typography variant="h6" component="h6">
                {t("nameAndOccupation")}
              </Typography>
              <Box color={orange[700]}>
                <Typography variant="subtitle1">
                  {t("nameAndOccupationWarning")}
                </Typography>
              </Box>
            </Box>
            <Grid container spacing={3} direction="column">
              {!isInternalPreCVR && !isOutsideCountry && (
                <AttributeDhis2FormInput
                  id="jifnoTmhndz"
                  valueType={DHIS2_TYPES.OTHER}
                  Component={CVIDInput}
                  variant="outlined"
                  size="small"
                  autoFocus
                  length={4}
                  ParentComponent={Box}
                  ParentComponentProps={{
                    component: Grid,
                    item: true,
                    width: 1,
                    pt: 0,
                    pb: 0,
                  }}
                  rules={{
                    required: t("thisFieldIsRequired"),
                    pattern: {
                      value: /^[A-Z]\d{4}-[A-Z]-(\d|[A-Z])\d{4}$/,
                      message: t("wrongCVIDFormat"),
                    },
                  }}
                />
              )}
              {/*<AttributeDhis2FormInput*/}
              {/*  id="jifnoTmhndz"*/}
              {/*  ParentComponent={Grid}*/}
              {/*  ParentComponentProps={{ item: true }}*/}
              {/*  // dependentFields="is1stDose"*/}
              {/*  // onDependentChangedRenderer={(value) => !!value}*/}
              {/*  rules={{*/}
              {/*    required: t("thisFieldIsRequired"),*/}
              {/*    pattern: {*/}
              {/*      value: /^[A-Z]\d{4}-[A-Z]-\d{5}$/,*/}
              {/*      message: t("wrongValue"), // JS only: <p>error message</p> TS only support string*/}
              {/*    },*/}
              {/*  }}*/}
              {/*/>*/}
              <AttributeDhis2FormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                // valueType={DHIS2_TYPES.TEXT}
                id="sB1IHYu2xQT"
                rules={{
                  required: t("thisFieldIsRequired"),
                }}
              />
              <Grid item>
                <AttributeDhis2FormInput
                  ParentComponent={Grid}
                  ParentComponentProps={{ item: true }}
                  // valueType={DHIS2_TYPES.TEXT}
                  id="ENRjVGxVL6l"
                  rules={{
                    required: t("thisFieldIsRequired"),
                  }}
                />
              </Grid>
              <Grid item>
                <AttributeDhis2FormInput
                  ParentComponent={Grid}
                  ParentComponentProps={{ item: true }}
                  id="LY2bDXpNvS7"
                />
              </Grid>
            </Grid>
          </Box>
          {/*Identification*/}
          <Box pt={4}>
            <Box pb={2}>
              <Typography variant="h6" component="h6">
                {t("identification")}
              </Typography>
            </Box>
            <Grid container spacing={3} direction="column">
              <AttributeDhis2FormInput
                id="QVAquUnX3GC"
                // defaultValue={false}
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
              />
              <AttributeDhis2FormInput
                id="lRZGCESE6v2"
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                dependentFields="QVAquUnX3GC"
                onDependentChangedRenderer={(value) => !value}
              />
              <NationalityDhis2FormInput
                id="uR9XK6AbPvE"
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                dependentFields="QVAquUnX3GC"
                onDependentChangedProps={(value) => {
                  if (value) {
                    setValue("uR9XK6AbPvE", null);
                  } else {
                    setValue("uR9XK6AbPvE", "LA");
                  }
                }}
                onDependentChangedRenderer={(value) => !!value}
                shouldUnregister={false}
                initialValue="LA"
              />
              <AttributeDhis2FormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                // valueType={DHIS2_TYPES.TEXT}
                id="pjpnF7u5PQj"
              />
            </Grid>
          </Box>

          {/*Sex*/}
          <Box pt={4}>
            <Box pb={2}>
              <Typography variant="h6" component="h6">
                {t("sexAndAge")}
              </Typography>
            </Box>
            <Grid container spacing={3} direction="column">
              <AttributeDhis2FormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                id="DmuazFb368B"
                // // valueType={DHIS2_TYPES.TEXT}
                rules={{
                  required: t("thisFieldIsRequired"),
                }}
              />
              <DataElementFormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                id="BfNZcj99yz4"
                // // valueType={DHIS2_TYPES.TEXT}
                // optionSet={{
                //   options: [
                //     { value: "YES", code: "YES", name: "Yes" },
                //     { value: "NO", code: "NO", name: "No" },
                //   ],
                // }}
                dependentFields="DmuazFb368B"
                onDependentChangedRenderer={(value) => value === "F"}
              />
              <DataElementFormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                id="CBAs12YL4g7"
                // valueType={DHIS2_TYPES.TEXT}
                dependentFields="BfNZcj99yz4"
                onDependentChangedRenderer={(value) => value === "YES"}
              />
              <AttributeDhis2FormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                variant="dialog"
                inputVariant="outlined"
                id="tQeFLjYbqzv"
                // valueType={DHIS2_TYPES.DATE}
                defaultValue={null}
                disableFuture
                // autoOk
                format="YYYY-MM-DD"
                rules={{
                  required: t("thisFieldIsRequired"),
                  validate: {
                    mustGreaterOrEqual10: (value) => {
                      const age = moment().diff(value, "years");
                      if (age > 120) {
                        return t("under120years");
                      }
                    },
                  },
                }}
                views={["year", "month", "date"]}
                openTo="year"
              />
              <AttributeDhis2FormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                id="Z1rLc1rVHK8"
                // valueType={DHIS2_TYPES.TRUE_ONLY}
                color="primary"
              />
              <AttributeDhis2FormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                id="BaiVwt8jVfg"
                // valueType={DHIS2_TYPES.INTEGER_POSITIVE}
                disabled
                dependentFields={"tQeFLjYbqzv"}
                onDependentChangedProps={(value) => {
                  setValue(
                    "BaiVwt8jVfg",
                    value ? moment().diff(value, "years") : 0
                  );
                }}
              />
            </Grid>
          </Box>
          {/*Address*/}
          <Box pt={4}>
            <Box pb={2}>
              <Typography variant="h6" component="h6">
                {t("address")}
              </Typography>
            </Box>
            <Grid container spacing={3} direction="column">
              <AttributeDhis2FormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                id="NqKnaoG9qg4"
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                optionSet={{
                  options: createOptions([], 0),
                }}
                rules={{
                  required: t("thisFieldIsRequired"),
                }}
              />
              <AttributeDhis2FormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                id="rn2HaLfSHk6"
                dependentFields="NqKnaoG9qg4"
                onDependentChangedProps={(value) => {
                  const options = createOptions([value], 1);
                  return {
                    optionSet: {
                      options,
                    },
                    disabled: !options.length,
                  };
                }}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                rules={{
                  required: t("thisFieldIsRequired"),
                }}
                optionSet={{
                  options: createOptions([getValues("NqKnaoG9qg4")], 1),
                }} // override for default optionSet
              />
              <AttributeDhis2FormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                id="EHI7zIc1hnf"
                dependentFields={["NqKnaoG9qg4", "rn2HaLfSHk6"]}
                onDependentChangedProps={([province, district]) => {
                  const options = createOptions([province, district], 2);
                  return {
                    optionSet: {
                      options,
                    },
                    disabled: !options.length,
                  };
                }}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                optionSet={{
                  options: createOptions(
                    [getValues("NqKnaoG9qg4"), getValues("rn2HaLfSHk6")],
                    2
                  ),
                }}
              />
            </Grid>
            {/*<CascadeSelector*/}
            {/*    provinceId="NqKnaoG9qg4"*/}
            {/*        districtId=""*/}
            {/*  // change={(selections) => {*/}
            {/*  //   values.NqKnaoG9qg4 = selections[0];*/}
            {/*  //   values.rn2HaLfSHk6 = selections[1];*/}
            {/*  //   values.EHI7zIc1hnf = selections[2];*/}
            {/*  //   setValues({ ...values });*/}
            {/*  // }}*/}
            {/*  */}
            {/*  */}
            {/*  hierarchy={createHierarchy()}*/}
            {/*  selections={[null, null, null]}*/}
            {/*  labels={[*/}
            {/*    "Please select province / ກະລຸນາເລຶອກແຂວງ",*/}
            {/*    "Please select district / ກະລຸນາເລຶອກເມືອງ",*/}
            {/*    "Please select village / ກະລຸນາເລຶອກບ້ານ",*/}
            {/*  ]}*/}
            {/*/>*/}
          </Box>
          {/*Contact*/}
          <Box pt={4}>
            <Box pb={2}>
              <Typography variant="h6" component="h6">
                {t("contact")}
              </Typography>
            </Box>
            <Grid container spacing={3} direction="column">
              <AttributeDhis2FormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                id="ciCR6BBvIT4"
                // valueType={DHIS2_TYPES.NUMBER}
                rules={{
                  required: t("thisFieldIsRequired"),
                  maxLength: {
                    value: 11,
                    message: t("phoneNumberHasMaximum11Digits"),
                  },
                  pattern: {
                    value: PHONE_PREFIX,
                    message: t("phoneNumberHasToStartWithLaosAndVietnamese"),
                  },
                }}
              />
              {/*<Grid item>*/}
              {/*  <TypedInput valueType={DHIS2_TYPES.TEXT} label="Whatsapp" />*/}
              {/*</Grid>*/}
              <AttributeDhis2FormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                id="jFZFXOCI9he"
                rules={{
                  pattern: {
                    value: emailRegex,
                    message: t("wrongEmailFormat"),
                  },
                }}
                // valueType={DHIS2_TYPES.EMAIL}
              />
            </Grid>
          </Box>
          {/*Conditions*/}
          <Box pt={4}>
            <Box pb={2}>
              <Typography variant="h6" component="h6">
                {t("conditions")}
              </Typography>
            </Box>
            <Grid container spacing={3} direction="column">
              <DataElementFormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                id="LOU9t0aR0z7"
                // valueType={DHIS2_TYPES.TRUE_ONLY}
                color="primary"
              />
              <DataElementFormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                inputVariant="outlined"
                variant="dialog"
                id="oZiX5uHkacg"
                defaultValue={null}
                // valueType={DHIS2_TYPES.DATE}
                // autoOk
                format="YYYY-MM-DD"
                dependentFields="LOU9t0aR0z7"
                onDependentChangedRenderer={(value) => value === true}
                disableFuture
                views={["year", "month", "date"]}
                openTo="year"
              />

              <DataElementFormInput
                ParentComponent={Grid}
                ParentComponentProps={{ item: true }}
                id="bCtWZGjSWM8"
                // options={yesNoOptions(t)}
              />
              <FormController
                dependentFields="bCtWZGjSWM8"
                onDependentChangedRenderer={(value) => {
                  return value === "YES";
                }}
              >
                <>
                  <DataElementFormInput
                    ParentComponent={Grid}
                    ParentComponentProps={{ item: true }}
                    id="xsErJKvstJ7"
                    // valueType={DHIS2_TYPES.TRUE_ONLY}
                    color="primary"
                  />
                  <DataElementFormInput
                    ParentComponent={Grid}
                    ParentComponentProps={{ item: true }}
                    id="TT1h0vGu5da"
                    // valueType={DHIS2_TYPES.TRUE_ONLY}
                    color="primary"
                  />
                  <DataElementFormInput
                    ParentComponent={Grid}
                    ParentComponentProps={{ item: true }}
                    id="PjOMM53YTsR"
                    // valueType={DHIS2_TYPES.TRUE_ONLY}
                    color="primary"
                  />
                  <DataElementFormInput
                    ParentComponent={Grid}
                    ParentComponentProps={{ item: true }}
                    id="Elf0MHK59bj"
                    // valueType={DHIS2_TYPES.TRUE_ONLY}
                    color="primary"
                  />
                  <DataElementFormInput
                    ParentComponent={Grid}
                    ParentComponentProps={{ item: true }}
                    id="UDPhgoVbjpM"
                    // valueType={DHIS2_TYPES.TRUE_ONLY}
                    color="primary"
                  />
                  <DataElementFormInput
                    ParentComponent={Grid}
                    ParentComponentProps={{ item: true }}
                    id="M8AtepTiBVi"
                    // valueType={DHIS2_TYPES.TRUE_ONLY}
                    color="primary"
                  />
                  <DataElementFormInput
                    ParentComponent={Grid}
                    ParentComponentProps={{ item: true }}
                    id="yGn30kRQbys"
                    // valueType={DHIS2_TYPES.TRUE_ONLY}
                    color="primary"
                  />
                  <DataElementFormInput
                    ParentComponent={Grid}
                    ParentComponentProps={{ item: true }}
                    id="oWdg1zxTWrE"
                    // valueType={DHIS2_TYPES.TRUE_ONLY}
                    color="primary"
                  />
                  <DataElementFormInput
                    ParentComponent={Grid}
                    ParentComponentProps={{ item: true }}
                    id="Kk3Z0jSAkQQ"
                    // valueType={DHIS2_TYPES.TRUE_ONLY}
                    color="primary"
                  />
                  <DataElementFormInput
                    ParentComponent={Grid}
                    ParentComponentProps={{ item: true }}
                    id="VcfifxxG4im"
                    // valueType={DHIS2_TYPES.TRUE_ONLY}
                    color="primary"
                  />
                  <DataElementFormInput
                    ParentComponent={Grid}
                    ParentComponentProps={{ item: true }}
                    id="dpyQUtizp7s"
                    // valueType={DHIS2_TYPES.TEXT}
                  />
                </>
              </FormController>
            </Grid>
          </Box>
          {/*Vaccination Scheduling*/}
          <Box pt={4}>
            <Box pb={2}>
              <Typography variant="h6" component="h6">
                {t("vaccinationScheduling")}
              </Typography>
            </Box>
            <Grid container spacing={3} direction="column">
              <VaccinationSiteSelectContainer
                control={control}
                // formState={formState}
                id="vaccinationSite"
              />
              <ScheduledDateSelectContainer
                id="ScsGETliG8f"
                control={control}
              />
              <VaccinationSlotButtonSelectContainer
                id="uG58XyIlYFr"
                control={control}
                // formState={formState}
                // Other dependent form fields inside the component
              />
            </Grid>
          </Box>
          {/*Captcha*/}
          <Box pt={4}>
            <NewSimpleCaptcha
              value={captchaValue}
              onChange={(e) => {
                setCaptchaValue(e.target.value);
                setCaptchaMessage("");
              }}
              placeholder={t("captchaText")}
              reloadText={t("reloadCaptcha")}
              error={!!captchaMessage}
              captchaValue={rootCaptchaValue}
              setCaptchaValue={setRootCaptchaValue}
            />
            {captchaMessage && (
              <FormHelperText error>{t("wrongValue")}</FormHelperText>
            )}
          </Box>
          {/*Confirm*/}
          <Box pt={4}>
            <FormInput
              valueType={DHIS2_TYPES.TRUE_ONLY}
              id="isConfirm"
              label={t("confirmText")}
              rules={{
                required: t("thisFieldIsRequired"),
              }}
            />
          </Box>
          {/*Submit Button*/}
          <Box pt={4}>
            <FormController
              dependentFields="isConfirm"
              onDependentChangedProps={(value) => ({
                disabled: !value,
              })}
            >
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                type="submit"
              >
                <Grid container alignItems="center" justify="center">
                  {loading && (
                    <>
                      <CircularProgress color="white" size={24} />
                      <Box mr={2} />
                    </>
                  )}
                  {t("register")}
                </Grid>
              </Button>
            </FormController>
          </Box>
        </Box>
      )}
    </form>
  );
};

export default CVRForm;
