export const TYPES = {
  GROUP: "GROUP",
  ATTRIBUTE: "ATTRIBUTE",
  DATA_ELEMENT: "DATA_ELEMENT",
};

export const formTemplate = [
  {
    name: "Name & Occupation",
    type: TYPES.GROUP,
    children: [
      {
        type: TYPES.ATTRIBUTE,
        id: "sB1IHYu2xQT",
      },
      {
        type: TYPES.ATTRIBUTE,
        id: "ENRjVGxVL6l",
      },
      {
        type: TYPES.ATTRIBUTE,
        id: "LY2bDXpNvS7",
      },
    ],
  },
  {
    name: "Name & Occupation",
    type: TYPES.GROUP,
    children: [
      {
        type: TYPES.ATTRIBUTE,
        id: "lRZGCESE6v2",
      },
      {
        type: TYPES.ATTRIBUTE,
        id: "pjpnF7u5PQj",
      },
    ],
  },
  {
    name: "sexAndAge",
    type: TYPES.GROUP,
    children: [
      {
        type: TYPES.ATTRIBUTE,
        id: "DmuazFb368B",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "BfNZcj99yz4",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "CBAs12YL4g7",
      },
      {
        type: TYPES.ATTRIBUTE,
        id: "tQeFLjYbqzv",
      },
      {
        type: TYPES.ATTRIBUTE,
        id: "Z1rLc1rVHK8",
      },
      {
        type: TYPES.ATTRIBUTE,
        id: "BaiVwt8jVfg",
      },
    ],
  },
  {
    name: "address",
    type: TYPES.GROUP,
    children: [
      {
        type: TYPES,
      },
    ],
  },
  {
    name: "contact",
    type: TYPES.GROUP,
    children: [
      {
        type: TYPES.ATTRIBUTE,
        id: "ciCR6BBvIT4",
      },
      {
        type: TYPES.ATTRIBUTE,
        id: "jFZFXOCI9he",
      },
    ],
  },
  {
    name: "conditions",
    type: TYPES.GROUP,
    children: [
      {
        type: TYPES.DATA_ELEMENT,
        id: "LOU9t0aR0z7",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "oZiX5uHkacg",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "bCtWZGjSWM8",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "xsErJKvstJ7",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "TT1h0vGu5da",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "PjOMM53YTsR",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "Elf0MHK59bj",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "UDPhgoVbjpM",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "yGn30kRQbys",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "oWdg1zxTWrE",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "Kk3Z0jSAkQQ",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "VcfifxxG4im",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "dpyQUtizp7s",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "yGn30kRQbys",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "yGn30kRQbys",
      },
      {
        type: TYPES.DATA_ELEMENT,
        id: "yGn30kRQbys",
      },
    ],
  },
];

export const toServerData = (values) => {
  return {
    ...values,
    ciCR6BBvIT4: toServerMobileValue(values.ciCR6BBvIT4),
  };
};

export const toClientData = (values) => {
  return {
    ...values,
    ciCR6BBvIT4: toClientMobileValue(values.ciCR6BBvIT4),
  };
};

export const toServerMobileValue = (value) => {
  if (!value) return value;
  if (value.toString().startsWith("0")) {
    return "856" + value.slice(1);
  }
  return value;
};
export const toClientMobileValue = (value) => {
  if (!value) return value;
  if (value.toString().startsWith("856")) {
    return "0" + value.slice(3);
  }
  return value;
};
