import React from "react";
import SchedulingInternal2ndDoseDialog from "./SchedulingInternal2ndDoseDialog";
import { useDispatch, useSelector } from "react-redux";
import { toServerMobileValue } from "./utils";
import {
  registerForBoosterDose,
  registerForSecondDose,
} from "../../services/api";
import { openInternal2ndSchedulingModal } from "../../redux/reducers/cvr/internalFirstDose/actions";
import {
  openResultModal,
  setResultData,
  setResultError,
  setResultLoading,
} from "../../redux/reducers/cvr/result/actions";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { DOSE_TYPES } from "../../common/TypedInput/DoseSelectRadioGroup";

const SchedulingInternal2ndDoseDialogContainer = () => {
  const open = useSelector(
    (state) => state.cvr.internalFirstDose.isOpenInternal2ndDoseSchedulingModal
  );

  const isLoading = useSelector((state) => state.cvr.result.isLoading);
  const onCancel = () => window.location.reload();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const internalTeiData = useSelector(
    (state) => state.cvr.internalFirstDose.data
  );
  const doseNumber = useSelector((state) => state.cvr.doseNumber);

  const getEndPoint = () => {
    switch (doseNumber) {
      case DOSE_TYPES.SECOND:
        return registerForSecondDose;
      case DOSE_TYPES.BOOSTER:
        return registerForBoosterDose;
    }
  };

  const onSubmit = ({ vaccinationSite, ScsGETliG8f, uG58XyIlYFr }) => {
    const payload = {
      ciCR6BBvIT4: internalTeiData.ciCR6BBvIT4
        ? toServerMobileValue(internalTeiData.ciCR6BBvIT4)
        : null, // Phone,
      corXnplgfQ7: internalTeiData.corXnplgfQ7, // CVID
      vaccinationSite,
      ScsGETliG8f,
      uG58XyIlYFr,
      tQeFLjYbqzv: internalTeiData.tQeFLjYbqzv || null, // dob
    };
    dispatch(setResultLoading(true));
    getEndPoint()(payload)
      .then((result) => {
        if (!result || result.status === false) {
          dispatch(setResultData(null));
          dispatch(openResultModal(false));
          dispatch(
            setResultError(get(result, "message", t("unknownServerError")))
          );
        } else {
          const { patient } = result;
          dispatch(openResultModal(true));
          dispatch(
            setResultData({
              teiUid: patient.teiUid,
              ScsGETliG8f: patient.scheduleDate,
              uG58XyIlYFr: patient.scheduleTime,
              vaccinationSite,
              sB1IHYu2xQT: patient.firstName,
              ENRjVGxVL6l: patient.lastName,
              ciCR6BBvIT4: patient.phoneNumber,
            })
          );
          dispatch(setResultError(null));
          dispatch(openInternal2ndSchedulingModal(false));
        }
      })
      .catch((e) => {
        return e.json().then((result) => {
          dispatch(setResultData(null));
          dispatch(openResultModal(false));
          dispatch(
            setResultError(get(result, "message", t("unknownServerError")))
          );
        });
      })
      .finally(() => {
        dispatch(setResultLoading(false));
      });
  };

  return (
    <SchedulingInternal2ndDoseDialog
      isLoading={isLoading}
      open={open}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
};

export default SchedulingInternal2ndDoseDialogContainer;
